import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Button from "./Button";

export default ({
  visible,
  onHide,
  renderContent,
  buttons,
  title,
  height,
  width,
}) => {
  const theme = Themes[useColorScheme()];

  if (!visible) {
    return null;
  }

  const localStyles = {
    overlay: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.overlay,
      zIndex: 9999,
    },
    content: {
      display: "flex",
      flex: 1,
      overflow: "auto",
    },
    titleArea: {
      boxSizing: "content-box",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
      borderBottomColor: theme.colors.border,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
    title: {
      marginTop: "19px",
      marginBottom: "19px",
    },
    modal: {
      display: "flex",
      backgroundColor: theme.colors.white,
      width: width || "50%",
      height: height || "50%",
      minWidth: 300,
      borderRadius: 6,
      flexDirection: "column",
      boxShadow: theme.colors.shadow,
      overflow: "hidden",
    },
    buttons: {
      borderTopColor: theme.colors.border,
      borderTopWidth: 1,
      borderTopStyle: "solid",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 15,
      paddingBottom: 15,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    buttonsRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };

  const onOverlayClick = (evt) => {
    if (evt.target.dataset.overlay) {
      onHide();
    }
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return (
      <div style={localStyles.titleArea}>
        <h2
          style={{
            ...theme.styles.h2,
            ...localStyles.title,
          }}
        >
          {title}
        </h2>
      </div>
    );
  };

  const renderContentArea = () => {
    if (!renderContent) {
      return null;
    }

    return <div style={localStyles.content}>{renderContent()}</div>;
  };

  const renderButtonSide = (side, style) => {
    if (!side) {
      return <div style={localStyles.style}></div>;
    }

    return (
      <div style={style}>
        {side.map((button) => {
          return (
            <Button
              key={button.id}
              label={button.label}
              width={button.width}
              disabled={button.disabled}
              onClick={button.onClick}
              loading={button.loading}
            />
          );
        })}
      </div>
    );
  };

  const renderButtons = () => {
    if (!buttons) {
      return null;
    }

    return (
      <div style={localStyles.buttons}>
        {renderButtonSide(buttons.left, localStyles.buttonsLeft)}
        {renderButtonSide(buttons.right, localStyles.buttonsRight)}
      </div>
    );
  };

  return (
    <div
      data-overlay="true"
      style={localStyles.overlay}
      className="overlay-appear"
      onMouseDown={(evt) => onOverlayClick(evt)}
    >
      <div style={localStyles.modal} className="modal-appear">
        {renderTitle()}
        {renderContentArea()}
        {renderButtons()}
      </div>
    </div>
  );
};
