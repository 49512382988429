import React from "react";
import moment from "moment";
import { extent } from "d3-array";
import { axisBottom } from "d3-axis";
import { scaleTime, scaleLinear } from "d3-scale";
import { line } from "d3-shape";
import { colorScale, clearSvg, setupSvg } from "./helpers";

export default class extends React.Component {

    componentDidUpdate() {
        this.renderTrend(this.props.data);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => this.renderTrend(this.props?.data || []);

    getDateOrCurrentDate = (date) => date > moment.now() ? moment.now() : date;

    renderTrend(data) {
        const setup = {
            id: "chart_trend",
            height: 320,
            margin: { top: 20, right: 30, bottom: 20, left: 40 }
        };
        clearSvg(setup);
        const { g, width, height } = setupSvg(setup);
        const scales = {
            x: scaleTime().domain(extent(data, d => d.date)).range([0, width]),
            y: scaleLinear().domain([0, 10]).range([0, height])
        }
        const path = line()(data.map(d => ([scales.x(this.getDateOrCurrentDate(d.date)), height - scales.y(d.score) ])));
        const pathMin = line()(data.map(d => ([scales.x(this.getDateOrCurrentDate(d.date)), height - scales.y(d.scoreMin) ])));
        const pathMax = line()(data.map(d => ([scales.x(this.getDateOrCurrentDate(d.date)), height - scales.y(d.scoreMax) ])));
        g.selectAll("line")
            .data([2.5, 5, 7.5, 10])
            .enter()
            .append("line")
            .attr("x1", -15)
            .attr("x2", width)
            .attr("y1", d => height - scales.y(d))
            .attr("y2", d => height - scales.y(d))
            .style("stroke", "#ccc")
            .style("opacity", 0.8);
        g.selectAll("text.line-label")
            .data([2.5, 5, 7.5, 10])
            .enter()
            .append("text")
            .attr("class", "line-label")
            .attr("x", -20)
            .attr("y", d => height - scales.y(d) + 4)
            .style("text-anchor", "end")
            .style("font-size", "12px")
            .text(d => d);
        g.selectAll("path")
            .data([path, pathMin, pathMax])
            .enter()
            .append("path")
            .attr("class", (_, i) => ["trend-path"].concat(["", "-min", "-max"][i]).join(""))
            .attr("d", d => d)
            .style("fill", "none");
        g.selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", d => scales.x(this.getDateOrCurrentDate(d.date)))
            .attr("cy", d => height - scales.y(d.score))
            .attr("r", 10)
            .style("fill", (d, i) => colorScale(Math.floor(d.score)));
        g.selectAll("text.text-main")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "text-main")
            .attr("x", d => scales.x(this.getDateOrCurrentDate(d.date)))
            .attr("y", d => height - scales.y(d.score) - 20)
            .text(d => d.score)
            .style("text-anchor", "middle");
        const axis = axisBottom(scales.x);
        g.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(axis);
    }

    render() {
        return (
            <div className="leaderboard tile">
                <h2>Trend</h2>    
                <p style={{ fontSize: "smaller", fontStyle: "italic" }}>Scores are grouped by the week (Monday - Sunday) in which they are recorded</p>
                <div className="row">
                    <svg id="chart_trend"></svg>
                </div>
            </div>
        )
    }
}