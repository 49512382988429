import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import moment from "moment";
import UserCompanyServices from "../services/UserCompanyServices";
import OverallScore from "../components/visualisations/OverallScore";
import Trend from "../components/visualisations/Trend";
import Scores from "../components/visualisations/Scores";
import Detail from "../components/visualisations/Detail";
import Leaderboard from "../components/visualisations/Leaderboard";
import {
  getLeaderboardData,
  getScoresData,
  getTrendData,
  groupByWeek,
} from "../components/visualisations/getData";

export default ({ companies }) => {
  const theme = Themes[useColorScheme()];

  const [dateFrom, setDateFrom] = useState(moment().startOf("month"));
  const [dateTo, setDateTo] = useState(moment().endOf("month"));
  const [focusedInput, setFocusedInput] = useState(null);

  //const [averageScore, setAverageScore] = useState(0);
  const [detailData, setDetailData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [scoresData, setScoresData] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    reloadReport();
  }, [dateFrom, dateTo, companies]);

  const getUniqueScores = (scores) => {
    var usedScoreIdMap = {};

    var uniqueScores = [];

    scores.forEach((item) => {
      if (!usedScoreIdMap[item.score_id]) {
        uniqueScores.push(item);
        usedScoreIdMap[item.score_id] = true;
      }
    });

    return uniqueScores;
  };

  const reloadReport = async () => {
    var orgParam = "";

    if (companies && companies.selected) {
      orgParam = `&organisation_id=${companies.selected.id}`;
    } else if (!UserCompanyServices.canAdmin()) {
      return;
    }
    if (dateFrom !== null && dateTo !== null) {
      const scores = await HttpServices.get(
        `/reporting/organisation/scores?report_type=json&date_from=${dateFrom.format(
          "YYYY-MM-DD"
        )}&date_to=${dateTo.format("YYYY-MM-DD")}${orgParam}`
      );

      const uniqueScores = getUniqueScores(scores);

      const scoresGroupedByWeek = groupByWeek(uniqueScores);
      //setAverageScore(getAverageScore(scores));
      setDetailData(scores);
      setTrendData(getTrendData(scoresGroupedByWeek));
      setScoresData(getScoresData(uniqueScores));
      setLeaderboardData(getLeaderboardData(uniqueScores, scoresGroupedByWeek));
    }
  };

  const countNumberOfUniqueScores = () => {
    var usedScoreIdMap = {};

    var count = 0;

    detailData.forEach((item) => {
      if (!usedScoreIdMap[item.score_id]) {
        count++;
        usedScoreIdMap[item.score_id] = true;
      }
    });

    return count;
  };

  const getOverallAverage = () => {
    var usedScoreIdMap = {};

    var count = 0;
    var total = 0;

    detailData.forEach((item) => {
      if (!usedScoreIdMap[item.score_id]) {
        count++;
        total += item.score;
        usedScoreIdMap[item.score_id] = true;
      }
    });

    if (!total && !count) {
      return 0;
    }

    return (total / count).toFixed(1);
  };

  return (
    <div>
      <h1 style={theme.styles.pageHeading}>Scores Visualisation</h1>
      <div style={{ marginLeft: "1em" }}>
        <DateRangePicker
          startDate={dateFrom}
          startDateId="start_date_id"
          endDate={dateTo}
          endDateId="end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            setDateFrom(startDate);
            setDateTo(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(input) => setFocusedInput(input)}
          isOutsideRange={(day) =>
            day.isAfter(moment().add(1, "day")) ||
            day.isBefore(moment("2021-01-01"))
          }
          hideKeyboardShortcutsPanel={true}
          displayFormat={"DD/MM/YYYY"}
        />
      </div>
      <div className="row">
        <OverallScore
          data={getOverallAverage()}
          numberOfScores={countNumberOfUniqueScores()}
        />
        <Trend data={trendData} />
      </div>
      <div className="row">
        <Scores data={scoresData} />
        <Leaderboard data={leaderboardData} />
      </div>
      <div className="row">
        <Detail data={detailData} />
      </div>
    </div>
  );
};
