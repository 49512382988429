import React, {useState} from "react";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import moment from "moment";
import Checkbox from "./Checkbox";

export default({streaks}) => {
    const theme = Themes[useColorScheme()];
    const [showOnlyCompleted, setShowOnlyCompleted] = useState(false);

    const localStyles = {
        container: {
            marginTop: 30
        },
        centered: {
            textAlign: "center"
        },
        checkboxRow: {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 30
        }

    };

    if (!streaks || !streaks.weeks) {
        return null;
    }

    const formatUserName = (user) => {
        if (user.first_name) {
            return `${user.first_name} ${user.last_name || ""}`
        }

        return user.email;
    };

    const renderCompleted = (completed) => {
        if (!completed) {
            return null;
        }

        return (
            <img src={theme.images.completed} alt="Completed"/>
        );
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.checkboxRow}>
                <Checkbox
                    on={showOnlyCompleted}
                    onChange={() => setShowOnlyCompleted(!showOnlyCompleted)}
                />
                <div>Show only completed</div>

            </div>
            
           <table>
                <thead>
                    <tr>
                        <th>User</th>
                        {streaks.weeks.map((week) => {
                            return (
                                <th key={week} style={localStyles.centered}>{moment(week).format("DD/MM")}</th>
                            );
                        })}
                        <th style={localStyles.centered}>All</th>
                    </tr>
                </thead>
                <tbody>
                    {streaks.users.map((user) => {
                        if (showOnlyCompleted && !user.completed) {
                            return null;
                        }

                        return (
                            <tr key={user.id}>
                                <td>{formatUserName(user)}</td>
                                {streaks.weeks.map((week) => {
                                    return (
                                        <td key={week} style={localStyles.centered}>{renderCompleted(user.weeks[week])}</td>
                                    );
                                })}
                                <td style={localStyles.centered}>{renderCompleted(user.completed)}</td>
                            </tr>
                        );
                    })}
                </tbody>
           </table>
        </div>
    )
}