import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";

const DEFAULT_SIZE = 30;

export default({size, user}) => {

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: size || DEFAULT_SIZE,
            height: size || DEFAULT_SIZE,
            borderRadius: size ? (size / 2) : (DEFAULT_SIZE / 2),
            backgroundColor: theme.colors.border,
            overflow: "hidden"
        },
        initials: {
            fontWeight: 700,
            fontSize: 10,
            opacity: 0.5,
            color: theme.colors.alwaysBlack,
        },
        pic: {
            width: size || DEFAULT_SIZE,
            height: size || DEFAULT_SIZE,
            borderRadius: size ? (size / 2) : (DEFAULT_SIZE / 2),
        }
    };

    const getInitials = () => {
        var str = "";

        if (user.override) {
            return user.override;
        }

        if (user.name) {
            // This is an org
            return user.name.substr(0, 1);
        }

        if (user.first_name && user.first_name.length > 0) {
            str += user.first_name.substr(0, 1);
        }

        if (user.last_name && user.last_name.length > 0) {
            str += user.last_name.substr(0, 1);
        }

        return str;

    };

    const renderImageOrPlaceholder = () => {
        if (user.profile_pic) {
            return (
                <img 
                    alt="Profile Pic"
                    src={HttpServices.getS3Url(user.profile_pic)}
                    style={localStyles.pic}/>
            )
        }

        return (
            <div style={localStyles.initials}>{getInitials()}</div>
        )

    };

    return (
        <div style={localStyles.container}>
            {renderImageOrPlaceholder()}
        </div>
    );
};