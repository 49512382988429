import React, {useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import LastXTooltip from "./LastXTooltip";

export default({day, index}) => {
    
    const theme = Themes[useColorScheme()];
    const [hovering, setHovering] = useState(false);

    var score = null;
    var title = null;


    if (day && day.score) {
        title = day.score.avg.toFixed(1);

        if (title === '10.0') {
            title = 10;
        }

        score = "" + day.score.avg.toFixed(0);
    }

    const localStyles = {
        container: {
            position: "relative",
            backgroundColor: (!day || !day.date) ? theme.colors.background : score ? theme.colors.form[score] : theme.colors.border,
            display: "flex",
            width: 40,
            height: 40,
            borderRadius: 20,
            cursor: "pointer",
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 2,
            borderColor: 'transparent',
        },
        dayTitle: {
            color: theme.colors.alwaysWhite,
            fontSize: 12,
            fontWeight: "700"
        },
    };

    return (
            <div key={index} style={localStyles.container} onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}>
                <div style={localStyles.dayTitle} onMouseOver={() => setHovering(true)}>{title}</div>
                <LastXTooltip
                    day={day}
                    visible={hovering}
                />
            </div>
    );

};