import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default() => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        logo: {
            width: 40,
            height: 40
        }
    };

    return (
        <div style={localStyles.container}>
            <img 
                alt="Spinner"
                className="rotating"
                src={theme.images.formSpinner}
                style={localStyles.logo}
                />
        </div>
        
    )
};