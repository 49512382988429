import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import HttpServices from "../services/HttpServices";

import Modal from "./Modal";
import TextBox from "./TextBox";

import SuccessToastDrivers from "./SuccessToastDrivers";

export default ({ visible, onHide, onRefresh, seats, organisation }) => {
  const toast = useToast();
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState(seats?.subScriptionDetails?.seats);

  const localStyles = {
    content: {
      width: "100%",
    },
    contentDisplay: {
      width: "80%",
      display: "flex",
      flexFlow: "wrap",
      // justifyContent: "space-between",
      padding: 15,
      overflow: "auto",
    },
    text: {
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 5,
    },
    fieldHolder: {
      padding: 20,
    },
    emoji: {
      width: 40,
      height: 40,
      cursor: "pointer",
      padding: 8,
    },
    image_wrapper: {
      marginTop: 35,
    },
    btn: {
      cursor: "pointer",
    },
  };

  const renderContent = () => {
    return (
      <div style={localStyles.content}>
        <div style={localStyles.fieldHolder}>
          <TextBox
            label="Seats Allocated"
            value={title}
            width={400}
            onChange={(val) => setTitle(val)}
            inputProps={{ maxLength: 5 }}
          />
          <small>
            Note: Increasing or decresing number of seats allocated won't affect
            exisiting Users.
          </small>
        </div>
      </div>
    );
  };

  const hide = () => {
    onHide();
  };

  const canSave = () => {
    if (!title || title.length === 0 || isNaN(title)) {
      return false;
    }
    return true;
  };

  const save = async () => {
    let body = {
      org_id: organisation,
      seats: title,
    };
    setSaving(true);
    await HttpServices.put(`/user/msteams/v1/update-seats`, {
      ...body,
    });
    onRefresh();
    hide();
    setSaving(false);
    return toast({
      duration: 3000,
      render: () => {
        return <SuccessToastDrivers>Updated successfully</SuccessToastDrivers>;
      },
    });
  };

  return (
    <Modal
      title={`Update Seats Allocated`}
      visible={visible}
      width="50%"
      // height="60%"
      buttons={{
        left: [
          { id: "cancel", label: "Cancel", onClick: () => hide(), width: 60 },
        ],
        right: [
          {
            id: "save",
            label: "Save",
            onClick: () => save(),
            width: 60,
            loading: saving,
            disabled: !canSave(),
          },
        ],
      }}
      onHide={() => hide()}
      renderContent={() => renderContent()}
    />
  );
};
