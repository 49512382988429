import React, { useEffect, useState } from "react";
import CompanySelectModal from "../components/CompanySelectModal";
import EditOrganisationSettings from "../components/EditOrganisationSettings";
import OrganisationUsers from "../components/OrganisationUsers";
import ProfilePic from "../components/ProfilePic";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes, { useChakraTheme } from "../Themes";
import OrganisationPermissions from "../components/OrganisationPermissions";
import { Button, ChakraProvider, Heading, Tag, Text } from "@chakra-ui/react";
import { userIsAdmin } from "../services/UserServices";
import ManageOrganisationProductSubscriptions from "../components/ManageOrganisationProductSubscriptions";
import { EditOrganisationsProvider } from "../contexts/EditOrganisationsContext";
import { useButtonStyles } from "../components/Button";

export default ({ companies }) => {
  const theme = Themes[useColorScheme()];
  const chakraTheme = useChakraTheme();
  const buttonStyles = useButtonStyles();

  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState(null);
  const [showChooser, setShowChooser] = useState(false);
  const localStyles = {
    orgHolder: {
      display: "flex",
      flexDirection: "row",
    },
    org: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      userSelect: "none",
      cursor: "pointer",
      padding: 20,
      backgroundColor: theme.colors.white,
      borderRadius: 10,
    },
    orgName: {
      fontWeight: "500",
      marginLeft: 10,
    },
    deleteOrg: {
      opacity: 0.3,
      marginLeft: 30,
      width: 18,
      height: 18,
    },
  };

  useEffect(() => {
    reloadOrg();
  }, [companies]);

  const reloadOrg = async () => {
    if (companies.selected) {
      const loadedOrg = await HttpServices.get(
        `/organisation/${companies.selected.id}`
      );
      setOrganisation(loadedOrg);
      setLoading(false);
    }
  };

  const onSelectParent = async (parent) => {
    var updatedOrg = Object.assign({}, organisation);
    updatedOrg.parent_id = parent.id;
    updatedOrg.parent_name = parent.name;
    updatedOrg.parent_profile_pic = parent.profile_pic;

    setOrganisation(updatedOrg);
    setShowChooser(false);

    await HttpServices.post(`/organisation/${organisation.id}/parent`, {
      parent_id: parent.id,
    });
  };

  const onDeleteParent = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    var updatedOrg = Object.assign({}, organisation);
    updatedOrg.parent_id = null;
    updatedOrg.parent_name = null;
    updatedOrg.parent_profile_pic = null;

    setOrganisation(updatedOrg);
    setShowChooser(false);

    await HttpServices.delete(`/organisation/${organisation.id}/parent`);
  };

  const saveOrganisationSettings = async (name, shortName, baseEmail) => {
    await HttpServices.patch(`/organisation/${organisation.id}`, {
      name: name,
      short_name: shortName,
      base_email: baseEmail,
    });

    var updatedOrg = Object.assign({}, organisation);
    updatedOrg.name = name;
    updatedOrg.short_name = shortName;
    setOrganisation(updatedOrg);
  };

  const toggleOrgArchived = async () => {
    const result = await HttpServices.post(
      `/organisation/${organisation.id}/archive`
    );
    setOrganisation(result);
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    return (
      <div>
        {!organisation.active && (
          <Tag size={"lg"} bg={theme.colors.form["2"]} color={"white"} mb={3}>
            Archived
          </Tag>
        )}
        {userIsAdmin() && (
          <Button {...buttonStyles} mb={4} onClick={toggleOrgArchived}>
            {!organisation.active ? "Unarchive" : "Archive"}
          </Button>
        )}
        <Heading variant={"h2"}>Parent Organisation</Heading>
        <div style={localStyles.orgHolder}>
          <div style={localStyles.org} onClick={() => setShowChooser(true)}>
            {organisation.parent_id ? (
              <ProfilePic
                user={{
                  name: organisation.parent_name,
                  profile_pic: organisation.parent_profile_pic,
                  id: organisation.parent_name,
                }}
              />
            ) : null}
            <div style={localStyles.orgName}>
              {organisation.parent_id ? organisation.parent_name : "No parent"}
            </div>
            {organisation.parent_id ? (
              <img
                alt="Delete"
                className="unOpacityHover"
                src={theme.images.deleteOrg}
                style={localStyles.deleteOrg}
                onClick={(evt) => onDeleteParent(evt)}
              />
            ) : null}
          </div>
        </div>
        <EditOrganisationSettings
          organisation={organisation}
          onSave={(name, shortName, baseEmail) =>
            saveOrganisationSettings(name, shortName, baseEmail)
          }
        />
        {userIsAdmin() && (
          <EditOrganisationsProvider>
            <ManageOrganisationProductSubscriptions
              organisation={organisation}
              mt={5}
            />
          </EditOrganisationsProvider>
        )}

        <OrganisationUsers organisation={organisation} />
        {userIsAdmin() && (
          <OrganisationPermissions organisation={organisation} />
        )}
      </div>
    );
  };

  return (
    <ChakraProvider theme={chakraTheme}>
      <Heading>Organisation Settings</Heading>
      <Text my={3}>
        This admin only section lets you modify some organisation settings
      </Text>
      {renderContent()}
      <CompanySelectModal
        returnSelectionOnly={true}
        visible={showChooser}
        excludeOrg={organisation}
        companies={companies}
        onSelect={(parent) => onSelectParent(parent)}
        onHide={() => setShowChooser(false)}
      />
    </ChakraProvider>
  );
};
