import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { DiscoverCategoriesContext } from "../../contexts/DiscoverCategoriesContext";

const CategoryFormControl = ({ value, onChange, ...props }) => {
  const categories = useContext(DiscoverCategoriesContext);

  if (!categories.length) {
    return null;
  }

  return (
    <FormControl {...props}>
      <FormLabel mb={2}>Category</FormLabel>
      <Select onChange={(e) => onChange(e.target.value)} value={value}>
        <option value="">Select Category</option>
        {categories.map((c) => (
          <option key={c.id} value={c.id}>
            {c.title}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

CategoryFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...FormControl.propTypes,
};

export default CategoryFormControl;
