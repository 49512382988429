import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import logo from "../form_logo_word.svg";
import UserDetails from "./UserDetails";
import { useHistory } from "react-router-dom";
import FeatureFlagServices from "../services/FeatureFlagServices";

const LINKS = {
  admin: [
    { id: "home", title: "Home", url: "/" },
    { id: "users", title: "User Management", url: "/users" },
    {
      id: "apps",
      title: "Apps Management",
      url: "/apps",
      featureFlag: FeatureFlagServices.APPS_ADMIN_FLAG,
    },
    { id: "reports", title: "Reports", url: "/reports" },
    { id: "driverTrends", title: "Driver Trends", url: "/drivers" },
    { id: "cards", title: "Discover Cards", url: "/cards" },
    {
      id: "card-categories",
      title: "Discover Categories",
      url: "/discover-categories",
    },
    {
      id: "scoreViz",
      title: "Score Visualisation",
      url: "/scores/visualisation",
    },
    {
      id: "lowscores",
      title: "Low Score Resources",
      url: "/lowscores",
      adminOnly: false,
    },
    {
      id: "drivers-edit",
      title: "Drivers",
      url: "/drivers/edit",
      adminOnly: true,
    },
    {
      id: "orgs-edit",
      title: "Organisations",
      url: "/organisations/edit",
      adminOnly: true,
    },
  ],
  organisation: [
    { id: "driverTrends", title: "Driver Trends", url: "/drivers" },
    { id: "lastx", title: "Last 100", url: "/lastx" },
    {
      id: "scoreViz",
      title: "Score Visualisation",
      url: "/scores/visualisation",
    },
    { id: "emails", title: "Emails", url: "/emails" },
    {
      id: "polls",
      title: "Polls",
      url: "/polls",
      adminOnly: true,
      featureFlag: FeatureFlagServices.POLLS_FLAG,
    },
    {
      id: "streaks",
      title: "Streaks",
      url: "/streaks",
      adminOnly: true,
    },
    { id: "orgCards", title: "Discover Cards", url: "/org-cards" },
    {
      id: "analytics",
      title: "Analytics",
      url: "/analytics",
      adminOnly: false,
    },
    { id: "settings", title: "Settings", url: "/settings", adminOnly: true },
    {
      id: "ms-teams-settings",
      title: "Teams Settings",
      url: "/ms-teams-settings",
      adminOnly: true,
    },
    {
      id: "lowscores",
      title: "Low Score Resources",
      url: "/lowscores",
      adminOnly: false,
    },
    {
      id: "drivers-edit",
      title: "Drivers",
      url: "/drivers/edit",
      adminOnly: true,
    },
  ],
};

export default ({ user, companies }) => {
  const history = useHistory();
  const theme = Themes[useColorScheme()];

  const localStyles = {
    container: {
      userSelect: "none",
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      left: 0,
      top: 0,
      bottom: 0,
      width: 200,
      backgroundColor: theme.colors.colorBackground,
    },
    innerContainer: {
      padding: 30,
      flex: 1,
    },
    links: {
      paddingTop: 20,
      flex: 1,
    },
    link: {
      marginBottom: 10,
      color: theme.colors.alwaysWhite,
      fontSize: 14,
      fontWeight: 500,
      cursor: "pointer",
    },
    logo: {
      width: 60,
    },
  };

  const links = companies && companies.admin ? LINKS.admin : LINKS.organisation;

  return (
    <div style={localStyles.container}>
      <div style={localStyles.innerContainer}>
        <img alt="Logo" src={logo} style={localStyles.logo} />
        <div style={localStyles.links}>
          {links.map((link) => {
            if (link.adminOnly && !user.admin) {
              return null;
            }

            if (
              link.featureFlag &&
              !FeatureFlagServices.isFeatureOn(user, link.featureFlag)
            ) {
              return null;
            }

            return (
              <div
                className="opacityHover"
                key={link.id}
                style={localStyles.link}
                onClick={() => history.push(link.url)}
              >
                {link.title}
              </div>
            );
          })}
        </div>
      </div>
      <UserDetails user={user} />
    </div>
  );
};
