import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
} from "@chakra-ui/react";
import HttpServices from "../../services/HttpServices";
import { useParams } from "react-router-dom";
import { IoCloseCircleSharp } from "react-icons/all";
import { CardsContext } from "../../contexts/CardsContext";

const ImageFormControl = ({ value, onChange, ...props }) => {
  const { id } = useParams();
  const { chosenCardId } = useContext(CardsContext);
  const cardId = chosenCardId || id;
  const uploadImage = async (file) => {
    const form = new FormData();
    form.append("image", file);

    const result = await HttpServices.upload(
      `/discover/cards/${cardId}/image`,
      form
    ).catch((e) => {
      console.error(e);
      alert(
        "There was an error uploading the image. Check the image is not too large and try again."
      );
      return false;
    });

    if (result) {
      onChange(result);
    }
  };
  return (
    <FormControl {...props}>
      <FormLabel>Image</FormLabel>
      <Grid gridGap={4} alignItems={"center"} gridTemplateColumns={"336px 1fr"}>
        {!value && (
          <Box
            maxWidth={"336px"}
            height={"219px"}
            background={"borderContrast"}
          />
        )}
        {value && (
          <Box
            width={"336px"}
            height={"auto"}
            position={"relative"}
            _hover={{
              ".image__overlay": {
                background: "rgba(0, 0, 0, 0.5)",
              },
              ".image__remove": {
                opacity: 1,
              },
            }}
          >
            <Image src={value} width={"100%"} />
            <Box
              width={"100%"}
              height={"100%"}
              background={"transparent"}
              position={"absolute"}
              top={0}
              left={0}
              transition={"0.25s ease-in"}
              className={"image__overlay"}
            />
            <Icon
              as={IoCloseCircleSharp}
              position={"absolute"}
              top={3}
              right={3}
              opacity={0}
              color={"alwaysWhite"}
              fontSize={"30px"}
              transition={"0.25s ease-in"}
              className={"image__remove"}
              onClick={() => onChange("")}
              cursor={"pointer"}
            />
          </Box>
        )}
        <div>
          <FormHelperText mb={2}>
            Optimal image dimensions: 708px x 462px
          </FormHelperText>
          <Input
            type={"file"}
            p={4}
            height={"auto"}
            accept={"image/jpeg, image/jpg"}
            onChange={async (e) => {
              uploadImage(e.target.files[0]);
            }}
          />
        </div>
      </Grid>
    </FormControl>
  );
};

ImageFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...Box.propTypes,
};

export default ImageFormControl;
