import HttpServices from "./HttpServices";

export const userIsAdmin = () => {
  const user = HttpServices.getUser();

  return user && user.admin;
};

export const logout = () => {
  HttpServices.clearUserAndToken();
  window.location.reload();
};
