import { createContext, useState, useEffect } from "react";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";

import { chunk, get, isEqual, orderBy } from "lodash";

export const CardsContext = createContext({
  cards: [],
  chosenCardId: "",
  setChosenCardId: () => {},
  cardEditActive: false,
  setCardEditActive: () => {},
  searchQuery: "",
  search: () => {},
  direction: "",
  productConfig: null,
  order: [],
  fetchCards: () => {},
  getUrl: () => {},
  reOrder: () => {},
  allDrivers: [],
  driverFilters: [],
  filterByDriver: () => {},
  filterByOrg: () => {},
  allCategories: [],
  filterByCategory: () => {},
  categoryFilters: [],
  orgFilters: [],
  allTypes: [],
  filterByType: () => {},
  typeFilters: [],
  page: 1,
  pages: 1,
  setPage: () => {},
  remove: () => {},
  update: () => {},
});

const CARDS_PER_PAGE = 20;

export const CardsProvider = ({ children }) => {
  // const [url, setUrl] = useState("/discover/cards");
  // const { data: cards } = useApi(url, []);
  const [cards, setCards] = useState([]);
  const [chosenCardId, setChosenCardId] = useState("");
  const [cardEditActive, setCardEditActive] = useState(false);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState(["updated_at"]);
  const [mergedCardData, setMergedCardData] = useState([]);
  const [direction, setDirection] = useState("desc");
  const [driverFilters, setDriverFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [orgFilters, setOrgFilters] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const url = getUrl();
    getData(url);
  }, [localStorage.getItem("selectedCompany")]);

  const getUrl = () => {
    if (typeof window !== undefined) {
      if (localStorage.getItem("selectedCompany") !== null) {
        return `/discover/cards?organisation_id=${
          JSON.parse(localStorage.getItem("selectedCompany")).id
        }`;
      } else {
        return "/discover/cards";
      }
    }
  };

  const getData = async (url) => {
    try {
      const newArray = [];
      const res = await HttpServices.get(url);
      setCards(res);
      res?.results?.forEach((data) => {
        newArray.push(...data.data);
      });
      setMergedCardData(newArray);
    } catch (e) {
      console.log("error", e);
      setCards([]);
    }
  };

  const [allDriversById, allCategoriesById, allTypesById, allOrg] =
    mergedCardData?.reduce(
      (acc, c) => {
        c?.reasons?.forEach((r) => {
          if (!acc[0][r.id]) {
            acc[0][r.id] = r;
          }
        });

        if (c?.category?.id && !acc[1][c.category.id]) {
          acc[1][c.category.id] = c.category;
        }

        if (!acc[2][c.type]) {
          acc[2][c.type] = c.type;
        }
        if (c?.organisation_name) {
          acc[3][c.organisation_name] = c.organisation_name;
        } else {
          acc[3]["Form"] = "Form";
        }

        return acc;
      },
      [{}, {}, {}, {}]
    ) || [{}, {}, {}, {}];

  const filteredCards = orderBy(
    (mergedCardData || []).filter((c) => {
      let isSearched = true;
      let isDriverMatch = true;
      let isCategoryMatch = true;
      let isTypeMatch = true;
      let isOrgMatch = true;

      if (query) {
        const rgx = new RegExp(query, "ig");

        isSearched = rgx.test(JSON.stringify(c));
      }

      if (driverFilters.length) {
        isDriverMatch = driverFilters.every((driverId) =>
          c?.reasons.some((r) => r.id === driverId)
        );
      }

      if (categoryFilters.length) {
        isCategoryMatch = categoryFilters.every(
          (catId) => c?.category?.id === catId
        );
      }

      if (typeFilters.length) {
        isTypeMatch = typeFilters.every((type) => c.type === type);
      }
      if (orgFilters.length) {
        isOrgMatch = orgFilters.every((org) => {
          if (c.organisation_name === org) {
            return true;
          }
          if (c.organisation_name === null && org === "Form") {
            return true;
          }
        });
      }
      return (
        isSearched &&
        isDriverMatch &&
        isCategoryMatch &&
        isTypeMatch &&
        isOrgMatch
      );
    }),
    [
      (obj) => {
        const value = get(obj, order[0]);

        if (["updated_at", "created_at"].includes(order[0])) {
          return new Date(value);
        }

        return value;
      },
    ],
    direction
  );
  const cardsToUse = chunk(filteredCards, CARDS_PER_PAGE)[page - 1];

  const pages = filteredCards?.length
    ? Math.ceil(filteredCards.length / CARDS_PER_PAGE)
    : 1;

  const handleSearch = (newQuery) => {
    setQuery(newQuery);
  };

  const handleOrder = (newOrder) => {
    const newState = [newOrder];

    if (isEqual(newState, order)) {
      setDirection(direction === "desc" ? "asc" : "desc");
    } else {
      setDirection("asc");
    }

    setOrder(newState);
    setPage(1);
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
  };

  const handleFilter = (cb) => (arg) => {
    setPage(1);
    cb(arg);
  };

  const handleRemove = async (id) => {
    await HttpServices.delete(`/discover/cards/${id}`);
    getData(getUrl());
    return mutate("/discover/cards");
  };

  const handleUpdate = async (newCard) => {
    await HttpServices.post(`/discover/cards/${newCard.id}`, {
      card: newCard,
    });

    return mutate("/discover/cards");
  };

  return (
    <CardsContext.Provider
      value={{
        cards: cardsToUse,
        chosenCardId,
        productConfig: cards.productConfig,
        setChosenCardId,
        cardEditActive,
        setCardEditActive,
        searchQuery: query,
        search: handleSearch,
        direction,
        order,
        reOrder: handleOrder,
        driverFilters,
        categoryFilters,
        typeFilters,
        orgFilters,
        fetchCards: getData,
        getUrl: getUrl,
        filterByDriver: handleFilter(setDriverFilters),
        filterByCategory: handleFilter(setCategoryFilters),
        filterByType: handleFilter(setTypeFilters),
        filterByOrg: handleFilter(setOrgFilters),
        allDrivers: Object.values(allDriversById),
        allCategories: Object.values(allCategoriesById),
        allTypes: Object.values(allTypesById),
        allOrg: allOrg,
        page,
        pages,
        setPage: handleSetPage,
        remove: handleRemove,
        update: handleUpdate,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};
