import { useContext } from "react";
import { PollScoreReasonsContext } from "../contexts/PollScoreReasonsContext";
import { ReasonsContext } from "../contexts/ReasonsContext";
import { chain, sum } from "lodash";
import Emojis from "../Emojis";
import { PollScoresContext } from "../contexts/PollScoresContext";
import { Box, Center, Flex, Image } from "@chakra-ui/react";

export default () => {
  const reasons = useContext(ReasonsContext);
  const scores = useContext(PollScoresContext);
  const pollScoreReasons = useContext(PollScoreReasonsContext);

  // if (!scores.length) {
  //   return null;
  // }

  const reasonsById = chain(reasons)
    .groupBy("id")
    .mapValues((vals) => vals[0])
    .value();

  const reasonItems = chain(pollScoreReasons)
    .reduce((acc, r) => {
      if (!acc[r.reason_id]) {
        acc[r.reason_id] = {
          positive: 0,
          negative: 0,
          trends: [],
        };
      }

      acc[r.reason_id].trends.push(r.reason_trend);

      if (r.reason_trend > 0) {
        acc[r.reason_id].positive += parseInt(r.count);
      } else {
        acc[r.reason_id].negative += parseInt(r.count);
      }

      return acc;
    }, {})
    .toPairs()
    .orderBy(
      [
        (val) => {
          return sum(val[1].trends) / val[1].trends.length;
        },
        "[1].positive",
      ],
      ["asc"]
    )
    .value();

  const highestCount = reasonItems.reduce((acc, item) => {
    const highest = Math.max(item[1].positive, item[1].negative);

    if (highest > acc) {
      return highest;
    }

    return acc;
  }, 0);

  const styles = {
    reasonStatContainer: {
      width: "20px",
      mx: 2,
      my: 0,
    },
  };

  return (
    <Box fontFamily={"heading"} position={"fixed"} bottom={0} width={"100%"}>
      {pollScoreReasons.length > 0 && reasons.length > 0 && (
        <Flex
          p={4}
          height={"175px"}
          justifyContent={"center"}
          alignItems={"flex-end"}
        >
          {reasonItems
            .filter(([reasonId]) => {
              return reasonsById[reasonId];
            })
            .map(([reasonId, item]) => {
              const reason = reasonsById[reasonId];
              const emoji = Emojis[reason.emoji] || {};

              const positiveStyles = {
                height: `${Math.floor(45 * (item.positive / highestCount))}px`,
              };
              const negativeStyles = {
                height: `${Math.floor(45 * (item.negative / highestCount))}px`,
              };

              return (
                <Flex
                  alignItems={"center"}
                  flexDirection={"column"}
                  key={reasonId}
                  mx={3}
                  mt={"auto"}
                >
                  <Flex
                    alignItems={"flex-end"}
                    mb={2}
                    textAlign={"center"}
                    fontSize={"12px"}
                  >
                    <div>
                      <span>{item.negative}</span>
                      <Box
                        {...styles.reasonStatContainer}
                        {...negativeStyles}
                        background={"form.4"}
                      />
                    </div>
                    <div>
                      <span>{item.positive}</span>
                      <Box
                        {...styles.reasonStatContainer}
                        {...positiveStyles}
                        background={"form.10"}
                      />
                    </div>
                  </Flex>
                  <Center height={"38px"}>
                    <Image
                      display={"block"}
                      maxWidth={emoji.width * 0.8 + "px"}
                      maxHeight={emoji.height * 0.8 + "px"}
                      width={"auto"}
                      height={"auto"}
                      src={emoji.image}
                      alt={reason.emoji}
                    />
                  </Center>
                  <Box fontSizes={"12px"}>{reason.title}</Box>
                </Flex>
              );
            })}
        </Flex>
      )}
    </Box>
  );
};
