import React, { useState } from "react";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import UserSearchResults from "../components/UserSearchResults";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import FeatureFlagsModal from "../components/FeatureFlagsModal";

export default({user}) => {
    const theme = Themes[useColorScheme()];
    const [searching, setSearching] = useState(false);
    const [searchStr, setSearchStr] = useState("");
    const [results, setResults] = useState(null);
    const [showFeatureFlags, setShowFeatureFlags] = useState(null);
    
    const localStyles = {
        searchBar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
        },
        buttonSpacer: {
            marginLeft: 20
        }
    };

    const updateUserActive = (user, active) => {
        var updatedResults = results.concat([]);

        for (var i = 0; i < updatedResults.length; i++) {
            if (updatedResults[i].id === user.id) {
                updatedResults[i].active = active;
                break;
            }
        }

        setResults(updatedResults);
    };

    const onToggleActive = async(userToChange) => {
        if (userToChange.active) {
            updateUserActive(userToChange, false);
            await HttpServices.delete("/admin/user/disable", {
                user_id: userToChange.id
            });
        } else {
            updateUserActive(userToChange, true);
            await HttpServices.post("/admin/user/enable", {
                user_id: userToChange.id
            });
        }
    };

    const search = async () => {
        if (searchStr === "") {
            return;
        }

        setResults(null);
        setSearching(true);
        
        const searchResults = await HttpServices.get(`/admin/user/search?s=${searchStr}`);

        setResults(searchResults);
        setSearching(false);
    };

    const onGoToUser = (user) => {
        setShowFeatureFlags(user);
    };

    return (
        <div>
            <h1 style={theme.styles.pageHeading}>User Management</h1>
            
            <div style={localStyles.searchBar}>
                <TextBox
                    label="Find user"
                    value={searchStr}
                    onChange={(val) => setSearchStr(val)}
                    onEnter={() => search()}
                />
                <div style={localStyles.buttonSpacer}>
                    <Button
                        height={40}
                        width={50}
                        label="Search"
                        onClick={() => search()}
                        loading={searching}
                    />
                </div>
                
            </div>
            
            <UserSearchResults
                results={results}
                onGoToUser={(user) => onGoToUser(user)}
                onToggleActive={(user) => onToggleActive(user)}
            />

            <FeatureFlagsModal
                user={showFeatureFlags}
                onHide={() => setShowFeatureFlags(null)}
            />

        </div>
    )
};