import React from "react";
import { Heading } from "@chakra-ui/react";
import AppsManagement from "../components/AppsManagement";
import { useHistory } from "react-router-dom";
import FeatureFlagServices from "../services/FeatureFlagServices";
import HttpServices from "../services/HttpServices";

const Apps = () => {
  const featureFlagOn = FeatureFlagServices.isFeatureOn(
    HttpServices.getUser(),
    FeatureFlagServices.APPS_ADMIN_FLAG
  );
  const history = useHistory();

  if (!featureFlagOn) {
    history.push("/");
    return null;
  }
  return (
    <div>
      <Heading variant={"h1"} mb={6}>
        Apps
      </Heading>
      <AppsManagement />
    </div>
  );
};

export default Apps;
