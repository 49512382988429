import { createContext, useContext, useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import { PollContext } from "./PollContext";

export const PollScoreReasonsContext = createContext([]);

export const PollScoreReasonsProvider = ({ children, polling = true }) => {
  const [reasons, setReasons] = useState([]);
  const { id } = useContext(PollContext);

  const pollReasons = () => {
    const url = `/polls/instances/${id}/reasons`;

    return HttpServices.get(url).then((result) => {
      setReasons(result);

      if (polling) {
        setTimeout(pollReasons, 2000);
      }
    });
  };

  useEffect(() => {
    if (id) {
      pollReasons();
    }
  }, []);

  return (
    <PollScoreReasonsContext.Provider value={reasons}>
      {children}
    </PollScoreReasonsContext.Provider>
  );
};
