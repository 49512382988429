import React, { useContext, useState } from "react";
import { Box, Button, Flex, useToast, VStack } from "@chakra-ui/react";
import CardTypeFormControl from "./CardTypeFormControl";
import DriversFormControl from "./DriversFormControl";
import CategoryFormControl from "./CategoryFormControl";
import CardFormControl from "./CardFormControl";
import { useButtonStyles } from "../Button";
import { CardContext } from "../../contexts/CardContext";
import { useFormik } from "formik";
import SuccessToast from "../SuccessToast";
import { discoverCardTypes } from "../../services/DiscoverCardServices";
import { get } from "lodash";
import { filterEmptyProps } from "../../services/ObjectServices";

const CardEditForm = ({ fetchCards, getUrl, setCardEditActive }) => {
  const buttonStyles = useButtonStyles();
  const toast = useToast();
  const { card, update, updateOrg } = useContext(CardContext);
  const [saving, setSaving] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: card.title || "",
      description: card.description || "",
      image_url: card.image_url,
      type: card.type || "",
      body: card.body || "",
      category_id: card.category_id || "",
      reasons: card.reasons || [],
      config: card.config || {},
    },
    onSubmit: async (values) => {
      setSaving(true);
      if (
        typeof window !== undefined &&
        window.localStorage.getItem("selectedCompany") !== null
      ) {
        updateOrg({
          ...values,
          category_id: values.category_id || null,
        })
          .then(() => {
            setSaving(false);
            return toast({
              duration: 3000,
              position: "top-right",
              render: () => {
                return <SuccessToast>Card Saved</SuccessToast>;
              },
            });
          })
          .finally(() => {
            fetchCards(getUrl());
            setCardEditActive(false);
          });
      } else {
        update({
          ...values,
          category_id: values.category_id || null,
        })
          .then(() => {
            setSaving(false);
            return toast({
              duration: 3000,
              position: "top-right",
              render: () => {
                return <SuccessToast>Card Saved</SuccessToast>;
              },
            });
          })
          .finally(() => {
            fetchCards(getUrl());
            setCardEditActive(false);
          });
      }
    },
  });

  const checkDisabled = () => {
    if (formik.values.type === "article") {
      return !(formik.values.type && formik.values.reasons.length);
    }
    return !(
      formik.values.type &&
      formik.values.title &&
      formik.values.reasons.length
    );
  };

  const cardType = discoverCardTypes.find(
    (cardType) => cardType.id === formik.values.type
  );

  return (
    <Box onSubmit={formik.handleSubmit} as={"form"}>
      <VStack
        spacing={4}
        display={"inline-flex"}
        position={"relative"}
        zIndex={2}
      >
        <CardTypeFormControl
          value={formik.values.type}
          onChange={formik.handleChange}
        />
        {cardType && (
          <>
            <DriversFormControl
              values={formik.values.reasons}
              onChange={(newReasons) => {
                formik.setFieldValue("reasons", newReasons);
              }}
            />
            <CategoryFormControl
              value={formik.values.category_id}
              onChange={(newId) => formik.setFieldValue("category_id", newId)}
            />
          </>
        )}
        {cardType &&
          cardType.features.map((feature) => {
            const fieldProps = filterEmptyProps({
              helperText: get(cardType, `fieldHelperText[${feature}]`),
              label: get(cardType, `fieldLabels[${feature}]`),
            });

            return (
              <CardFormControl
                key={feature}
                values={formik.values}
                feature={feature}
                onChange={(name, value) => {
                  if (name === "all") {
                    formik.setValues(value);
                  } else {
                    formik.setFieldValue(name, value);
                  }
                }}
                {...fieldProps}
              />
            );
          })}
      </VStack>
      <Flex justifyContent={"flex-end"} mt={4} position={"relative"} zIndex={1}>
        <Button
          type={"submit"}
          {...buttonStyles}
          // isDisabled={saving}
          isDisabled={checkDisabled() || saving}
          width={"100px"}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default CardEditForm;
