import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

const FREQUENCY_DAILY = {value: "daily", label: "Daily"};
const FREQUENCY_WEEKLY = {value: "weekly", label: "Weekly"};
const FREQUENCIES = [FREQUENCY_DAILY, FREQUENCY_WEEKLY];

const DAYS = [
    {value: 1, label: "Monday"},
    {value: 2, label: "Tuesday"},
    {value: 3, label: "Wednesday"},
    {value: 4, label: "Thursday"},
    {value: 5, label: "Friday"},
    {value: 6, label: "Saturday"},
    {value: 7, label: "Sunday"}
];

const TIMES = [
    {value: 0, label: "Midnight"},
    {value: 1, label: "1am"},
    {value: 2, label: "2am"},
    {value: 3, label: "3am"},
    {value: 4, label: "4am"},
    {value: 5, label: "5am"},
    {value: 6, label: "6am"},
    {value: 7, label: "7am"},
    {value: 8, label: "8am"},
    {value: 9, label: "9am"},
    {value: 10, label: "10am"},
    {value: 11, label: "11am"},
    {value: 12, label: "12pm"},
    {value: 13, label: "1pm"},
    {value: 14, label: "2pm"},
    {value: 15, label: "3pm"},
    {value: 16, label: "4pm"},
    {value: 17, label: "5pm"},
    {value: 18, label: "6pm"},
    {value: 19, label: "7pm"},
    {value: 20, label: "8pm"},
    {value: 21, label: "9pm"},
    {value: 22, label: "10pm"},
    {value: 23, label: "11pm"}
    
]

export default({config, onChange}) => {
    if (!config) {
        return null;
    }
    
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        dropDown: {
            padding: 10,
            borderRadius: 6,
            borderColor: theme.colors.border,
            outline: theme.colors.primary,
        },
        label: {
            marginLeft: 10,
            marginRight: 10
        }
    };

    const onChangeField = (field, value) => {
        var updatedConfig = Object.assign({}, config);
        updatedConfig[field] = value;

        onChange(updatedConfig);
    };

    const renderDay = () => {
        if (config.frequency !== FREQUENCY_WEEKLY.value) {
            return null;
        }

        return (
            <div style={localStyles.container}>
                <div style={localStyles.label}>on</div>
                <select value={config.day_of_week} style={localStyles.dropDown} onChange={(evt) => onChangeField("day_of_week", evt.target.value)}>
                    {DAYS.map((day) => {
                        return (
                            <option key={day.value} value={day.value}>{day.label}</option>
                        );
                    })}
                </select>
            </div>
           
        );
    };

    return (
        <div style={localStyles.container}>
            <select value={config.frequency} style={localStyles.dropDown} onChange={(evt) => onChangeField("frequency", evt.target.value)}>
                {FREQUENCIES.map((frequency) => {
                    return (
                        <option key={frequency.value} value={frequency.value}>{frequency.label}</option>
                    );
                })}
            </select>

            {renderDay()}

            <div style={localStyles.label}>at</div>

            <select value={config.hour_of_day} style={localStyles.dropDown} onChange={(evt) => onChangeField("hour_of_day", evt.target.value)}>
                {TIMES.map((time) => {
                    return (
                        <option key={time.value} value={time.value}>{time.label}</option>
                    );
                })}
            </select>
        </div>
        
    )
};