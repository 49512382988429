import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import BaseFormControl from "../BaseFormControl";
import { MdDownload } from "react-icons/md";
import HttpServices from "../../services/HttpServices";

const ExternalArticleLinkFormControl = ({ onChange, value }) => {
  const [loading, setLoading] = useState(false);

  const handleArticleDownload = async () => {
    if (!value) {
      alert("Enter a valid url to make the request");
      return;
    }
    setLoading(true);

    try {
      const result = await HttpServices.get(
        `/discover/cards/external-article?url=${value}`
      );
      onChange({
        title: result.title,
        description: result.description,
        image_url: result.image,
      });
    } catch (e) {
      alert(
        "There was an error requesting article data. Make sure article is publicly available and not behind a login system or paywall"
      );
    }

    setLoading(false);
  };

  return (
    <BaseFormControl
      name={"external-article"}
      value={value}
      label={"External Article Link"}
      helperText={
        "Add the full url of the article. Use the button on the right to fetch data from this url and pre-populate card data"
      }
      inputComponent={
        <InputGroup>
          <Input
            name={"external-article"}
            value={value || ""}
            onChange={(e) =>
              onChange({
                config: {
                  url: e.target.value,
                },
              })
            }
          />
          <InputRightAddon
            onClick={handleArticleDownload}
            children={
              <Icon
                fontSize={"20px"}
                as={MdDownload}
                opacity={loading ? "0.6" : "1"}
              />
            }
            cursor={loading ? "cross" : "pointer"}
            pointerEvents={loading ? "none" : "auto"}
          />
        </InputGroup>
      }
    />
  );
};

ExternalArticleLinkFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...FormControl.propTypes,
};

export default ExternalArticleLinkFormControl;
