import React from "react";
import PropTypes from "prop-types";
import { FormControl, Text } from "@chakra-ui/react";
import useApi from "../../hooks/useApi";
import SearchFormControl from "../SearchFormControl";

const FormWebsiteLinkFormControl = ({ value, onChange, ...props }) => {
  const { data: articles } = useApi("/discover/cards/form-articles", {
    defaultValue: [],
  });

  const handleSelect = (article) => {
    onChange(article);
  };

  return (
    <SearchFormControl
      items={articles}
      getItemValue={(i) => i.title}
      value={""}
      label={"Form Website Article"}
      afterLabel={() => (
        <Text>
          {(value && (
            <a href={value} target={"_blank"} rel={"noreferrer"}>
              {value}
            </a>
          )) ||
            "No Article Chosen"}
        </Text>
      )}
      helperText={
        "Search for an article from the FormScore.today website using the input below"
      }
      placeholder={"Search Here"}
      onSearch={(a, query) => {
        const rgx = new RegExp(query, "ig");

        return rgx.test(a.title) || rgx.test(a.config.url);
      }}
      onChange={handleSelect}
      {...props}
    />
  );
};

FormWebsiteLinkFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...FormControl.propTypes,
};

export default FormWebsiteLinkFormControl;
