import React, { useContext } from "react";
import {
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { DiscoverCategoriesContext } from "../contexts/DiscoverCategoriesContext";
import { useFormik } from "formik";
import BaseFormControl from "../components/BaseFormControl";
import { MdDelete } from "react-icons/md";
import { useButtonStyles } from "../components/Button";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";
import SuccessToast from "../components/SuccessToast";
import { useIsAdminMode } from "../services/UserCompanyServices";

const DiscoverCategories = () => {
  const isAdminMode = useIsAdminMode();
  const buttonStyles = useButtonStyles({ width: "100px" });
  const toast = useToast();
  const categories = useContext(DiscoverCategoriesContext);

  const listColumns = "1fr 2fr 1fr 1fr 1fr";

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: "",
      order: 0,
    },
    onSubmit: async (values, helpers) => {
      await HttpServices.post("/discover/categories", {
        category: values,
      });

      await mutate("/discover/categories");

      helpers.setValues({
        title: "",
        description: "",
        type: "",
        order: 0,
      });

      return toast({
        duration: 3000,
        render: () => <SuccessToast>Category Added</SuccessToast>,
      });
    },
  });

  const handleEdit = (categoryId, prop) => async (newValue) => {
    return HttpServices.post(`/discover/categories/${categoryId}`, {
      category: {
        [prop]: newValue,
      },
    });
  };

  const handleDelete = async (categoryId) => {
    await HttpServices.delete(`/discover/categories/${categoryId}`);
    await mutate("/discover/categories");

    return toast({
      duration: 3000,
      render: () => <SuccessToast>Category Deleted</SuccessToast>,
    });
  };

  if (!isAdminMode) {
    return (
      <Text>
        You can only view this page in Admin Mode with an admin level user
      </Text>
    );
  }

  return (
    <div>
      <Heading as={"h1"} mb={4}>
        Discover Card Categories
      </Heading>
      <Grid gridTemplateColumns={"250px 1fr"} gridGap={6}>
        <div>
          <Heading mb={4} as={"h2"} fontSize={"22px"}>
            Add new category
          </Heading>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={2}>
              <BaseFormControl
                label={"Title"}
                name={"title"}
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              <BaseFormControl
                label={"Description"}
                name={"description"}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              <BaseFormControl
                label={"Type"}
                name={"type"}
                value={formik.values.type}
                onChange={formik.handleChange}
              />
              <BaseFormControl
                label={"Order"}
                name={"order"}
                type={"number"}
                value={formik.values.order}
                onChange={formik.handleChange}
              />
            </VStack>
            <Flex justifyContent={"flex-end"} width={"100%"} mt={4}>
              <Button type={"submit"} {...buttonStyles}>
                Add
              </Button>
            </Flex>
          </form>
        </div>
        <div>
          <Heading mb={2} as={"h2"} fontSize={"22px"}>
            All categories
          </Heading>
          <Text mb={4}>Click on a property to edit</Text>
          <Grid
            gridTemplateColumns={listColumns}
            borderBottom={"1px solid"}
            pb={2}
            mb={2}
            gridGap={2}
          >
            <Text>Title</Text>
            <Text>Description</Text>
            <Text textAlign={"center"}>Type</Text>
            <Text textAlign={"center"}>Order</Text>
            <Text textAlign={"center"}>Actions</Text>
          </Grid>
          <VStack spacing={2} display={"block"}>
            {categories.map((c) => {
              return (
                <Grid
                  key={c.id}
                  gridTemplateColumns={listColumns}
                  gridGap={2}
                  alignItems={"center"}
                >
                  <Editable
                    defaultValue={c.title}
                    onSubmit={handleEdit(c.id, "title")}
                  >
                    <EditablePreview />
                    <EditableInput />
                  </Editable>
                  <Editable
                    defaultValue={c.description}
                    onSubmit={handleEdit(c.id, "description")}
                  >
                    <EditablePreview />
                    <EditableInput />
                  </Editable>
                  <Editable
                    textAlign={"center"}
                    defaultValue={c.type}
                    onSubmit={handleEdit(c.id, "type")}
                  >
                    <EditablePreview minHeight={"30px"} minWidth={"50px"} />
                    <EditableInput />
                  </Editable>
                  <Editable
                    textAlign={"center"}
                    defaultValue={String(c.order)}
                    onSubmit={handleEdit(c.id, "order")}
                  >
                    <EditablePreview minHeight={"30px"} minWidth={"50px"} />
                    <EditableInput type={"number"} />
                  </Editable>
                  <HStack
                    justifyContent={"center"}
                    spacing={2}
                    alignItems={"center"}
                  >
                    <Icon as={MdDelete} onClick={() => handleDelete(c.id)} />
                  </HStack>
                </Grid>
              );
            })}
          </VStack>
        </div>
      </Grid>
    </div>
  );
};

DiscoverCategories.propTypes = {};

export default DiscoverCategories;
