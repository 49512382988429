import React, { useEffect, useState } from "react";
import MsTeamsUsers from "../components/MsTeamsUsers";
import TotalSeatCount from "../components/TotalSeatCount";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import { Heading, useToast } from "@chakra-ui/react";
import LinkMSTeamsSubscription from "../components/LinkMsTeamsSubscription";
import SuccessToastDrivers from "../components/SuccessToastDrivers";


export default ({ companies }) => {
  const theme = Themes[useColorScheme()];
  const [seats, setSeats] = useState([]);
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [isParentOrg, setIsParentOrg] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const localStyles = {
    headlineStats: {
      display: "flex",
      flexDirection: "row",
      width: 320,
      justifyContent: "space-between",
    },
    container: {
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      paddingRight: "10%",
      minWidth: 320,
      // backgroundColor: theme.colors.primary,
    },
    total: {
      color: theme.colors.black,
      fontSize: 16,
      fontWeight: "500",
    },
    description: {
      color: theme.colors.black,
      fontSize: 14,
      opacity: 0.8,
      fontWeight: "500",
      marginTop: 10,
      display: "flex",
    },
    headlineDetails: {
      display: "flex",
      flexDirection: "row",
      width: 320,
      justifyContent: "space-between",
      marginBottom: 10,
    },
  };

  useEffect(() => {
    reloadSeats();
    getSubs();
  }, [companies]);

  const getSubs = async () => {
    try {
      const res = await HttpServices.get(
        "/user/msteams/v1/get-unassigned-active-subscription"
      );
      setSubscriptions(res);
    } catch (e) {
      console.log("error", e);
      setSubscriptions([]);
    }
  };

  const reloadSeats = async () => {
    if (companies.selected) {
      const loadedOrg = await HttpServices.get(
        `/organisation/${companies.selected.id}`
      );
      setOrganisation(loadedOrg);
      const reloadedAnalytics = await HttpServices.get(
        `/user/msteams/v1/org-seats?org_id=${companies.selected.id}`
      );
      if (!reloadedAnalytics.success) {
        setIsParentOrg(false);
      } else {
        setSeats(reloadedAnalytics);
        setIsParentOrg(true);
      }
      setLoading(false);
    }
  };

  const linkSubscription = async (subs_id) => {
    try {
      let body = {
        org_id: organisation.id,
        subs_id,
      };
      setLoading(true);
      await HttpServices.put(
        `/user/msteams/v1/link-unassigned-subscription`,
        {
          ...body,
        }
      );
      reloadSeats();
      return toast({
        duration: 3000,
        render: () => {
          return (
            <SuccessToastDrivers>Subscription Linked Successfully</SuccessToastDrivers>
          );
        },
      });
    } catch (e) {
      alert("Error linking subscription. Please try again");
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (isParentOrg) {
      return (
        <div>
          <Heading variant={"h2"}>Subscription Details</Heading>
          <div style={localStyles.headlineDetails}>
            <div style={localStyles.container}>
              <div style={localStyles.description}>Subscription</div>
              <div style={localStyles.total}>
                {seats.subScriptionDetails.subscription_name}
              </div>
            </div>
            <div style={localStyles.container}>
              <div style={localStyles.description}>Offer</div>
              <div style={localStyles.total}>
                {seats.subScriptionDetails.offer_id}
              </div>
            </div>
          </div>
          <div style={localStyles.headlineDetails}>
            <div style={localStyles.container}>
              <div style={localStyles.description}>Plan</div>
              <div style={localStyles.total}>
                {seats.subScriptionDetails.plan_id}
              </div>
            </div>
            <div style={localStyles.container}>
              <div style={localStyles.description}>Purchase Date</div>
              <div style={localStyles.total}>
                {seats.subScriptionDetails.subscription_name}
              </div>
            </div>
          </div>
          <div style={localStyles.headlineDetails}>
            <div style={localStyles.container}>
              <div style={localStyles.description}>Status</div>
              <div style={localStyles.total}>
                {seats.subScriptionDetails.isActive ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
          <div style={localStyles.headlineStats}>
            <TotalSeatCount
              label="Total"
              total={seats?.subScriptionDetails?.seats || 0}
            />
            <TotalSeatCount
              label="Utilized"
              total={seats?.seatsUtilized || 0}
            />
          </div>
          <MsTeamsUsers
            users={seats?.users}
            organisation={companies.selected.id}
            reload={reloadSeats}
            seats={seats}
          />
        </div>
      );
    } else {
      if (organisation.parent_id) {
        return (
          <div>
            <Heading variant={"h2"}>Subscription Details</Heading>
            <p>
              This is not a Parent Organisation. Go to Parent Organisation to
              access this page
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <Heading variant={"h2"}>Subscription Details</Heading>
            <p>No Active Subscription is Linked to this Organisation.</p>
            {subscriptions.data && subscriptions.data.length > 0 && (
              <LinkMSTeamsSubscription
                data={subscriptions.data}
                action={linkSubscription}
              />
            )}
          </div>
        );
      }
    }
  };

  return (
    <>
      <h1 style={theme.styles.pageHeading}>MS Teams Subscription</h1>
      <>{renderContent()}</>
    </>
  );
};
