import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  List,
  ListItem,
  useOutsideClick,
} from "@chakra-ui/react";

const SearchFormControl = ({
  items,
  getItemValue,
  getItemKey,
  afterItem = () => {},
  value,
  label,
  afterLabel = () => {},
  helperText,
  placeholder,
  onSearch,
  onChange,
  ...props
}) => {
  const [query, setQuery] = useState(value);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuWrapper = useRef();

  useOutsideClick({
    ref: menuWrapper,
    handler: () => setMenuOpen(false),
  });

  const searchedItems = items.filter((a) => {
    if (!query) {
      return true;
    }

    return onSearch(a, query);
  });

  const handleSelect = (item) => {
    onChange(item);
    setQuery(getItemValue(item));
    setMenuOpen(false);
  };

  return (
    <FormControl zIndex={2} {...props}>
      {label && <FormLabel>{label}</FormLabel>}
      {afterLabel()}
      {helperText && <FormHelperText mb={1}>{helperText}</FormHelperText>}
      <Box position={"relative"} ref={menuWrapper}>
        <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onClick={() => setMenuOpen(true)}
          onFocus={() => setMenuOpen(true)}
          placeholder={placeholder}
        />
        <Box
          position={"absolute"}
          top={"100%"}
          width={"100^%"}
          left={0}
          background={"alwaysWhite"}
        >
          <Collapse in={menuOpen} animateOpacity>
            <Box shadow={"md"} py={2}>
              <List maxHeight={"200px"} overflow={"auto"}>
                {searchedItems.map((i) => (
                  <ListItem
                    py={1}
                    px={2}
                    key={(getItemKey && getItemKey(i)) || getItemValue(i)}
                    cursor={"pointer"}
                    borderBottom={"1px solid"}
                    borderColor={"transparent"}
                    color={"alwaysBlack"}
                    _hover={{
                      borderColor: "borderContrast",
                    }}
                    onClick={() => handleSelect(i)}
                  >
                    {getItemValue(i)}
                    {afterItem(i)}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </FormControl>
  );
};

SearchFormControl.propTypes = {
  items: PropTypes.array.isRequired,
  getItemValue: PropTypes.func.isRequired,
  getItemKey: PropTypes.func,
  afterItem: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  afterLabel: PropTypes.func,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  ...FormControl.propTypes,
};

export default SearchFormControl;
