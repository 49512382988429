import React, { useContext } from "react";
import { CardsContext } from "../../contexts/CardsContext";
import { Circle, Flex, HStack, Icon, Input, Text } from "@chakra-ui/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/all";

const CardsPagination = () => {
  const { page, pages, setPage } = useContext(CardsContext);

  if (pages <= 1) {
    return null;
  }
  return (
    <Flex justifyContent={"flex-end"}>
      <HStack spacing={4} userSelect={"none"}>
        <Circle
          size={"40px"}
          background={"border"}
          cursor={"pointer"}
          onClick={() => setPage(Math.max(1, page - 1))}
        >
          <Icon as={BsChevronLeft} />
        </Circle>
        <HStack spacing={1} alignItems={"center"}>
          <Text mr={1}>Page: </Text>
          <Input
            mr={1}
            value={page}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                setPage(Number(e.target.value));
              }
            }}
            type={"number"}
            min={1}
            max={pages}
            w={"50px"}
            textAlign={"center"}
            p={1}
          />
          <Text>/</Text>
          <Text>{pages}</Text>
        </HStack>
        <Circle
          size={"40px"}
          background={"border"}
          cursor={"pointer"}
          onClick={() => setPage(Math.min(pages, page + 1))}
        >
          <Icon as={BsChevronRight} />
        </Circle>
      </HStack>
    </Flex>
  );
};

export default CardsPagination;
