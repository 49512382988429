import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default ({ user }) => {
  const theme = Themes[useColorScheme()];

  return (
    <div>
      <h1 style={theme.styles.h1}>Hello {user.first_name}</h1>
    </div>
  );
};
