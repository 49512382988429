import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import FormSpinner from "./FormSpinner";
import LowScoreResource from "./LowScoreResource";
import ProfilePic from "../components/ProfilePic";

export default({token, score}) => {
    const theme = Themes[useColorScheme()];
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        titleHolder: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        picHolder: {
            marginRight: 5
        }, 
        selector: {
            marginTop: 10,
            width: 280,
            maxHeight: 400,
            borderRadius: 4,
            overflow: "auto",
            borderColor: theme.colors.border,
            borderWidth: 1,
            borderStyle: "solid",
            padding: 10
        },
        resourcesHolder: {
            maxWidth: 500,
            width: "100%",
            display: "flex",
            flexDirection: "column"
        },
        orgName: {
            flex: 1
        },
        org: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            marginBottom: 10,
            borderColor: theme.colors.border,
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: theme.colors.white
        },
        title: {
            marginTop: 14
        },
        logoHolder: {
            marginRight: 10
        },
        logo: {
            width: 28,
            height: 28
        },
        buttonsArea: {
            marginTop: 30
        },
    };

    useEffect(() => {
        reload();
    }, []);

    const reload = async() => {
        const availableResources = await HttpServices.get(`/score/email/low/resources?token=${token}`);
        setResources(availableResources);
        setLoading(false);
    };

    if (loading) {
        return (
            <FormSpinner/>
        );
    }


    return (
        <div style={localStyles.container}>
            <h1 style={theme.styles.headerNoMargin}>Your score is very low</h1>
            <p>Here are some resources which may help</p>

            <div style={localStyles.resourcesHolder}>
                {resources.map((category) => {
                    var pic = null;

                    if (category.organisation) {
                        pic = (
                            <ProfilePic
                                size={20}
                                user={category.organisation ? category.organisation : {override: "F"}}
                            />
                        );
                    } else {
                        pic = (
                            <img alt="Form Logo" src={theme.images.emailFormLogo} style={localStyles.logo}/>
                        );
                    }


                    return (
                        <div key={category.organisation ? category.organisation.id : "form"}>
                            <div style={localStyles.titleHolder}>
                                <div style={localStyles.logoHolder}>
                                    {pic}
                                </div>
                                
                                <h3 style={localStyles.title}>{category.organisation ? "Provided by " + category.organisation.name : "Provided by Form"}</h3>
                            </div>
                            
                            <div>
                                {category.resources.map((resource) => {
                                    return (
                                        <LowScoreResource
                                            key={resource.id}
                                            token={token}
                                            score={score}
                                            resource={resource}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};