import React from "react";
import PropTypes from "prop-types";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import showdown from "showdown";

const converter = new showdown.Converter();

const ContentFormControl = ({ value, label, onChange, ...props }) => {
  const handleOnChange = (newValue) => {
    const newMd = converter
      .makeMd(newValue)
      .replace(/(<\/?span.*?>)/g, "")
      .replace(/(<!--\s*-->)/g, "");
    onChange(newMd);
  };

  return (
    <FormControl
      sx={{
        "*, *:before, *:after": {
          color: "black",
        },
        ".ql-snow .ql-picker-options *:before": {
          color: "alwaysBlack",
        },
        ".ql-snow .ql-stroke": {
          stroke: "currentColor",
        },
        ".ql-editor.ql-blank::before": {
          color: "transparentBody",
        },
        ".ql-editor p, .ql-editor ul": {
          marginBottom: 3,
        },
      }}
      {...props}
    >
      <FormLabel>{label}</FormLabel>
      <ReactQuill
        defaultValue={(value && converter.makeHtml(value)) || ""}
        onChange={handleOnChange}
        theme={"snow"}
        placeholder={"Start typing here"}
      />
    </FormControl>
  );
};

ContentFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...Box.propTypes,
};

export default ContentFormControl;
