import React, { useEffect, useState } from "react";
import { useButtonStyles } from "./Button";
import TextBox from "./TextBox";
import { Button, Heading, InputLeftAddon } from "@chakra-ui/react";
import { useFormik } from "formik";

export default ({ organisation, onSave }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  const buttonStyles = useButtonStyles({ disabled: !hasChanged, width: 60 });

  const formik = useFormik({
    initialValues: {
      name: organisation.name || "",
      short_name: organisation.short_name || "",
      base_email: organisation.base_email || "",
    },
    onSubmit: (values) => {
      setSaving(true);
      onSave(values.name, values.short_name, values.base_email);
    },
  });

  useEffect(() => {
    setHasChanged(false);
    setSaving(false);
  }, [organisation]);

  const localStyles = {
    fieldHolder: {
      marginBottom: 20,
    },
  };

  const onChange = (prop) => (val) => {
    setHasChanged(true);
    formik.setFieldValue(prop, val);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading variant="h2">Organisation Details</Heading>
      <div style={localStyles.fieldHolder}>
        <TextBox
          label="Name"
          value={formik.values.name}
          width={400}
          onChange={onChange("name")}
        />
      </div>
      <div style={localStyles.fieldHolder}>
        <TextBox
          label="Short Name"
          value={formik.values.short_name}
          width={400}
          onChange={onChange("short_name")}
        />
      </div>
      <div style={localStyles.fieldHolder}>
        <TextBox
          label={"Organisation base email address"}
          value={formik.values.base_email}
          width={400}
          onChange={onChange("base_email")}
          leftAddon={() => <InputLeftAddon children={"@"} />}
        />
      </div>

      <Button {...buttonStyles} disabled={!hasChanged} type={"submit"}>
        Save
      </Button>
    </form>
  );
};
