import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import HttpServices from "../services/HttpServices";
import UserCompanyServices from "../services/UserCompanyServices";
import Welcome from "./Welcome";
import TopBar from "../components/TopBar";
import Users from "./Users";
import Reports from "./Reports";
import Themes, { useChakraTheme } from "../Themes";
import useCompanies from "../hooks/useCompanies";
import useColorScheme from "../hooks/useColorScheme";
import logo from "../form_logo_word.svg";
import Drivers from "./Drivers";
import ScoresVisualisation from "./ScoresVisualisation";
import Emails from "./Emails";
import LastX from "./LastX";
import Settings from "./Settings";
import TeamsSettings from "./MSTeamsSettings";
import Analytics from "./Analytics";
import Polls from "./Polls";
import LowScores from "./LowScores";
import OrgCards from "./OrgCards";
import NewDrivers from "./NewDrivers";
import Cards from "./Cards";
import DiscoverCategories from "./DiscoverCategories";
import { DiscoverCategoriesProvider } from "../contexts/DiscoverCategoriesContext";
import { ChakraProvider } from "@chakra-ui/react";
import Apps from "./Apps";
import Streaks from "./Streaks";
import DriversEdit from "./DriversEdit";
import { DriversEditProvider } from "../contexts/DriversEditContext";
import OrganisationsEdit from "./OrganisationsEdit";
import { EditOrganisationsProvider } from "../contexts/EditOrganisationsContext";
import { OrganisationsProvider } from "../contexts/OrganisationsContext";

export default () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const theme = Themes[useColorScheme()];
  const chakraTheme = useChakraTheme();
  const companies = useCompanies();

  const localStyles = {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    content: {
      overflow: "auto",
      position: "fixed",
      top: 0,
      left: 200,
      right: 0,
      bottom: 0,
      padding: 30,
    },
    formLogo: {
      marginTop: 30,
      width: 50,
      cursor: "pointer",
    },
    noAccess: {
      backgroundColor: theme.colors.colorBackground,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 30,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.colors.alwaysWhite,
    },
  };

  useEffect(() => {
    if (!window.sessionStorage.token) {
      history.push("/login");
    }

    reloadMe();
  }, [history]);

  const reloadMe = async () => {
    try {
      const loggedInUser = await HttpServices.get("/user/me");
      HttpServices.setUser(loggedInUser);

      await UserCompanyServices.reloadUserCompanies();

      setUser(loggedInUser);
      setLoading(false);
    } catch (e) {
      history.push("/login");
    }
  };

  if (loading || !companies || !companies.companies) {
    return null;
  }

  const goToLogin = () => {
    window.location.href = "/";
  };

  if (!user.admin && companies.companies.length === 0) {
    window.sessionStorage.clear();
    window.localStorage.clear();

    return (
      <div style={localStyles.noAccess}>
        <h1 style={theme.styles.h1}>You don't have access to this</h1>
        <p>
          We're sorry but you don't have access to this page.
          <br />
          You have been automatically logged out
          <br />
          Click the logo or refresh the page to login again
        </p>
        <img
          alt="Logo"
          src={logo}
          style={localStyles.formLogo}
          onClick={() => goToLogin()}
        />
      </div>
    );
  }

  return (
    <div style={localStyles.container}>
      <Sidebar user={user} companies={companies} />
      <TopBar user={user} companies={companies} />
      <div style={localStyles.content}>
        <Switch>
          <Route exact path={"/polls"}>
            <Polls />
          </Route>
          <Route path={"/cards"}>
            <ChakraProvider theme={chakraTheme}>
              <Cards />
            </ChakraProvider>
          </Route>
          <Route path={"/discover-categories"}>
            <ChakraProvider theme={chakraTheme}>
              <DiscoverCategoriesProvider>
                <DiscoverCategories />
              </DiscoverCategoriesProvider>
            </ChakraProvider>
          </Route>
          <Route path="/users">
            <Users user={user} />
          </Route>
          <Route path="/reports">
            <ChakraProvider theme={chakraTheme}>
              <Reports user={user} />
            </ChakraProvider>
          </Route>
          <Route path="/drivers" exact>
            <Drivers user={user} companies={companies} />
          </Route>
          <Route path={"/drivers/edit"} exact>
            <ChakraProvider theme={chakraTheme}>
              <DriversEditProvider>
                <DriversEdit />
              </DriversEditProvider>
            </ChakraProvider>
          </Route>
          <Route path={"/organisations/edit"} exact>
            <ChakraProvider theme={chakraTheme}>
              <OrganisationsProvider>
                <EditOrganisationsProvider>
                  <OrganisationsEdit />
                </EditOrganisationsProvider>
              </OrganisationsProvider>
            </ChakraProvider>
          </Route>
          <Route path="/lastx">
            <LastX user={user} companies={companies} />
          </Route>
          <Route path="/emails">
            <Emails user={user} companies={companies} />
          </Route>
          <Route path="/settings">
            <Settings user={user} companies={companies} />
          </Route>
          <Route path="/ms-teams-settings">
            <TeamsSettings user={user} companies={companies} />
          </Route>
          <Route path={"/org-cards"}>
            <ChakraProvider theme={chakraTheme}>
              <OrgCards />
            </ChakraProvider>
          </Route>
          <Route path="/lowscores">
            <LowScores user={user} companies={companies} />
          </Route>
          <Route path="/new-drivers">
            <NewDrivers />
          </Route>
          <Route path="/analytics">
            <Analytics user={user} companies={companies} />
          </Route>
          <Route path="/streaks">
            <Streaks user={user} companies={companies} />
          </Route>
          <Route path="/scores/visualisation">
            <ScoresVisualisation user={user} companies={companies} />
          </Route>
          <Route path={"/apps"}>
            <ChakraProvider theme={chakraTheme}>
              <Apps />
            </ChakraProvider>
          </Route>
          <Route path="/" exact>
            <Welcome user={user} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
