import React from "react";
import { select } from "d3-selection";
import { colorScale, formatNumberWithCommas } from "./helpers";

export default class extends React.Component {
  componentDidUpdate() {
    this.renderScore(this.props.data, this.props.numberOfScores);
  }

  renderScore(score, nScores) {
    select(".score__main").style(
      "background-color",
      colorScale(Math.floor(score))
    );
    select("#score__main").html(score);
    select("#score__amount").html(formatNumberWithCommas(nScores || 0));
  }

  render() {
    return (
      <div className="score tile">
        <h2>Average score</h2>
        {!!this.props.data && (
          <div className="score__stats">
            <div className="score__main">
              <h1 id="score__main">0</h1>
            </div>
            <p>
              <span id="score__amount">0</span> scores
            </p>
          </div>
        )}
      </div>
    );
  }
}
