import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import Button from "../components/Button";
import EmailFrequencySelector from "../components/EmailFrequencySelector";
import EmailManager from "../components/EmailManager";
import EmailForceSend from "../components/EmailForceSend";
import EmailToggleJoinSubTeams from "../components/EmailToggleJoinSubTeams";
import EmailToggleMustJoinSubTeam from "../components/EmailToggleMustJoinSubTeam";
import EmailToggleSignup from "../components/EmailToggleSignup";

export default({companies}) => {
    const theme = Themes[useColorScheme()];

    const [emailConfig, setEmailConfig] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showForceSend, setShowForceSend] = useState(false);

    const reloadStatus = async () => {
        if (companies && companies.selected && companies.selected.id) {
            const status = await HttpServices.get(`/organisation/${companies.selected.id}/emails/status`);

            if (status.enabled) {
                // Load stats
                const emailAnalytics = await HttpServices.get(`/organisation/${companies.selected.id}/emails/analytics`);

                setAnalytics(emailAnalytics);
                setEmailConfig(status.config);
            } else {
                setEmailConfig(null);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        reloadStatus();
    }, [companies]);

    const onChangeEmailConfig = async (config) => {
        setEmailConfig(config);
        await HttpServices.patch(`/organisation/${companies.selected.id}/emails/config`, {
            hour_of_day: parseInt(config.hour_of_day),
            day_of_week: config.frequency === "weekly" ? parseInt(config.day_of_week) : undefined,
            frequency: config.frequency,
            join_sub_teams: config.join_sub_teams,
            must_join_sub_team: config.must_join_sub_team,
            allow_signup: config.allow_signup,
            domains: config.domains
        });
    }

    const toggleStatus = async () => {
        setLoading(true);

        if (emailConfig) {
            // We are disabling
            await HttpServices.post(`/organisation/${companies.selected.id}/emails/disable`);
            reloadStatus();
        } else {
            // We are enabling
            await HttpServices.post(`/organisation/${companies.selected.id}/emails/enable`, {
                hour_of_day: 9,
                frequency: "daily"
            });

            reloadStatus();
        }

    };

    const renderEditables = () => {
        if (!emailConfig) {
            return null;
        }

        return (
            <div>
                <h2>Frequency</h2>
                <EmailFrequencySelector
                    config={emailConfig}
                    onChange={(config) => onChangeEmailConfig(config)}
                />
                <EmailToggleJoinSubTeams
                    config={emailConfig}
                    onChange={(config) => onChangeEmailConfig(config)}
                />
                <EmailToggleMustJoinSubTeam
                    config={emailConfig}
                    onChange={(config) => onChangeEmailConfig(config)}
                />
                <EmailToggleSignup
                    config={emailConfig}
                    onChange={(config) => onChangeEmailConfig(config)}
                    organisation={companies.selected}
                />
                <EmailManager
                    organisation={companies.selected}
                    showLogging={showForceSend}
                    analytics={analytics}
                />
                <EmailForceSend
                    visible={showForceSend}
                    onHide={() => setShowForceSend(false)}
                    organisation={companies.selected}
                />
            </div>
        );
    }
    
    return (
        <div>
            <h1 style={theme.styles.pageHeading} onDoubleClick={() => setShowForceSend(true)}>Emails</h1>
            <p>In addition to users with the Form app, you can optionally send emails to users without the app to collect their scores anonymously.</p>
            <Button
                label={emailConfig ? "Disable" : "Enable"}
                width={100}
                loading={loading}
                dangerous={emailConfig ? true : false}
                onClick={() => toggleStatus()}/>
            {renderEditables()}
        </div>
    );


};