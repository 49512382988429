import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

const MAX_BAR_WIDTH = 550;
const MAX_BAR_HEIGHT = 350;

export default ({ scores }) => {
  const theme = Themes[useColorScheme()];

  const getBars = () => {
    var bars = [];

    for (var i = 1; i <= 10; i++) {
      const percent = scores[i + ""] / scores.count;

      bars.push({
        id: i,
        count: scores[i + ""],
        width: MAX_BAR_WIDTH * percent,
        height: MAX_BAR_HEIGHT * percent,
      });
    }

    return bars;
  };

  const getMaxHeight = (bars) => {
    var max = 0;

    bars.forEach((bar) => {
      if (bar.height > max) {
        max = bar.height;
      }
    });

    return max;
  };

  const bars = getBars();
  const maxHeight = getMaxHeight(bars);

  return (
    <Center>
      <Flex direction="row" height={`${MAX_BAR_HEIGHT + 50}px`}>
        {bars.map((row) => {
          return (
            <Flex
              direction="column"
              marginRight="10px"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                flexDirection="column"
                height={`${MAX_BAR_HEIGHT}px`}
                alignItems="center"
                justifyContent="flex-end"
                marginBottom="10px"
              >
                <Flex backgroundColor={theme.colors.lightText}>
                  {row.count && row.count > 0 ? (
                    <Text fontSize="xs">{row.count}</Text>
                  ) : null}
                </Flex>
                <Flex
                  transition="all 0.5s ease-in-out"
                  height={`${(row.height / maxHeight) * 100}%`}
                  borderRadius="2px"
                  direction="row"
                  width="15px"
                  backgroundColor={theme.colors.form[row.id]}
                ></Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                width="40px"
                height="40px"
                borderRadius="20px"
                backgroundColor={theme.colors.form[row.id]}
              >
                <Text fontSize="sm" color="#ffffff">
                  {row.id}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Center>
  );
};
