import React, {useState} from "react";
import HttpServices from "../services/HttpServices";
import Modal from "./Modal";
import TextBox from "./TextBox";

export default({visible, organisation, onHide, onRefresh}) => {
    const [email, setEmail] = useState("");
    const [adding, setAdding] = useState(false);

    const localStyles = {
        content: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            width: "100%"
        }
    }

    const renderContent = () => {
        return (
            <div style={localStyles.content}>
                <TextBox
                    label="Email"
                    value={email}
                    width={300}
                    onChange={(val) => setEmail(val)}
                />
            </div>
        );
    }

    const hide = () => {
        setEmail("");
        onHide();
    }

    const addEmail = async() => {
        setAdding(true);
        
        await HttpServices.post(`/organisation/${organisation.id}/emails`, {
            email: email
        });

        await onRefresh();

        hide();
        setAdding(false);
    };

    return (
        <Modal
            title="Add an email"
            visible={visible}
            height="40%"
            buttons={{
                left: [
                    {id: "cancel", label: "Cancel", onClick: () => hide(), width: 60}
                ],
                right: [
                    {
                        id: "save", 
                        label: "Save", 
                        onClick: () => addEmail(), 
                        width: 60, 
                        loading: adding,
                        disabled: email.length < 3 || email.indexOf("@") < 0 || email.indexOf(".") < 0
                    }
                ]
            }}
            onHide={() => hide()}
            renderContent={() => renderContent()}
        />
    );


};