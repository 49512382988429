import React, { createContext } from "react";
import useApi from "../hooks/useApi";
import { getId } from "../helpers/helpers";
import { useIsAdminMode } from "../services/UserCompanyServices";
export const ReasonsContext = createContext([]);

export const ReasonsProvider = ({ children }) => {
  const isAdminMode = useIsAdminMode();
  const orgId = getId(isAdminMode);
  let addOrgId = "";
  if (!isAdminMode && orgId) {
    addOrgId = `?org_id=${orgId}`;
  }
  const { data: reasons } = useApi(`/score/reasons/all${addOrgId}`, {
    defaultValue: [],
  });

  return (
    <ReasonsContext.Provider value={reasons}>
      {children}
    </ReasonsContext.Provider>
  );
};
