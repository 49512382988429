import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import { useIsAdminMode } from "../services/UserCompanyServices";

import Modal from "./Modal";
import TextBox from "./TextBox";

import { getId } from "../helpers/helpers";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import SuccessToastDrivers from "./SuccessToastDrivers";

export default ({ visible, editing, onHide }) => {
  const theme = Themes[useColorScheme()];
  const toast = useToast();
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const isAdminMode = useIsAdminMode();

  useEffect(() => {
    if (editing?.emoji) {
      setSelectedImage(editing.emoji);
    }
  }, [editing]);

  const clear = () => {
    setTitle(null);
  };

  useEffect(() => {
    if (editing) {
      setTitle(editing.title);
    } else {
      clear();
    }
  }, [editing]);

  const localStyles = {
    content: {
      width: "100%",
    },
    contentDisplay: {
      width: "80%",
      display: "flex",
      flexFlow: "wrap",
      // justifyContent: "space-between",
      padding: 15,
      overflow: "auto",
    },
    text: {
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 5,
    },
    fieldHolder: {
      padding: 20,
    },
    emoji: {
      width: 40,
      height: 40,
      cursor: "pointer",
      padding: 8,
    },
    image_wrapper: {
      marginTop: 35,
    },
    btn: {
      cursor: "pointer",
    },
  };

  const images = [
    {
      url: theme.images.autonomy,
      title: "autonomy",
    },
    {
      url: theme.images.compensation,
      title: "compensation",
    },
    {
      url: theme.images.confidence,
      title: "confidence",
    },
    {
      url: theme.images.culture,
      title: "culture",
    },
    {
      url: theme.images.feedback,
      title: "feedback",
    },
    {
      url: theme.images.fertility,
      title: "fertility",
    },
    {
      url: theme.images.flexibility,
      title: "flexibility",
    },
    {
      url: theme.images.grief,
      title: "grief",
    },
    {
      url: theme.images.job_security,
      title: "job_security",
    },
    {
      url: theme.images.line_manager,
      title: "line_manager",
    },
    {
      url: theme.images.menopause,
      title: "menopause",
    },
    {
      url: theme.images.phycological_safety,
      title: "phycological_safety",
    },
    {
      url: theme.images.recognition,
      title: "recognition",
    },
    {
      url: theme.images.transaction,
      title: "transaction",
    },
    {
      url: theme.images.work_stress,
      title: "work_stress",
    },
    {
      url: theme.images.assessment_centers,
      title: "assessment_centers",
    },
    {
      url: theme.images.interviews,
      title: "interviews",
    },
    {
      url: theme.images.rejection,
      title: "rejection",
    },
    {
      url: theme.images.time_management,
      title: "time_management",
    },
    {
      url: theme.images.uncertainty,
      title: "uncertainty",
    },
    {
      url: theme.images.workload,
      title: "workload",
    },
  ];

  const handleImgClick = (title) => {
    setSelectedImage(title);
  };

  const renderContent = () => {
    return (
      <div style={localStyles.content}>
        <div style={localStyles.fieldHolder}>
          <TextBox
            label="Driver name"
            value={title}
            width={400}
            onChange={(val) => setTitle(val)}
            inputProps={{ maxLength: 11 }}
          />
          <div style={localStyles.image_wrapper}>
            <p style={localStyles.text}>Driver image</p>
            <div style={localStyles.contentDisplay}>
              {images.map((image) => {
                return (
                  <img
                    alt="Driver Emoji"
                    style={localStyles.emoji}
                    src={image.url}
                    onClick={() => handleImgClick(image.title)}
                    className={
                      image.title.includes(selectedImage) ? "active-img" : ""
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const hide = () => {
    clear();
    onHide();
  };

  const canSave = () => {
    if (!title || title.length === 0 || !selectedImage) {
      return false;
    }
    return true;
  };

  const save = async () => {
    const userId = getId(isAdminMode);

    var body = {
      title: title,
      description: "description is optional",
      image_url: selectedImage,
      dark_image_url: selectedImage,
      organisation_id: userId,
    };

    if (editing) {
      setSaving(true);
      await HttpServices.post(
        `/reasons/organisation/save?organisation_id=${userId}`,
        {
          ...body,
          reason_id: editing.id,
        }
      );
      hide();
      setSaving(false);
      return toast({
        duration: 3000,
        render: () => {
          return <SuccessToastDrivers>Driver Edited</SuccessToastDrivers>;
        },
      });
    } else {
      setSaving(true);
      await HttpServices.post(
        `/reasons/organisation/save?organisation_id=${userId}`,
        body
      );
      hide();
      setSaving(false);
      return toast({
        duration: 3000,
        render: () => {
          return <SuccessToastDrivers>Driver Added</SuccessToastDrivers>;
        },
      });
    }
  };

  return (
    <Modal
      title={`${editing ? "Edit" : "Add"} New Driver`}
      visible={visible}
      width="50%"
      height="60%"
      buttons={{
        left: [
          { id: "cancel", label: "Cancel", onClick: () => hide(), width: 60 },
        ],
        right: [
          {
            id: "save",
            label: "Save",
            onClick: () => save(),
            width: 60,
            loading: saving,
            disabled: !canSave(),
          },
        ],
      }}
      onHide={() => hide()}
      renderContent={() => renderContent()}
    />
  );
};
