import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DriverSelect from "../components/DriverSelect";
import HttpServices from "../services/HttpServices";
import { PollContext } from "../contexts/PollContext";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";

export default () => {
  const history = useHistory();
  const { scoreId } = useParams();
  const { id } = useContext(PollContext);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    positive: [],
    negative: [],
  });
  const [tab, setTab] = useState("positive");

  const handleSave = async () => {
    setLoading(true);
    if (!selected.positive.length && !selected.negative.length) {
      history.push(`/p/thank-you`);
      return;
    }

    const calls = [];

    const url = `/polls/instances/${id}/scores/${scoreId}/reasons`;

    if (selected.positive.length) {
      calls.push(
        HttpServices.post(url, {
          reasonTrend: 1,
          reasonIds: selected.positive,
        })
      );
    }

    if (selected.negative.length) {
      calls.push(
        HttpServices.post(url, {
          reasonTrend: -1,
          reasonIds: selected.negative,
        })
      );
    }

    Promise.all(calls)
      .catch((e) => {
        console.error(e);
        alert(
          "There was an error saving your form score reasons. Please try again"
        );
        setLoading(false);
      })
      .then(() => {
        history.push(`/p/thank-you`);
      });
  };

  const styles = {
    tab: {
      padding: "5px 20px",
      cursor: "pointer",
      userSelect: "none",
      width: "50%",
    },
  };

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      height={"calc(100vh - 86px)"}
      textAlign={"center"}
      p={4}
      maxWidth={"480px"}
      mx={"auto"}
      userSelect={"none"}
      sx={{
        touchAction: "manipulation",
      }}
    >
      <Heading fontSize={"18px"} textAlign={"center"} mb={2}>
        What is affecting your Form today?
      </Heading>
      <Flex
        mb={4}
        width={"100%"}
        borderRadius={"10px"}
        border={"1px solid"}
        borderColor={"border"}
        overflow={"hidden"}
      >
        <Box
          onClick={() => setTab("positive")}
          {...styles.tab}
          color={tab === "positive" ? "alwaysWhite" : ""}
          background={tab === "positive" ? "form.10" : "none"}
        >
          Positive
        </Box>
        <Box width={"1px"} height={"34px"} background={"border"} />
        <Box
          onClick={() => setTab("negative")}
          {...styles.tab}
          color={tab === "negative" ? "alwaysWhite" : ""}
          background={tab === "negative" ? "form.4" : "none"}
        >
          Negative
        </Box>
      </Flex>
      <div>
        <DriverSelect
          display={tab === "positive" ? "grid" : "none"}
          onSelect={(items) =>
            setSelected({
              ...selected,
              positive: items,
            })
          }
          trend={"positive"}
        />
        <DriverSelect
          display={tab === "negative" ? "grid" : "none"}
          onSelect={(items) =>
            setSelected({
              ...selected,
              negative: items,
            })
          }
          trend={"negative"}
        />
      </div>
      <Flex
        justifyContent={"center"}
        p={4}
        width={"100%"}
        bottom={0}
        position={"fixed"}
      >
        <Button
          background={"primary"}
          color={"alwaysWhite"}
          fontSize={"22px"}
          p={2}
          disabled={loading}
          width={"100%"}
          maxWidth={"440px"}
          opacity={loading ? 0.5 : 1}
          height={"auto"}
          onClick={handleSave}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
