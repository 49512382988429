import React, {useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import {
    useLocation,
    useHistory
} from "react-router-dom";

import Button from "../components/Button";
import HttpServices from "../services/HttpServices";
import Checkbox from "../components/Checkbox";

export default() => {
    const theme = Themes[useColorScheme()];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const [merged, setMerged] = useState(false);
    const [token] = useState(query.get("t"));
    const [saving, setSaving] = useState(false);
    const [agreed, setAgreed] = useState(false);

    const localStyles = {
        container: {
            padding: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        centered: {
            textAlign: "center"
        },
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        buttonsArea: {
            marginTop: 30
        },
        logo: {
            width: 150,
            height: 150
        },
        checkboxRow: {
            marginTop: 30,
            display: "flex",
            flexDirection: "row"
        },
        appIcons: {
            marginTop: 30,
            display: "flex",
            flexDirection: "row",
            width: 300,
            justifyContent: "space-around",
            alignItems: "center"
        },
        appStoreBadge: {
            cursor: "pointer",
            width: 120,
            height: 40
        },
        playStoreBadge: {
            width: 135,
            height: 40,
            cursor: "pointer"
        }
    };

    const mergeAccount = async() => {
        setSaving(true);

        await HttpServices.post(`/score/email/merge`, {
            token: token
        });

        setMerged(true);
        setSaving(false);
    };

    const onGoToAppStore = () => {
        window.open("https://apps.apple.com/app/id1549717355");
    };

    const onGoToPlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=today.formscore.app");
    };

    const renderContent = () => {
        if (!sessionStorage["token"]) {
            history.push(`/email/merge/login?${token ? `t=${token}` : ""}`);
        } else if (merged) {
            return (
                <div style={localStyles.content}>
                    <h1 style={localStyles.centered}>Your account has been linked</h1>
                    <p style={localStyles.centered}>Your account has now been linked. Open up your Form app to record scores. If you haven't yet downloaded Form, get it on the links below</p>

                    <div style={localStyles.appIcons}>
                        <img 
                            alt="Apple App Store"
                            src={theme.images.appStoreBadge}
                            style={localStyles.appStoreBadge}
                            onClick={() => onGoToAppStore()}/>

                        <img 
                            alt="Google Play Store"
                            src={theme.images.playStoreBadge}
                            style={localStyles.playStoreBadge}
                            onClick={() => onGoToPlayStore()}/>
                        
                    </div>
                
                </div>
            );
        } else {
            return (
                <div style={localStyles.content}>
                    <h1 style={localStyles.centered}>Link your email to Form Account</h1>
                    <p style={localStyles.centered}>Click the button below to link your email to a full Form account. This will allow you to use the app to track your score.</p>

                    <div style={localStyles.checkboxRow}>
                        <Checkbox
                            on={agreed}
                            onChange={() => setAgreed(!agreed)}
                        />
                        <div>I agree to the <a href="https://www.formscore.today/terms">Terms & Conditions</a></div>
                    </div>

                    <div style={localStyles.buttonsArea}>
                        <Button
                            label="Link Account"
                            loading={saving}
                            disabled={!agreed}
                            onClick={() => mergeAccount()}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <div style={localStyles.container}>
            <img 
                alt="Logo"
                style={localStyles.logo}
                src={theme.images.formLogo150}
            />
            {renderContent()}
        </div>
    );
};