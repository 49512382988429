import React, { createContext, useContext, useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import { PollContext } from "./PollContext";

export const PollScoresContext = createContext([]);

let timer;

export const PollScoresProvider = ({ children, polling = true }) => {
  const { id } = useContext(PollContext);
  const [scores, setScores] = useState([]);

  const pollScores = () => {
    const url = `/polls/instances/${id}/scores`;

    return HttpServices.get(url).then((result) => {
      setScores(result);

      if (polling) {
        clearTimeout(timer);
        timer = setTimeout(pollScores, 2000);
      }
    });
  };

  useEffect(() => {
    if (id) {
      clearTimeout(timer);
      timer = setTimeout(pollScores, 100);
    }
  }, [id]);

  return (
    <PollScoresContext.Provider value={scores}>
      {children}
    </PollScoresContext.Provider>
  );
};
