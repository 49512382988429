export const arrayToHashMap = (arr, prop) => {
  return (arr || []).reduce((acc, item) => {
    acc[item[prop]] = item;

    return acc;
  }, {});
};

export const filterEmptyProps = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
};
