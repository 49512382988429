import React from "react";
import PropTypes from "prop-types";
import { mutate } from "swr";
import { Box, Checkbox, Heading, useToast } from "@chakra-ui/react";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import SuccessToast from "./SuccessToast";
import { arrayToHashMap } from "../services/ObjectServices";

const EDITABLE_PERMISSIONS = {
  FORM_REPORTING_APP: "FORM_REPORTING_APP",
};
const permissionLabels = {
  [EDITABLE_PERMISSIONS.FORM_REPORTING_APP]: "Generated PDF reporting",
};

const OrganisationPermissions = ({ organisation }) => {
  const { data: apps } = useApi("/apps", { defaultValue: [] });
  const appsByToken = arrayToHashMap(apps, "token");
  const { data: permissions } = useApi(`/apps/permissions/${organisation.id}`, {
    defaultValue: [],
  });
  const toast = useToast();

  const handleOnChange = async (newState, appId) => {
    const method = newState ? "post" : "delete";
    const url = newState
      ? `/apps/${appId}/join`
      : `/apps/${appId}/organisations/${organisation.id}`;
    await HttpServices[method](url, {
      organisation_id: organisation.id,
    });
    await mutate(`/apps/permissions/${organisation.id}`);

    return toast({
      duration: 1500,
      position: "top-right",
      render: () => <SuccessToast>Permission updated</SuccessToast>,
    });
  };

  return (
    <Box mt={6}>
      <Heading variant={"h2"} mb={2}>
        Permissions
      </Heading>
      {Object.values(EDITABLE_PERMISSIONS).map((appToken) => {
        const app = appsByToken[appToken];
        const isChecked = permissions.some((p) => p.app_token === appToken);
        return (
          <Box key={appToken}>
            <Checkbox
              isChecked={isChecked}
              onChange={(e) => {
                handleOnChange(e.target.checked, app && app.id);
              }}
            >
              {permissionLabels[appToken]}
            </Checkbox>
          </Box>
        );
      })}
    </Box>
  );
};

OrganisationPermissions.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default OrganisationPermissions;
