import React, {useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Modal from "../components/Modal";
import LowScoreVideoModal from "./LowScoreVideoModal";
import HttpServices from "../services/HttpServices";

const TYPE_LINK = "link";
const TYPE_EMAIL = "email";
const TYPE_REACH_OUT = "reachout";
const TYPE_VIDEO = "video";



export default({resource, score, token}) => {
    const theme = Themes[useColorScheme()];

    const [reachingOut, setReachingOut] = useState(false);
    const [reachedOut, setReachedOut] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);

    const localStyles = {
        container: {
            marginBottom: 6,
            marginLeft: 10,
            cursor: "pointer"
        },
        resourceIcon: {
            width: 19,
            height: 19,
            marginRight: 10
        },
        padded: {
            paddingLeft: 30,
            paddingRight: 30
        },
        link: {
            display: "flex",
            alignItems: "center",
            color: theme.colors.primary,
            fontWeight: "500"
        }
    }

    const trackClick = () => {
        try {
            HttpServices.post(`/score/email/low/resources/${resource.id}/track`, {
                token: token
            });
        } catch (e) {
            // Don't worry if we can't track
            console.error(e);
        }
    };

    const onClick = () => {
        trackClick();


        if (resource.type === TYPE_LINK) {
            window.open(resource.config.url, "_blank");
        } else if (resource.type === TYPE_EMAIL) {
            window.open(`mailto:${resource.config.email}?subject=I have low Form`);
        } else if (resource.type === TYPE_REACH_OUT) {
            setShowModal(true);
        } else if (resource.type === TYPE_VIDEO) {
            if (resource.config && resource.config.nativeVideos) {
                if (score && score === "2") {
                    setShowVideoModal(theme.videos["2"]);
                } else {
                    setShowVideoModal(theme.videos["1"]);
                }
            } else if (resource.config && resource.config.url) {
                setShowVideoModal(resource.config.url);
            }

        }
    };

    const reachOut = async() => {
        setReachingOut(true);

        try {
            await HttpServices.post(`/score/email/low/resources/${resource.id}/reachout`, {
                token: token
            });

            setReachedOut(true);
            setReachingOut(false);
        } catch (e) {
            alert("We were unable to share your details");
            setReachingOut(false);
        }
    };

    const hideModal = () => {
        // TODO figure out what is going on...
        // No idea why I need to do the below! Seems to be some issue at play though which requires a timeout and I don't have the time to troubleshoot!
        setTimeout(() => {
            setReachingOut(false);
            setReachedOut(false);
            setShowModal(false);
        });
       
    }; 
    
    const getReachOutMessage = () => {
        if (reachedOut) {
            return "We have shared your details. Somebody will be in touch soon."
        }

        return "This will discretely share your contact details with your organisation representative so that they can get in touch with you.";
    };

    const hideVideoModal = () => {
        setTimeout(() => {
            setShowVideoModal(false)   
        })
    };

    const renderVideoModal = () => {
        if (resource.type !== TYPE_VIDEO) {
            return null;
        }

        return (
            <LowScoreVideoModal
                resource={resource}
                video={showVideoModal}
                onHide={() => hideVideoModal()}
            />
        );
    }

    const renderModal = () => {
        if (resource.type !== TYPE_REACH_OUT) {
            return null;
        }

        return (
            <Modal
                title="Share contact details"
                visible={showModal}
                height="40%"
                buttons={reachedOut ? {
                    right: [
                        {
                            id: "done",
                            label: "OK", 
                            onClick: () => hideModal(), 
                            width: 60
                        }
                    ]
                } : {
                    left: [
                        {
                            id: "cancel", 
                            label: "Cancel", 
                            onClick: (evt) => hideModal(evt), 
                            width: 60}
                    ],
                    right: [
                        {
                            id: "accept", 
                            loading: reachingOut,
                            label: "Accept", 
                            onClick: () => reachOut(), 
                            width: 60
                        }
                    ]
                }}
                renderContent={() => {
                    return (
                        <div style={localStyles.padded}>
                            <p>{getReachOutMessage()}</p>
                        </div>
                    );
                }}
                onHide={() => setShowModal(false)}
            />
        );
    };

    return (
        <div style={localStyles.container} onClick={() => onClick()}>
            <div style={localStyles.link}>
                <img src={theme.images.resources[resource.type]} alt={resource.type} style={localStyles.resourceIcon}/>
                {resource.title}
            </div>
            {renderModal()}
            {renderVideoModal()}
        </div>
    );

};