import { sum } from "lodash";
import React, { useContext } from "react";
import { PollScoresContext } from "../contexts/PollScoresContext";
import { FaUserAlt } from "react-icons/fa";
import { Box, Center, Circle, HStack, Text } from "@chakra-ui/react";

export default () => {
  const scores = useContext(PollScoresContext);

  

  var average = 0;

  if (!Array.isArray(scores)) {
    average = scores.avg || 0;
  } else {
    average = sum(scores.map((s) => s.score || 0)) / scores.length;
  }

  if (Number.isNaN(average)) {
    average = 0;
  }

  const roundedAverage = Math.round(average);

  return (
    <HStack spacing={4} py={4} fontFamily={"heading"}>
      <Center>
        <Box fontSize={"25px"} mr={1} className={"icon"}>
          <FaUserAlt />
        </Box>
        <Text fontSize={"30px"} m={0}>
          <Box as={"span"} fontSize={"22px"}>
            x
          </Box>
          {scores.count ? scores.count : scores.length}
        </Text>
      </Center>
      {average > 0 && (
        <Circle size={"50px"} background={`form.${roundedAverage}`}>
          <Box fontSize={"20px"} m={0} color={"alwaysWhite"}>
            {average.toFixed(1)}
          </Box>
        </Circle>
      )}
    </HStack>
  );
};
