import { Center, Flex } from "@chakra-ui/react";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import React, { Fragment } from "react";
import { animated, useSpring } from "@react-spring/web";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import { PollScoreVariant } from "./PollScores";
import AggregatedPollScoreBars from "./AggregatedPollScoreBars";

const ORDERED_SCORES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

export default({variant, scores, width, height, heightToSubtract, widthToSubtract}) => {
    const theme = Themes[useColorScheme()];

    const textAnimation = useSpring({
        from: {
          scale: 0,
        },
        to: {
          scale: 1,
        },
      });

    if (!scores) {
        return null;
    }

    var chartData = {
        id: "poll-scores",
        value: 0,
        children: []
    };

    for (var i = 1; i <= 10; i++) {
        chartData.children.push({
            id: i,
            label: i,
            color: theme.colors.form[i],
            value: scores[i + ""]
        })
    }

    if (variant === PollScoreVariant.AllScores) {
        return (
            <AggregatedPollScoreBars
                scores={scores}
            />
        )
    };

    return (
        <Center
        className={"bs"}
        width={width - widthToSubtract}
        height={height - heightToSubtract}
        mx={"auto"}
        position={"fixed"}
        left={"50%"}
        top={"50%"}
        transform={"translate(-50%, -50%)"}
        >
        <ResponsiveCirclePacking
            data={chartData}
            id={"id"}
            value={"value"}
            colorBy={"id"}
            colors={({ data }) => {
            return data.color;
            }}
            isInteractive={false}
            leavesOnly
            padding={15}
            enableLabels
            label={(props) => props.data.label}
            labelComponent={({ node, style, label }) => {
            return (
                <Fragment>
                <animated.text
                    textAnchor={"middle"}
                    dominantBaseline={"central"}
                    fill={theme.colors.alwaysWhite}
                    style={{
                    fontSize: Math.max(14, node.radius / 2),
                    fontFamily: "Fugue Regular",
                    ...style,
                    ...textAnimation,
                    }}
                >
                    {`#${node.data.value}`}
                </animated.text>
                </Fragment>
            );
            }}
        />
        </Center>
  );
    
    // return (
    //     <Flex direction="row">
    //         {ORDERED_SCORES.map((scoreId) => {
    //             console.log(scores);
    //             const aggregatedScore = scores[scoreId];

    //             return (
    //                 <Flex key={scoreId}>
    //                     <div>{scoreId} - {aggregatedScore}</div>
    //                 </Flex>
    //             )
    //         })}
    //     </Flex>
        
    // );
};