import HttpServices from "./HttpServices";
import { useState } from "react";

const UserCompanyServices = {
  userCompanies: null,
  selectedCompany: null,
  adminMode: false,
  listeners: {},

  addListener(id, listener) {
    this.listeners[id] = listener;
  },

  removeListener() {},

  canAdmin() {
    if (HttpServices.getUser() && HttpServices.getUser().admin) {
      return true;
    }

    return false;
  },

  notify() {
    if (Object.keys(this.listeners).length > 0) {
      Object.values(this.listeners).forEach((listener) => {
        listener({
          companies: this.userCompanies,
          selected: this.selectedCompany,
          admin: this.adminMode,
        });
      });
    }
  },

  setAdminMode() {
    this.selectedCompany = null;
    this.adminMode = true;
    window.localStorage.removeItem("selectedCompany");
    this.notify();
    window.location.href = "/";
  },

  setSelectedCompany(company) {
    this.selectedCompany = company;
    window.localStorage.selectedCompany = JSON.stringify(company);

    if (this.adminMode) {
      // We were in admin mode
      this.adminMode = false;
      window.location.href = "/";
    }

    this.notify();
  },

  async reloadUserCompanies() {
    const companies = await HttpServices.get("/organisation");
    this.userCompanies = companies;

    if (!this.selectedCompany && companies.length > 0) {
      if (window.localStorage.selectedCompany) {
        // We have a company stored, see if its in our list
        const savedCompany = JSON.parse(window.localStorage.selectedCompany);

        for (const company of companies) {
          if (company.id === savedCompany.id) {
            this.selectedCompany = company;
          }
        }
      }

      if (!this.selectedCompany) {
        // If user is admin then we can put them in admin mode

        // Still nothing selected, so select first
        if (this.canAdmin()) {
          this.selectedCompany = null;
          this.adminMode = true;
        } else {
          this.selectedCompany = companies[0];
        }
      }
    }

    this.notify();
  },
};

export default UserCompanyServices;

export const useIsAdminMode = () => {
  const user = HttpServices.getUser();
  const [isAdminMode, setIsAdminMode] = useState(
    user && user.admin && UserCompanyServices.adminMode
  );

  UserCompanyServices.addListener("isAdminHook", ({ admin }) => {
    if (isAdminMode !== admin) {
      setIsAdminMode(admin && user && user.admin);
    }
  });

  return isAdminMode;
};
