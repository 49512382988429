import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { ReasonsContext } from "../../contexts/ReasonsContext";
import { groupBy } from "lodash";
import ChipsFormInput from "../ChipsFormInput";

const DriversFormControl = ({ values, onChange }) => {
  const reasons = useContext(ReasonsContext);
  const reasonById = groupBy(reasons, "id");
  const [selected, setSelected] = useState(values || []);

  const handleAdd = (id) => {
    const newSelected = [...selected, id];
    setSelected(newSelected);
    onChange(newSelected);
  };

  const handleRemove = (index) => {
    const newSelected = selected.filter((item, i) => i !== index);
    setSelected(newSelected);
    onChange(newSelected);
  };

  if (!reasons.length) {
    return null;
  }

  return (
    <ChipsFormInput
      label={"Drivers"}
      helperText={
        "Choose the drivers associated with this card. Note: the first in the list will be use as the Primary Driver"
      }
      options={reasons.filter((reason) => !selected.includes(reason.id))}
      selected={selected.map((id) => reasonById[id]?.[0])}
      selectText={"Select Driver"}
      onAdd={handleAdd}
      onDelete={handleRemove}
    />
  );
};

DriversFormControl.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  ...Box.propTypes,
};

export default DriversFormControl;
