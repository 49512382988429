import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

const ASC = "asc";
const DESC = "desc";

const DEFAULT_SORT_ON = "avg_score";
const DEFAULT_SORT_ORDER = DESC;

export default ({ report, from, to, isAdmin }) => {
  const theme = Themes[useColorScheme()];
  const [sortOn, setSortOn] = useState(
    localStorage.driverSortOn || DEFAULT_SORT_ON
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.driverSortOrder || DEFAULT_SORT_ORDER
  );

  const localStyles = {
    container: {
      marginTop: 30,
    },
    clickableHeader: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      userSelect: "none",
    },
    value: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: 100,
    },
    titleAndEmoji: {
      display: "flex",
      alignItems: "center",
    },
    emoji: {
      marginRight: 10,
      width: 20,
    },
    sortOrderAsc: {
      marginLeft: 5,
      width: 12,
      height: 10,
      transform: "rotate(180deg)",
    },
    sortOrderDesc: {
      marginLeft: 5,
      width: 12,
      height: 10,
    },
    trend: (value) => {
      return {
        backgroundColor:
          value && value < 0 ? theme.colors.red : theme.colors.green,
        color: theme.colors.alwaysWhite,
        fontSize: 10,
        fontWeight: "700",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 6,
        minWidth: 20,
        borderRadius: 12,
        marginLeft: 10,
      };
    },
  };

  if (!report) {
    return null;
  }

  const renderScore = (row, field, dp) => {
    if (!row.months[to]) {
      return null;
    }

    var value = row.months[to][field];

    if (dp && value) {
      value = value.toFixed(dp);
    }

    var previousValue = null;

    if (row.months[from]) {
      previousValue = row.months[from][field];

      if (dp && previousValue) {
        previousValue = previousValue.toFixed(dp);
      }
    }

    var trend = null;

    if (previousValue && value - previousValue !== 0) {
      var trendValue = value - previousValue;

      if (dp) {
        trendValue = trendValue.toFixed(dp);
      }

      trend = (
        <div style={localStyles.trend(trendValue)}>
          {trendValue > 0 ? "+" : ""}
          {trendValue}
        </div>
      );
    }

    return (
      <div style={localStyles.value}>
        {value} {trend}
      </div>
    );
  };

  const getSortedReport = () => {
    var sortedReport = report.concat([]);

    sortedReport.sort((a, b) => {
      if (sortOn === "title") {
        // Sorting on title
        if (sortOrder === ASC) {
          return a.title.localeCompare(b.title);
        } else {
          return b.title.localeCompare(a.title);
        }
      } else {
        var aMonthData = a.months[to];
        var bMonthData = b.months[to];

        if (!aMonthData) {
          if (sortOrder === ASC) {
            return -1;
          }

          return 1;
        }

        if (!bMonthData) {
          if (sortOrder === ASC) {
            return 1;
          }

          return -1;
        }

        if (sortOrder === ASC) {
          return aMonthData[sortOn] - bMonthData[sortOn];
        } else {
          return bMonthData[sortOn] - aMonthData[sortOn];
        }
      }
    });

    return sortedReport;
  };

  const changeSort = (col) => {
    if (sortOn === col) {
      var newSortOrder = sortOrder === ASC ? DESC : ASC;
      setSortOrder(newSortOrder);
      localStorage.driverSortOrder = newSortOrder;
    } else {
      setSortOn(col);
      localStorage.driverSortOn = col;
    }
  };

  const renderSortIcon = (col) => {
    if (col !== sortOn) {
      return null;
    }

    if (sortOrder === ASC) {
      return (
        <img
          alt="Sort Ascending"
          src={theme.images.sortOrder}
          style={localStyles.sortOrderAsc}
        />
      );
    }

    return (
      <img
        alt="Sort Descending"
        src={theme.images.sortOrder}
        style={localStyles.sortOrderDesc}
      />
    );
  };

  return (
    <div style={localStyles.container}>
      <table>
        <thead>
          <tr>
            <th>
              <div
                style={localStyles.clickableHeader}
                onClick={() => changeSort("title")}
              >
                Driver{renderSortIcon("title")}
              </div>
            </th>
            {isAdmin && (
              <th>
                <div
                  style={localStyles.clickableHeader}
                  onClick={() => changeSort("organisation")}
                >
                  Organisation{renderSortIcon("organisation")}
                </div>
              </th>
            )}
            <th>
              <div
                style={localStyles.clickableHeader}
                onClick={() => changeSort("avg_score")}
              >
                Average Score{renderSortIcon("avg_score")}
              </div>
            </th>
            <th>
              <div
                style={localStyles.clickableHeader}
                onClick={() => changeSort("avg_trend")}
              >
                Average Trend{renderSortIcon("avg_trend")}
              </div>
            </th>
            <th>
              <div
                style={localStyles.clickableHeader}
                onClick={() => changeSort("count")}
              >
                Count{renderSortIcon("count")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {getSortedReport().map((row) => {
            if (!row.months[to]) {
              return null;
            }

            return (
              <tr key={row.id}>
                <td>
                  <div style={localStyles.titleAndEmoji}>
                    <img
                      alt={`${row.emoji}`}
                      style={localStyles.emoji}
                      src={theme.images[row.emoji]}
                    />
                    {row.title}
                  </div>
                </td>
                {isAdmin && <td>{row.organisation}</td>}
                <td>{renderScore(row, "avg_score", 2)}</td>
                <td>{renderScore(row, "avg_trend", 2)}</td>
                <td>{renderScore(row, "count")}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
