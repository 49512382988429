import React, { useContext, useState } from "react";
import QrCode from "../components/QrCode";
import PollScores, { PollScoreVariant } from "../components/PollScores";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import PollStatsBar from "../components/PollStatsBar";
import {
  PollScoresContext,
  PollScoresProvider,
} from "../contexts/PollScoresContext";
import { PollContext } from "../contexts/PollContext";
import { useParams } from "react-router-dom";
import { ReasonsProvider } from "../contexts/ReasonsContext";
import { PollScoreReasonsProvider } from "../contexts/PollScoreReasonsContext";
import PollScoreReasons from "../components/PollScoreReasons";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import FormScale from "../components/FormScale";

function PollLanding() {
  const theme = Themes[useColorScheme()];
  const { number } = useParams();
  const poll = useContext(PollContext);
  const scores = useContext(PollScoresContext);
  const [scoreView, setScoreView] = useState(PollScoreVariant.AllScores);

  const toggleScoreView = () => {
    if (scoreView === PollScoreVariant.AllScores) {
      setScoreView(PollScoreVariant.ScoreAmounts);
    } else {
      setScoreView(PollScoreVariant.AllScores);
    }
  };

  if (!poll || !poll.id) {
    return null;
  }

  let qrPath = `p/${poll.short_id}/score`;

  if (number) {
    qrPath = `p/${poll.short_id}/${number}/score`;
  }

  if (!scores || !Array.isArray(scores)) {
    return <div>Hello new method</div>;
  }

  return (
    <div>
      <PollScoresProvider>
        <Flex as={"header"} justifyContent={"space-between"} py={4} px={12}>
          <div>
            <Image src={theme.images.formLogoWord} alt={"form"} width={150} />
            <PollStatsBar />
          </div>
          <div>
            <Flex flexDirection={"column"} alignItems={"flex-end"}>
              <div>
                <QrCode
                  text={`${window.location.origin}/${qrPath}`}
                  width={180}
                />
              </div>
            </Flex>
          </div>
        </Flex>
        <Button
          onClick={toggleScoreView}
          variant={"outline"}
          borderColor={theme.colors.black}
          position={"absolute"}
          top={170}
          left={"60px"}
          height={"auto"}
          fontSize={18}
          py={1}
          px={2}
        >
          Switch View
        </Button>
        {scoreView === PollScoreVariant.ScoreAmounts && (
          <Box position={"fixed"} left={"60px"} top={220}>
            <FormScale />
          </Box>
        )}
        <PollScores variant={scoreView} />
        <ReasonsProvider>
          <PollScoreReasonsProvider>
            <PollScoreReasons />
          </PollScoreReasonsProvider>
        </ReasonsProvider>
      </PollScoresProvider>
    </div>
  );
}

export default PollLanding;
