import React from "react";
import Checkbox from "./Checkbox";

export default({config, onChange}) => {
    if (!config) {
        return null;
    }

    const localStyles = {
        row: {
            display: "flex",
            flexDirection: "row"
        }
    };

    const changeToggle = () => {
        var updatedConfig = Object.assign({}, config);
        updatedConfig.join_sub_teams = !updatedConfig.join_sub_teams;
        onChange(updatedConfig);
    };

    return (
        <div>
            <h2>Join sub teams</h2>
            <div style={localStyles.row}>
                <Checkbox
                    on={config.join_sub_teams}
                    onChange={() => changeToggle()}
                />
                <div>Allow users to select sub teams to join</div>
            </div>
            
        </div>
    );

};