import React from "react";
import { Route, Switch } from "react-router-dom";
import CardArchive from "./CardArchive";
import { CardsProvider } from "../contexts/CardsContext";
import { CardProvider } from "../contexts/CardContext";
import CardEdit from "./CardEdit";
import { ReasonsProvider } from "../contexts/ReasonsContext";
import { DiscoverCategoriesProvider } from "../contexts/DiscoverCategoriesContext";
import { Text } from "@chakra-ui/react";
import { useIsAdminMode } from "../services/UserCompanyServices";

const Cards = () => {
  const isAdminMode = useIsAdminMode();

  if (!isAdminMode) {
    return (
      <Text>
        You can only view this page in Admin Mode with an admin level user
      </Text>
    );
  }

  return (
    <Switch>
      <Route exact path={"/cards"}>
        <CardsProvider>
          <CardArchive />
        </CardsProvider>
      </Route>
      <Route exact path={"/cards/:id"}>
        <ReasonsProvider>
          <DiscoverCategoriesProvider>
            <CardProvider>
              <CardEdit />
            </CardProvider>
          </DiscoverCategoriesProvider>
        </ReasonsProvider>
      </Route>
    </Switch>
  );
};

Cards.propTypes = {};

export default Cards;
