import { useState } from "react";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import moment from "moment";
import { MdDelete, MdDownload } from "react-icons/md";

const styles = {
  container: {
    marginBottom: 20,
  },
  button: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    userSelect: "none",
  },
  buttonIcon: {
    marginLeft: 5,
  },
  item: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  link: {
    marginRight: 10,
  },
};

function PollDropdownList({ items, onDelete, onDownload }) {
  const [show, setShow] = useState(false);

  return (
    <div style={styles.container}>
      <div onClick={() => setShow(!show)} style={styles.button}>
        Previous Polls
        <div className={"icon"} style={styles.buttonIcon}>
          {(show && <VscTriangleUp />) || <VscTriangleDown />}
        </div>
      </div>
      {show && (
        <div>
          {items.map((item, i) => {
            return (
              <div key={item.id} style={styles.item}>
                <a
                  href={`/p/${item.short_id}/${item.number}`}
                  style={styles.link}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {moment(item.created_at).format("MMM Do YYYY, h:mm:ssa")}
                </a>
                <div
                  onClick={onDownload(item)}
                  className={"display--block link--clean mr--10"}
                >
                  <MdDownload className={"icon--md"} />
                </div>
                <MdDelete
                  className={"icon--md"}
                  onDelete={() => onDelete(item)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PollDropdownList;
