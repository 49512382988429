import React from "react";
import { mean, extent } from "d3-array";
import { scaleLinear } from "d3-scale";
import { groupBy } from "lodash";

import { colorScale, clearSvg, setupSvg } from "./helpers";

export default class extends React.Component {

    componentDidUpdate() {
        this.renderFormie(this.props.data);
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => this.renderFormie(this.props?.data || []);

    renderFormie(data) {
        const groupedByUser = groupBy(data, function (score) {
            return score['user_id'];
        });
        const userData = []
        for (const user in groupedByUser) {
            if (Object.hasOwnProperty.call(groupedByUser, user)) {
                const element = groupedByUser[user];
                userData.push({
                    user: user,
                    n: element.length,
                    data: element,
                    avg_score: Math.round(mean(element, d => d["score"]) * 10) / 10
                });
            }
        }
        // set the dimensions and margins of the graph
        const setup = {
            id: "chart__formie",
            height: 500,
            margin: { top: 0, right: 0, bottom: 0, left: 0 }
        };
        clearSvg(setup);
        const { g, width, height } = setupSvg(setup);
        const startX = 0.15 * width;
        const startY = 0.1 * height;
        const endY = 0.2 * height;
        const anchorX = width * 0.4;
        const anchorY = 0;
        const layout = userData.map((d, i) => {
            const x1 = startX,
                  y1 = startY,
                  x2 = i % 2 === 0 ? width : startX + ((width - startX) * Math.random()),
                  y2 = i % 2 === 0 ? endY + ((height - endY) * Math.random()) : height;
            return {
                id: d.user,
                d: `M ${x1} ${y1} C ${anchorX} ${anchorY}, ${x2} ${y2}, ${x2} ${y2}`,
                stroke: colorScale(Math.floor(d.avg_score)),
            }
        });

        g.selectAll("path")
            .data(layout)
            .enter()
            .append("path")
            .attr("class", "lifeline")
            .attr("id", d => d.id)
            .attr("d", d => d.d)
            .style("stroke", d => d.stroke)
            .style("opacity", 0.5)
            .style("fill", "none");

        const timeDomain = extent(data, d => new Date(d["created_at"]));
        const points = [];
        for (const user of userData) {
            const renderedPath = document.getElementById(user.user);
            const pathLength = Math.floor(renderedPath.getTotalLength());
            const margin = 10;
            const scale = scaleLinear().domain(timeDomain).range([margin, pathLength - margin]);
            for (let index = 0; index < user.data.length; index++) {
                const score = user.data[index];
                const point = renderedPath.getPointAtLength(scale(new Date(score["created_at"])));
                points.push({
                    x: point.x,
                    y: point.y,
                    score: score.score
                });
            }
        }
        g.selectAll("circle")
            .data(points)
            .enter()
            .append("circle")
            .attr("class", "detail-circle")
            .attr("cx", d => d.x)
            .attr("cy", d => d.y)
            .attr("r", d => 12 - d.score)
            .style("fill", d => colorScale(Math.floor(d.score)));

        g.append("image")
            .attr("href", "/images/formie_2.svg")
            .attr("width", 80)
            .attr("height", 80)
            .attr("x", startX - 100)
            .attr("y", startY - 40);
    }

    render() {
        return (
            <div className="chart tile">
                <div className="row">
                    <h2>Detail</h2>
                </div>
                <div className="row">
                    <svg id="chart__formie"></svg>
                </div>
            </div>
        )
    }
}