import React, {useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import {
    useLocation
} from "react-router-dom";

import Button from "../components/Button";
import HttpServices from "../services/HttpServices";

export default() => {
    const theme = Themes[useColorScheme()];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [token] = useState(query.get("t"));


    const localStyles = {
        container: {
            padding: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        buttonsArea: {
            marginTop: 30
        },
        logo: {
            width: 150,
            height: 150
        }
    };

    const unsubscribe = async () => {
        setUnsubscribing(true);

        await HttpServices.post(`/score/email/unsubscribe`, {
            token: token
        });

        setUnsubscribed(true);
        setUnsubscribing(false);
    };

    const resubscribe = async () => {
        setUnsubscribing(true);

        await HttpServices.post(`/score/email/resubscribe`, {
            token: token
        });

        setUnsubscribed(false);
        setUnsubscribing(false);
    };

   

    const renderContent = () => {
        if (unsubscribed) {
            return (
                <div style={localStyles.content}>
                    <h1>You've been unsubscribed</h1>
                    <p>We're sorry to see you go. If you made a mistake, just click the Resubscribe button below.</p>
    
                    <div style={localStyles.buttonsArea}>
                        <Button
                            label="Resubscribe"
                            loading={unsubscribing}
                            onClick={() => resubscribe()}
                        />
                    </div>
                </div> 
            );
        }

        return (
            <div style={localStyles.content}>
                <h1>Unsubscribe</h1>
                <p>If you no longer wish to receive emails from your organisation for Form then please click the button below. This will remove you from the mailing list.</p>

                <div style={localStyles.buttonsArea}>
                    <Button
                        label="Unsubscribe"
                        loading={unsubscribing}
                        onClick={() => unsubscribe()}
                    />
                </div>
            </div> 
        );
    };

    return (
        <div style={localStyles.container}>
            <img 
                alt="Logo"
                style={localStyles.logo}
                src={theme.images.formLogo150}
            />
            {renderContent()}
        </div>
    );
};