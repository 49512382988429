import React from "react";

import Modal from "../components/Modal";
import ReactPlayer from "react-player";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

export default({video, resource, onHide}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        content: {
            position: "relative",
            width: "100%",
            height: "100%"
        },
        video: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.colors.actualBlack,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    };
    
    return (
        <Modal
            title={resource.title}
            visible={video}
            height="80%"
            width="80%"
            buttons={{
                right: [
                    {
                        id: "accept", 
                        label: "Done", 
                        onClick: () => onHide(), 
                        width: 60
                    }
                ]
            }}
            renderContent={() => {
                return (
                    <div style={localStyles.content}>
                        <div style={localStyles.video}>
                            <ReactPlayer url={video} playing={true} controls={true}/>
                        </div>
                    </div>
                    
                );
            }}
            onHide={() => onHide()}
        />
    )
};