import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Select,
  Tag,
  TagLabel,
  TagRightIcon,
  Textarea,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdAdd, MdClose } from "react-icons/all";

export const ChipsVariant = {
  Select: "select",
  FreeText: "free-text",
};

const ChipsFormInput = ({
  label,
  helperText,
  options,
  selected,
  selectText,
  onAdd,
  onDelete,
  variant = ChipsVariant.Select,
  ...props
}) => {
  const handleFreeTextAdd = () => {
    const element = document.querySelector(".edit-driver__free-text");
    const value = element?.value;

    if (value) {
      onAdd(value);
      element.value = "";
    }
  };

  return (
    <FormControl {...props}>
      <FormLabel mb={2}>{label}</FormLabel>
      {helperText && <FormHelperText mb={1}>{helperText}</FormHelperText>}
      {variant === ChipsVariant.Select && (
        <Select
          mb={2}
          onChange={(e) => {
            onAdd(e.target.value);
            e.target.value = "";
          }}
        >
          <option value="">{selectText}</option>
          {options &&
            !!options.length &&
            options.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.title}
              </option>
            ))}
        </Select>
      )}
      {variant === ChipsVariant.FreeText && (
        <InputGroup mb={2}>
          <Textarea
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                handleFreeTextAdd();
              }
            }}
            className={"edit-driver__free-text"}
            pr={"50px"}
            placeholder={"Add Insight"}
          />
          <InputRightElement w={"50px"} justifyContent={"center"}>
            <Button h={"30px"} w={"40px"} p={0}>
              <MdAdd color={"black"} w={"30px"} onClick={handleFreeTextAdd} />
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
      <Wrap
        p={2}
        border={"1px solid"}
        borderColor={"border"}
        borderRadius={"md"}
        minHeight={"46px"}
        spacing={2}
      >
        {selected.map((item, i) => {
          return (
            <WrapItem key={item?.id || item?.title}>
              <Tag
                p={1}
                aria-label={item?.title}
                title={item?.title}
                fontWeight={"normal"}
                background={"primary"}
                color={"alwaysWhite"}
                fontSize={12}
                height={"auto"}
                _hover={{
                  background: "colorBackground",
                }}
              >
                <TagLabel maxW={"150px"}>{item?.title}</TagLabel>
                <TagRightIcon
                  fontSize={24}
                  onClick={() => {
                    onDelete(i);
                  }}
                >
                  <MdClose />
                </TagRightIcon>
              </Tag>
            </WrapItem>
          );
        })}
      </Wrap>
    </FormControl>
  );
};

ChipsFormInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selectText: PropTypes.string,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  variant: PropTypes.oneOf([ChipsVariant.Select, ChipsVariant.FreeText]),
  ...FormControl.propTypes,
};

export default ChipsFormInput;
