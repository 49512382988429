import React, { useContext, useState, useEffect } from "react";
import { Button, Heading, HStack, Switch } from "@chakra-ui/react";
import { useButtonStyles } from "../components/Button";
import HttpServices from "../services/HttpServices";
import { CardsContext } from "../contexts/CardsContext";
import BaseFormControl from "../components/BaseFormControl";
import CardsTable from "../components/discover/CardsTable";
import { useIsAdminMode } from "../services/UserCompanyServices";
import { getId } from "../helpers/helpers";
import { Select } from "chakra-react-select";
import CardEditModal from "../components/discover/CardEditModal";

const CardArchive = () => {
  const buttonStyles = useButtonStyles();
  const {
    search,
    searchQuery,
    productConfig,
    allDrivers,
    driverFilters,
    filterByDriver,
    allCategories,
    categoryFilters,
    filterByCategory,
    allTypes,
    typeFilters,
    getUrl,
    filterByType,
    filterByOrg,
    setChosenCardId,
    setCardEditActive,
    fetchCards,
    allOrg,
  } = useContext(CardsContext);
  const [toggleState, setToggleState] = useState(false);
  const isAdminMode = useIsAdminMode();
  const [isDisabled, setIsDisabled] = useState(false);
  const handleClick = async () => {
    const result = await HttpServices.post("/discover/cards/new");
    setChosenCardId(result.id);
    setCardEditActive(true);
  };

  useEffect(() => {
    setToggleState(productConfig);
  }, [productConfig]);

  const handleToggle = async (value) => {
    try {
      setIsDisabled(true);
      await HttpServices.post("/discover/product/config", {
        organisation_id: getId(false),
        is_product_on: value.toString(),
      });
      setIsDisabled(false);
      setToggleState(value);
      fetchCards(getUrl());
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <div>
      <Heading as={"h1"} mb={4}>
        Form Cards
      </Heading>
      <Button {...buttonStyles} onClick={handleClick} mb={4}>
        Create Card
      </Button>
      <HStack spacing={2} mb={4} maxW={"1200px"}>
        <BaseFormControl
          label={"Search Cards"}
          name={"search_cards"}
          value={searchQuery}
          onChange={(e) => search(e.target.value)}
          maxW={"300px"}
        />
        <BaseFormControl
          label={"Filter Drivers"}
          name={"filter_drivers"}
          value={driverFilters}
          maxW={"400px"}
          inputComponent={
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={allDrivers.map((d) => ({
                label: d.title,
                value: d.id,
              }))}
              onChange={(selected) => {
                filterByDriver(selected.map((d) => d.value));
              }}
            />
          }
        />
        <BaseFormControl
          label={"Filter Category"}
          name={"filter_category"}
          value={categoryFilters}
          maxW={"250px"}
          inputComponent={
            <Select
              options={[
                {
                  label: "All",
                  value: "all",
                },
                ...allCategories.map((d) => ({
                  label: d.title,
                  value: d.id,
                })),
              ]}
              onChange={(selected) => {
                if (selected.value === "all") {
                  filterByCategory([]);
                } else {
                  filterByCategory([selected.value]);
                }
              }}
            />
          }
        />
        <BaseFormControl
          label={"Filter Type"}
          name={"filter_type"}
          value={typeFilters}
          maxW={"250px"}
          inputComponent={
            <Select
              options={[
                {
                  label: "All",
                  value: "all",
                },
                ...allTypes.map((t) => ({
                  label: t,
                  value: t,
                })),
              ]}
              onChange={(selected) => {
                if (selected.value === "all") {
                  filterByType([]);
                } else {
                  filterByType([selected.value]);
                }
              }}
            />
          }
        />
        {isAdminMode && (
          <BaseFormControl
            label={"Card Type"}
            name={"card_type"}
            value={typeFilters}
            maxW={"250px"}
            inputComponent={
              <Select
                options={[
                  {
                    label: "All",
                    value: "all",
                  },
                  ...Object.keys(allOrg)?.map((t) => ({
                    label: t,
                    value: t,
                  })),
                ]}
                onChange={(selected) => {
                  if (selected.value === "all") {
                    filterByOrg([]);
                  } else {
                    filterByOrg([selected.value]);
                  }
                }}
              />
            }
          />
        )}
        {!isAdminMode && (
          <BaseFormControl
            label={"Disable Product Cards"}
            name={"disable_product_cards"}
            value={typeFilters}
            maxW={"250px"}
            inputComponent={
              <Switch
                id="email-alerts"
                size="lg"
                isDisabled={isDisabled}
                isChecked={toggleState}
                onChange={(e) => handleToggle(!(toggleState || false))}
              />
            }
          />
        )}
      </HStack>
      <CardsTable />
      <CardEditModal />
    </div>
  );
};

export default CardArchive;
