import React, {useEffect, useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import {
    useLocation
} from "react-router-dom";

import Button from "../components/Button";
import HttpServices from "../services/HttpServices";
import ProfilePic from "../components/ProfilePic";
import FormSpinner from "../components/FormSpinner";
import TextBox from "../components/TextBox";

export default() => {
    const theme = Themes[useColorScheme()];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [organisation, setOrganisation] = useState(null);
    const [email, setEmail] = useState("");
    const [subscribing, setSubscribing] = useState(false);
    const [subscribed, setSubscribed] = useState(false);

    const [shortId] = useState(query.get("sid"));
    const [id] = useState(query.get("id"));

    useEffect(() => {
        
        if (shortId || id) {
            loadOrgInfo()
        }
    }, [shortId, id]);

    const loadOrgInfo = async() => {
        var query = "?";

        if (shortId) {
            query = `?sid=${shortId}`;
        } else {
            query = `?id=${id}`;
        }

        try {
            const org = await HttpServices.get(`/organisation/emails/signup/info${query}`);

            setOrganisation(org);
            setLoading(false);

        } catch (e) {
            setError("Invalid organisation");
            setLoading(false);
        }
        
    
    };

    const localStyles = {
        container: {
            padding: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        errorHolder: {
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center"
        },
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        buttonsArea: {
            marginTop: 30
        },
        logos: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        domainsHolder: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        domainsList: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "500",
            opacity: 0.7,
            marginTop: 30,
            fontSize: 12
        },
        domains: {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "500",
            opacity: 0.7,
            fontSize: 12
        },
        and: {
            width: 50,
            fontSize: 30,
            alignItems: "center",
            textAlign: "center"
        },
        logo: {
            width: 150,
            height: 150
        },
        logoSmall: {
            width: 75,
            height: 75
        }
    };

    if (loading) {
        return (
            <FormSpinner/>
        );
    }

    if (error) {
        return (
            <div style={localStyles.errorHolder}>
                {error}
            </div>
        )
    }

    const renderLogos = () => {
        if (!organisation.profile_pic) {
            return (
                <img 
                    alt="Logo"
                    style={localStyles.logo}
                    src={theme.images.formLogo150}
                />
            );
        }

        return (
            <div style={localStyles.logos}>
                <img 
                    alt="Logo"
                    style={localStyles.logoSmall}
                    src={theme.images.formLogo150}
                />
                <div style={localStyles.and}>+</div>
                <ProfilePic
                    user={organisation}
                    size={50}
                />
            </div>
        )
    };

    const onSubscribe = async () => {
        setSubscribing(true);

        await HttpServices.post(`/organisation/emails/signup`, {
            id: organisation.id,
            email: email.toLowerCase()
        });

        setSubscribed(true);
    };

    const isValidEmail = () => {
        if (email.indexOf("@") < 0) {
            return false;
        }

        if (email.indexOf(".") < 0) {
            return false;
        }

        if (organisation.domains && organisation.domains.length > 0) {
            var found = false;

            for (const domain of organisation.domains) {
                if (email.toLowerCase().indexOf(domain) > 0) {
                    found = true;
                    break;
                } 
            }

            if (!found) {
                return false;
            }
        }

        return true;
    };

    const renderDomainsList = () => {
        if (!organisation.domains || organisation.domains.length === 0) {
            return null;
        }

        return (
            <div style={localStyles.domainsHolder}>
                <div style={localStyles.domainsList}>
                    Note: Only emails with the following endings can signup to this organisation:  
                </div>
                <div style={localStyles.domains}>
                    {organisation.domains.map((domain, index) => {
                        return (
                            <div key={domain}>{index > 0 ? "," : " "}&nbsp;@{domain} </div>
                        );
                    })}
                </div>
            </div>
            
        );
    };

    if (subscribed) {
        return (
            <div style={localStyles.container}>
                {renderLogos()}
                <h1>{organisation.name}</h1>
                <p>Thank you for subscribing. You will receive an email shortly.</p>
            </div>
        );
    }

    return (
        <div style={localStyles.container}>
            {renderLogos()}
            <h1>{organisation.name}</h1>
            <p>Enter your email below to signup to Form emails</p>
            <TextBox
                width={280}
                placeholder="Enter your email"
                value={email}
                onChange={(val) => setEmail(val)}
            />
            
            <div style={localStyles.buttonsArea}>
                <Button
                    label="Subscribe"
                    loading={subscribing}
                    disabled={!isValidEmail()}
                    onClick={() => onSubscribe()}
                />
            </div>
            {renderDomainsList()}
        </div>
    );
};