import React from "react";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import { Box, Button, Heading, HStack, Icon, Text } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { mutate } from "swr";
import { useFormik } from "formik";
import BaseFormControl from "./BaseFormControl";

const AppsManagement = () => {
  const { data: apps } = useApi("/apps", {
    defaultValue: [],
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      token: "",
    },
    onSubmit: async (values, helpers) => {
      if (!values.name || !values.token) {
        alert("Must fill out both fields");
        return;
      }

      const result = await HttpServices.post("/apps", values);

      helpers.setValues({
        name: "",
        token: "",
      });

      alert(
        `App saved. Secret is: ${result.secret}. SAVE THIS AS IT WILL NOT BE SHOWN AGAIN`
      );

      await mutate("/apps");
    },
  });

  const handleRemoveApp = async (app) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${app.name}?`
    );

    if (confirmed) {
      await HttpServices.delete(`/apps/${app.id}`);
      mutate("/apps");
    }
  };

  return (
    <div>
      <Box pb={4} borderBottom={"1px solid"} borderColor={"black"} mb={4}>
        <Heading variant={"h2"} mb={2}>
          Add new app
        </Heading>
        <form onSubmit={formik.handleSubmit}>
          <BaseFormControl
            name={"name"}
            onChange={formik.handleChange}
            value={formik.values.name}
            label={"App Name"}
            mb={2}
          />
          <BaseFormControl
            name={"token"}
            onChange={formik.handleChange}
            value={formik.values.token}
            label={"Unique App Token"}
            mb={2}
          />
          <Button type={"submit"}>Create</Button>
        </form>
      </Box>
      <Heading variant={"h2"} mb={2}>
        All apps
      </Heading>
      {apps.map((app) => {
        return (
          <HStack spacing={1} alignItems={"center"} key={app.id}>
            <Text>{app.name}</Text>
            <Icon as={MdDelete} onClick={() => handleRemoveApp(app)} />
          </HStack>
        );
      })}
    </div>
  );
};

AppsManagement.propTypes = {};

export default AppsManagement;
