import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import moment from "moment";
import LastXDay from "../components/LastXDay";

const DAYS_BACK = 100;
const DATE_FORMAT = "YYYY-MM-DD";
const BOX_WIDTH = 40;
const BOX_MARGIN = 20;

export default({companies}) => {
    const theme = Themes[useColorScheme()];
    const [report, setReport] = useState(null);
    
    const localStyles = {
        container: {
            display: "flex",
            maxWidth: 400,
            marginTop: 30,
          },
          week: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
            justifyContent: 'space-between',
          },
          day: {
            display: "flex",
            width: 32,
            height: 32,
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 2,
            borderColor: 'transparent',
          },
          dayName: {
            fontSize: 14,
            fontWeight: "700",
            textAlign: 'center',
            color: theme.colors.textOnBorder,
          },
          today: {
            //borderColor: theme.colors.black,
            // transform: [
            //     {scale: 1.2}
            // ]
          },
          dayTitle: {
            color: theme.colors.textOnPrimary,
            fontSize: 12,
            fontWeight: "700"
          },
          emptyDay: {
            backgroundColor: theme.colors.white,
          },
          graphContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            paddingBottom: 200,
            width: (BOX_WIDTH + BOX_MARGIN) * 7
          },
    };
    
    useEffect(() => {
        if (companies && companies.selected) {
            reloadReport();
        }
    }, [companies]);

    const reloadReport = async () => {
        var orgParam = `&organisation_id=${companies.selected.id}`;

        const loadedReport = await HttpServices.get(`/reporting/lastx?days_back=${DAYS_BACK}${orgParam}`);
        setReport(loadedReport);
    };

    const createWeeks = () => {
        var dayMap = {};
    
        report.forEach((day) => {
          dayMap[moment(day.date).format('YYYY-MM-DD')] = day;
        });
    
        var weeks = [];
    
        var startDate = moment().add(-DAYS_BACK, 'days');
    
        for (var i = 0; i <= DAYS_BACK; i++) {
          var dayOfWeek = startDate.weekday();
    
          if (weeks.length === 0 || dayOfWeek === 0) {
            weeks.push({
              0: null,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null,
              6: null,
            });
          }
    
          var dayObj = {
            date: startDate.format(DATE_FORMAT),
          };
    
          if (dayMap[startDate.format(DATE_FORMAT)]) {
            dayObj.score = dayMap[startDate.format(DATE_FORMAT)];
          }
    
          weeks[weeks.length - 1][dayOfWeek] = dayObj;
    
          // Add 1 to the day
          startDate = startDate.add(1, 'days');
        }
    
        return weeks;
    };

    const getOrderedWeekArray = (week) => {
        return [week[0], week[1], week[2], week[3], week[4], week[5], week[6]];
    };

    const renderContent = () => {
        if (!report) {
            return null;
        }

        return (
            <div style={localStyles.graphContainer}>
                <div style={localStyles.week}>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>S</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>M</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>T</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>W</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>T</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>F</div>
                    </div>
                    <div style={localStyles.day}>
                        <div style={localStyles.dayName}>S</div>
                    </div>
                </div>
                {createWeeks().map((week, index) => {
                    return (
                        <div key={index} style={localStyles.week}>
                            {getOrderedWeekArray(week).map((day, index) => {
                                return (
                                    <LastXDay
                                        key={index}
                                        index={index}
                                        day={day}
                                    />
                                )
                            })}
                        </div>
                    );
                })}
            </div>   
        );
    };

    return (
        <div>
            <h1 style={theme.styles.pageHeading}>Last {DAYS_BACK} Days</h1>
            {renderContent()}

        </div>
    );


};