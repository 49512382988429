import React, { createContext } from "react";
import useApi from "../hooks/useApi";
import { uniqBy } from "lodash";

export const OrganisationsContext = createContext([]);

export const OrganisationsProvider = ({ children }) => {
  const { data: organisations } = useApi("/organisation");

  return (
    <OrganisationsContext.Provider value={uniqBy(organisations, "id")}>
      {children}
    </OrganisationsContext.Provider>
  );
};
