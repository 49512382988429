import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({total, label, labelOverride}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            width: 110,
            backgroundColor: theme.colors.primary
        },
        total: {
            color: theme.colors.alwaysWhite,
            fontSize: 40,
            fontWeight: "700"
        },
        description: {
            color: theme.colors.alwaysWhite,
            fontSize: 16,
            opacity: 0.8,
            fontWeight: "500",
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
        }
    }

    var totalToUse = total;

    if (!total) {
        totalToUse = 0;
    }

    return (
        <div style={localStyles.container}>
            <div style={localStyles.total}>
                {totalToUse}
            </div>
            <div style={localStyles.description}>
                {labelOverride ? labelOverride : label} {labelOverride ? "" : totalToUse === 1 ? 'User' : "Users"}
            </div>
        </div>
    )

};