import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({on, onChange}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            display: "flex",
            userSelect: "none",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.colors.border,
            backgroundColor: theme.colors.white,
            borderRadius: 4,
            width: 20,
            height: 20,
            marginRight: 10,
            cursor: "pointer"
        },
        tick: {
            width: 10,
            height: 8
        }
    };
    
    const renderTick = () => {
        if (!on) {
            return null;
        }

        return (
            <img 
                alt="Tick"
                src={theme.images.checkboxTick}
                style={localStyles.tick}/>
        );
    };

    return (
        <div style={localStyles.container} onClick={onChange}>
            {renderTick()}
        </div>
    );
};