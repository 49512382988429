import React from "react";
import { Route, Switch } from "react-router-dom";
import CardArchive from "./CardArchive";
import { CardsProvider } from "../contexts/CardsContext";
import { CardProvider } from "../contexts/CardContext";
import { ReasonsProvider } from "../contexts/ReasonsContext";
import { DiscoverCategoriesProvider } from "../contexts/DiscoverCategoriesContext";
import CardEdit from "./CardEdit";

const OrgCards = () => {
  return (
    <Switch>
      <Route exact path={"/org-cards"}>
        <CardsProvider>
          <CardArchive />
        </CardsProvider>
      </Route>
      <Route exact path={"/org-cards/:id"}>
        <ReasonsProvider>
          <DiscoverCategoriesProvider>
            <CardProvider>
              <CardEdit />
            </CardProvider>
          </DiscoverCategoriesProvider>
        </ReasonsProvider>
      </Route>
    </Switch>
  );
};

OrgCards.propTypes = {};

export default OrgCards;
