import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import NewOrgModal from "../components/NewOrgModal";

const OrganisationsEdit = () => {
  const theme = Themes[useColorScheme()];

  return (
    <div>
      <h1 style={theme.styles.pageHeading}>Edit Organisations</h1>
      <NewOrgModal />
    </div>
  );
};

export default OrganisationsEdit;
