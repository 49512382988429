import React from "react";
import { range } from "d3-array";
import { select } from "d3-selection";
import { colorScale } from "./helpers";

export default class extends React.Component {

    componentDidUpdate() {
        this.renderLeaderboard(this.props.data);
    }

    emptyData() {
        const array = range(10);
        return array.map(d => ({ name: "", score: 0, change: 0 }))
    }

    renderLeaderboard(data) {
        const tableData = data.length === 0 ? this.emptyData() : data;
        select("#leaderboard")
            .selectAll("tr")
            .remove();
        select("#leaderboard")
            .selectAll("tr")
            .data(tableData)
            .enter()
            .append("tr")
            .html((d, i) => (`
                <td>${i + 1}</td>
                <td class="large">${d.name}</td>
                <td>
                    <div class="leaderboard__stat" style="background-color: ${colorScale(Math.floor(10.5 - ((9 / tableData.length) * i)))};">
                        <span>${d.score}</span>
                    </div>
                </td>
                <td>
                    <img class="triangle" src="${d.change === 0
                        ? "/images/square_blue.svg"
                        : d.change < 0
                            ? "/images/triangle_down_red.svg"
                            : "/images/triangle_up_green.svg"}"
                    /> ${d.change > 0 ? "+" : ""}${d.change}
                </td>
            `));
    }

    render() {
        return (
            <div className="leaderboard tile">
                <h2>Leaderboard</h2>
                <p style={{ fontSize: "smaller", fontStyle: "italic" }}>Average scores this week, compared to previous week</p>
                <div className="table">
                    <table>
                        <tbody id="leaderboard"></tbody>
                    </table>
                </div>
            </div>
        )
    }
}