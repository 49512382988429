import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { OrganisationsContext } from "../contexts/OrganisationsContext";
import SearchFormControl from "./SearchFormControl";
import TextBox from "./TextBox";
import { useButtonStyles } from "./Button";
import { GeneratedReportsContext } from "../contexts/GeneratedReportsContext";
import moment from "moment";

const GeneratedReportForm = ({ onSubmit = () => {} }) => {
  const buttonStyles = useButtonStyles();
  const organisations = useContext(OrganisationsContext);
  const { add } = useContext(GeneratedReportsContext);
  const formik = useFormik({
    initialValues: {
      name: "",
      organisation_id: "",
      from: "",
      to: "",
    },
    onSubmit: async (values) => {
      if (
        !values.name ||
        !values.organisation_id ||
        !values.from ||
        !values.to
      ) {
        alert(
          "Missing form values. Please complete the form to generate a new report"
        );
        return;
      }

      await add(values);
      onSubmit(values);
    },
  });
  const labelProps = {
    fontSize: "md",
    fontWeight: 500,
  };
  const inputProps = {
    borderRadius: "6px",
    borderColor: "rgb(226, 232, 240)",
    fontWeight: 500,
  };
  // * must plus 1 to move back an extra day from the "from" value and avoid duplicates
  const dayDiff =
    formik.values.from &&
    formik.values.to &&
    Math.abs(
      moment(formik.values.from).diff(moment(formik.values.to), "days")
    ) + 1;

  return (
    <form onSubmit={formik.handleSubmit}>
      <HStack spacing={2} mb={2} position={"relative"} zIndex={2}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            name={"name"}
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
          />
        </FormControl>
        <SearchFormControl
          getItemValue={(o) => o.name}
          getItemKey={(o) => o.id + o.name}
          afterItem={(o) => <Text fontSize={"10px"}>{o.id}</Text>}
          items={organisations}
          value={""}
          onSearch={(o, query) => {
            const rgx = new RegExp(query, "ig");

            return rgx.test(o.name);
          }}
          label={"Organisation"}
          onChange={(o) => formik.setFieldValue("organisation_id", o.id)}
          placeholder={"Search Organisations"}
        />
      </HStack>
      <HStack spacing={2}>
        <Box width={"100%"}>
          <TextBox
            label="From"
            labelProps={labelProps}
            inputProps={inputProps}
            type="date"
            value={formik.values.from}
            onChange={(val) => formik.setFieldValue("from", val)}
            width={"100%"}
          />
        </Box>
        <Box width={"100%"}>
          <TextBox
            label="To"
            labelProps={labelProps}
            inputProps={inputProps}
            type="date"
            value={formik.values.to}
            onChange={(val) => formik.setFieldValue("to", val)}
            width={"100%"}
          />
        </Box>
      </HStack>
      {dayDiff && (
        <Text textAlign={"center"} mt={2}>
          Report will use <strong>{dayDiff} days</strong> of score data
        </Text>
      )}
      <Flex justifyContent={"flex-end"} mt={2} position={"relative"} zIndex={1}>
        <Button type={"submit"} {...buttonStyles}>
          Create
        </Button>
      </Flex>
    </form>
  );
};

GeneratedReportForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default GeneratedReportForm;
