import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

const PILL_BOOSTING = "boosting";
const PILL_LOWERING = "lowering";

export default({currentPill, onChangePill, loweringCount, boostingCount}) => {
    const theme = Themes[useColorScheme()];

    const fontSize = window.innerWidth < 400 ? 14 : 16;

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row",
            marginBottom: 20,
            marginTop: 10,
            width: "100%",
            maxWidth: 500
        },
        boostingPill: {
            display: "flex",
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: currentPill === PILL_BOOSTING ? theme.colors.primary : "transparent",
            borderColor: currentPill === PILL_BOOSTING ? theme.colors.primary : theme.colors.border,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            cursor: "pointer",
            userSelect: "none",
            alignItems: "center",
            justifyContent: "center",
            width: "50%"
        },
        boostingPillLabel: {
            fontWeight: "500",
            fontSize: fontSize,
            color: currentPill === PILL_BOOSTING ? theme.colors.lightOnPrimary : theme.colors.black,
        },
        loweringPill: {
            display: "flex",
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: currentPill === PILL_LOWERING ? theme.colors.lowering : "transparent",
            borderColor: currentPill === PILL_LOWERING ? theme.colors.lowering : theme.colors.border,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            cursor: "pointer",
            userSelect: "none",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "50%"
        },
        loweringPillLabel: {
            fontWeight: "500",
            fontSize: fontSize,
            color: currentPill === PILL_LOWERING ? theme.colors.lightOnPrimary : theme.colors.black,
        },
        pillOn: {

        }
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.boostingPill} onClick={() => onChangePill(PILL_BOOSTING)}>
                <div style={localStyles.boostingPillLabel}>Boosting Form {boostingCount > 0 ? ` (${boostingCount})` : ``}</div>
            </div>
            <div style={localStyles.loweringPill} onClick={() => onChangePill(PILL_LOWERING)}>
                <div style={localStyles.loweringPillLabel}>
                    Lowering Form  {loweringCount > 0 ? `(${loweringCount})` : ``}
                </div>
            </div>
        </div>
    );

};