import { Icon, Image, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AddNewDriverModal from "../components/AddNewDriverModal";
import Button from "../components/Button";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import { useIsAdminMode } from "../services/UserCompanyServices";
import Themes from "../Themes";
import { getId } from "../helpers/helpers";
import { MdDelete } from "react-icons/md";
import SuccessToastDrivers from "../components/SuccessToastDrivers";

export default () => {
  const toast = useToast();
  const theme = Themes[useColorScheme()];
  const [slotCount, setSlotCount] = useState(5);
  const [disableButton, setDisableButton] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orgReasons, setOrgReasons] = useState(null);
  const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
  const [editing, setEditing] = useState(null);
  const isAdminMode = useIsAdminMode();
  const userId = getId(isAdminMode);

  const localStyles = {
    content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `1px solid ${theme.colors.borderContrast}`,
      width: 200,
      // padding: 10,
      marginBottom: 20,
      cursor: "pointer",
    },
    display: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: 10,
    },
    btn: {
      marginRight: 10,
      cursor: "pointer",
    },
    emoji: {
      marginRight: 5,
      width: 20,
      height: 20,
    },
  };

  useEffect(() => {
    reloadDrivers();
  }, [getId(isAdminMode)]);

  const reloadDrivers = async () => {
    setLoading(true);

    const loadedDrivers = await HttpServices.get(
      `/reasons/organisation?organisation_id=${userId}`
    );

    setOrgReasons(loadedDrivers);

    setSlotCount(5 - loadedDrivers.length);

    setDisableButton(loadedDrivers.length >= 5);

    setLoading(false);
  };

  const showAddModal = (driver) => {
    setEditing(null);
    setShowAddOrEditModal(true);
  };

  const showEditModal = (driver) => {
    setEditing(driver);
    setShowAddOrEditModal(true);
  };

  const deleteDriver = async (driverId) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this driver?"
    );

    if (confirmed) {
      await HttpServices.delete(`/reasons/organisation/delete`, {
        reason_id: driverId,
      });
    }

    reloadDrivers();

    return toast({
      duration: 3000,
      render: () => <SuccessToastDrivers>Driver Deleted</SuccessToastDrivers>,
    });
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    return (
      <div>
        {orgReasons.map((drivers) => {
          return (
            <div style={localStyles.display}>
              <div style={localStyles.content}>
                <div
                  style={localStyles.display}
                  onClick={() => showEditModal(drivers)}
                >
                  <Image
                    style={localStyles.emoji}
                    src={theme.images[drivers.emoji]}
                  />
                  <p>{drivers.title}</p>
                </div>

                <Icon
                  style={localStyles.btn}
                  as={MdDelete}
                  onClick={() => deleteDriver(drivers.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const hideModal = () => {
    setShowAddOrEditModal(false);
    reloadDrivers();
  };

  return (
    <div>
      <h1 style={theme.styles.pageHeading}>Drivers</h1>
      <Button
        label="Create Driver"
        width={120}
        onClick={() => showAddModal()}
        disabled={disableButton}
      />
      <p>Remaining driver slots {slotCount}/5</p>
      <AddNewDriverModal
        visible={showAddOrEditModal}
        editing={editing}
        onHide={() => hideModal()}
      />
      {renderContent()}
    </div>
  );
};
