import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import ProfilePic from "./ProfilePic";
import { logout } from "../services/UserServices";

export default ({ user }) => {
  const theme = Themes[useColorScheme()];

  const localStyles = {
    container: {
      borderTopColor: theme.colors.transparentWhite,
      borderTopWidth: 1,
      borderTopStyle: "solid",
      padding: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    textContainer: {
      marginLeft: 10,
    },
    name: {
      color: theme.colors.alwaysWhite,
      fontWeight: 700,
      fontSize: 14,
    },
    logoutButton: {
      color: theme.colors.alwaysWhite,
      cursor: "pointer",
      fontSize: 12,
    },
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div style={localStyles.container}>
      <ProfilePic user={user} />
      <div style={localStyles.textContainer}>
        <div style={localStyles.name}>
          {user.first_name} {user.last_name}
        </div>
        <div style={localStyles.logoutButton} onClick={handleLogout}>
          Logout
        </div>
      </div>
    </div>
  );
};
