import React, { useState } from "react";
import HttpServices from "../services/HttpServices";
import Button from "./Button";

export default({organisation, visible, onHide}) => {
    
    const [sending, setSending] = useState(false);

    const localStyles = {
        container: {
            marginTop: 30
        },
        buttons: {
            marginTop: 30
        }
    }

    const forceSend = async () => {
        setSending(true);
        await HttpServices.post(`/organisation/${organisation.id}/emails/send`);
        setSending(false);
        onHide(true);
    };

    if (!visible) {
        return null;
    }

    return (
        <div style={localStyles.container}>
            <h2>Force Send Emails</h2>
            <div>You can force send mails to all email addresses listed above. Warning - you should only do this on rare ocassions where you want to resend emails for the day.</div>
            <div style={localStyles.buttons}>
                <Button
                    label="Force Send"
                    width={100}
                    loading={sending}
                    onClick={() => forceSend()}
                />
            </div>
        </div>
    )

};