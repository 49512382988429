import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
} from "@chakra-ui/react";
import { IoCloseCircleSharp } from "react-icons/all";
import HttpServices from "../services/HttpServices";

export const CONTENT_TYPE_JPEG = "image/jpeg, image/jpg";
export const CONTENT_TYPE_PNG = "image/png";

const UploadFormControl = ({
  value,
  onChange,
  onUpload,
  label,
  fileProp,
  uploadUrl,
  uploadErrorMsg,
  previewWidth,
  noImageHeight,
  optimalDimensionsText,
  contentType = CONTENT_TYPE_JPEG,
  ...props
}) => {
  const uploadImage = async (file) => {
    if (!uploadUrl) {
      if (onChange) {
        onChange(file);
      }
      return;
    }

    if (onChange) {
      onChange(file);
    }

    const form = new FormData();
    form.append(fileProp, file);

    const result = await HttpServices.upload(uploadUrl, form).catch((e) => {
      console.error(e);
      alert(
        uploadErrorMsg ||
          "There was an error with the upload. Check your internet connection and try again."
      );
      return false;
    });

    if (result) {
      onChange(result);
    }
  };

  return (
    <FormControl {...props}>
      <FormLabel>{label || "Image"}</FormLabel>
      <Grid
        gridGap={4}
        alignItems={"center"}
        gridTemplateColumns={`${previewWidth} 1fr`}
      >
        {!value && (
          <Box
            maxWidth={previewWidth}
            height={noImageHeight}
            background={"borderContrast"}
          />
        )}
        {value && (
          <Box
            width={previewWidth}
            height={"auto"}
            position={"relative"}
            _hover={{
              ".image__overlay": {
                background: "rgba(0, 0, 0, 0.5)",
              },
              ".image__remove": {
                opacity: 1,
              },
            }}
          >
            <Image src={value} width={"100%"} />
            <Box
              width={"100%"}
              height={"100%"}
              background={"transparent"}
              position={"absolute"}
              top={0}
              left={0}
              transition={"0.25s ease-in"}
              className={"image__overlay"}
            />
            <Icon
              as={IoCloseCircleSharp}
              position={"absolute"}
              top={3}
              right={3}
              opacity={0}
              color={"alwaysWhite"}
              fontSize={"30px"}
              transition={"0.25s ease-in"}
              className={"image__remove"}
              onClick={() => {
                return onChange("");
              }}
              cursor={"pointer"}
            />
          </Box>
        )}
        <div>
          {optimalDimensionsText && (
            <FormHelperText mb={2}>
              Optimal image dimensions: {optimalDimensionsText}
            </FormHelperText>
          )}
          <Input
            type={"file"}
            p={4}
            height={"auto"}
            accept={contentType}
            onChange={async (e) => {
              uploadImage(e.target.files[0]);
            }}
          />
        </div>
      </Grid>
    </FormControl>
  );
};

UploadFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  label: PropTypes.string,
  fileProp: PropTypes.string,
  uploadUrl: PropTypes.string,
  uploadErrorMsg: PropTypes.string,
  previewWidth: PropTypes.string,
  noImageHeight: PropTypes.string,
  contentType: PropTypes.string,
  ...Box.propTypes,
};

export default UploadFormControl;
