import useSWR from "swr";
import HttpServices from "../services/HttpServices";

export default function useApi(url, { defaultValue } = {}) {
  const { data, ...rest } = useSWR(
    url,
    (_url) => _url && HttpServices.get(_url),
    {
      revalidateOnFocus: false,
    }
  );
  return {
    data: data || defaultValue,
    ...rest,
  };
}
