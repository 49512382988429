import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({dateFrom, dateTo, onChange, showVersus}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            position: "relative",
            display: "flex",
            width: showVersus ? 300 : 200,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "space-between",
            padding: 10,
            paddingLeft: 5,
            paddingRight: 5,
            borderColor: theme.colors.border,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 12,
            fontWeight: "500",
            userSelect: "none",
        },
        titles: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        versus: {
            marginLeft: 10,
            marginRight: 10,
            opacity: 0.5
        },
        arrow: {
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
        },
        arrowRight: {
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer"
        },
        icon: {
            width: 12,
            height: 19
        }
    }

    const renderVersus = () => {
        if (!showVersus) {
            return (
                <div style={localStyles.titles}>
                    <div style={localStyles.monthName}>{dateTo.format("MMM YYYY")}</div>  
                </div>
            );
        }

        return (
            <div style={localStyles.titles}>
                <div style={localStyles.monthName}>{dateTo.format("MMM YYYY")}</div>
                <div style={localStyles.versus}>vs</div> 
                <div style={localStyles.monthName}>{dateFrom.format("MMM YYYY")}</div>     
            </div>
        );
    }

    return (
        <div style={localStyles.container}>
            <div className="opacityHover" style={localStyles.arrow} onClick={() => onChange(-1)}>
                <img 
                    alt="Left"
                    className="opacityHover"
                    src={theme.images.leftArrow}
                    style={localStyles.icon}/>
            </div>
            {renderVersus()}
            <div className="opacityHover" style={localStyles.arrowRight} onClick={() => onChange(1)}>
                <img 
                    alt="Right"
                    className="opacityHover"
                    src={theme.images.rightArrow}
                    style={localStyles.icon}/>
            </div>
        </div>
    )
};