import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import moment from "moment";
import MonthVersusSelector from "../components/MonthVersusSelector";
import DriversReport from "../components/DriversReport";

export default ({ companies }) => {
  const theme = Themes[useColorScheme()];
  const [dateFrom, setDateFrom] = useState(
    moment().startOf("month").add(-1, "months")
  );
  const [dateTo, setDateTo] = useState(moment().startOf("month"));
  const [report, setReport] = useState(null);

  useEffect(() => {
    reloadReport();
  }, [dateFrom, dateTo, companies]);

  const reloadReport = async () => {
    var orgParam = "";

    if (companies && companies.selected) {
      orgParam = `&organisation_id=${companies.selected.id}`;
    }

    const loadedReport = await HttpServices.get(
      `/reporting/drivers?month_from=${dateFrom.month()}&year_from=${dateFrom.year()}&month_to=${dateTo.month()}&year_to=${dateTo.year()}${orgParam}`
    );
    const mergedArray = [];
    loadedReport.map((data) => {
      mergedArray.push(...data.data);
    });
    setReport(mergedArray);
  };

  const onChangeDates = (direction) => {
    var newFrom = moment(dateFrom).add(direction, "months");
    var newTo = moment(dateTo).add(direction, "months");

    setDateFrom(newFrom);
    setDateTo(newTo);
  };

  return (
    <div>
      <h1 style={theme.styles.pageHeading}>Drivers</h1>

      <MonthVersusSelector
        showVersus={false}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onChange={(direction) => onChangeDates(direction)}
      />

      <DriversReport
        report={report}
        from={dateFrom.format("YYYY-MM")}
        to={dateTo.format("YYYY-MM")}
        isAdmin={!companies || !companies.selected}
      />
    </div>
  );
};
