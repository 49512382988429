import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";

function EditUserName({ user, onSubmit, ...props }) {
  const [editing, setEditing] = useState(false);
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");

  return (
    <Box
      _hover={{
        ".edit-user-name__control": {
          display: "block",
        },
      }}
      position={"relative"}
      pr={editing ? "85px" : "40px"}
      {...props}
    >
      <Flex alignItems={"center"}>
        {!editing ? (
          <Text mr={2} minHeight={"21px"} minWidth={"70px"}>
            {firstName + " " + lastName}
          </Text>
        ) : (
          <HStack mr={2}>
            <Input
              fontSize={"14px"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              fontSize={"14px"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </HStack>
        )}
        <Box position={"absolute"} right={"10px"}>
          {" "}
          {editing ? (
            <ButtonGroup justifyContent="center" size="sm">
              <IconButton
                icon={<Icon as={IoCheckmark} />}
                onClick={async () => {
                  if (onSubmit) {
                    await onSubmit({
                      first_name: firstName,
                      last_name: lastName,
                    });
                  }

                  setEditing(false);
                }}
              />
              <IconButton
                icon={<Icon as={IoClose} />}
                onClick={() => {
                  setFirstName(user.first_name || "");
                  setLastName(user.last_name || "");
                  setEditing(false);
                }}
              />
            </ButtonGroup>
          ) : (
            <Flex
              justifyContent="center"
              className={"edit-user-name__control"}
              display={"none"}
            >
              <IconButton
                size="sm"
                icon={<Icon as={AiOutlineEdit} />}
                onClick={() => setEditing(true)}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default EditUserName;
