import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useButtonStyles } from "./Button";
import BaseFormControl from "./BaseFormControl";
import { OrganisationsContext } from "../contexts/OrganisationsContext";
import SearchFormControl from "./SearchFormControl";
import { EditOrganisationsContext } from "../contexts/EditOrganisationsContext";

const NewOrgModal = (props) => {
  const organisations = useContext(OrganisationsContext);
  const { createOrganisation, startOrganisationPollsTrial } = useContext(
    EditOrganisationsContext
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const buttonStyles = useButtonStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      parentId: "",
      startPollTrial: false,
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }
    },
    onSubmit: async (values) => {
      setLoading(true);
      const newOrg = await createOrganisation(values.name, values.parentId);

      if (newOrg && values.startPollTrial) {
        await startOrganisationPollsTrial(newOrg.id);
      }

      setLoading(false);
      onClose();
      alert(
        `New Org ${values.name} successfully created. You should now be able to find it from the Organisation Selector Modal.`
      );
      window.location.reload();
    },
  });

  return (
    <div>
      <Button {...buttonStyles} onClick={onOpen}>
        Add new Organisation
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a new Organisation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <VStack mb={5}>
                <BaseFormControl
                  label={"Org Name"}
                  name={"name"}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.name}
                />
                <SearchFormControl
                  getItemValue={(o) => o.name}
                  getItemKey={(o) => o.id + o.name}
                  afterItem={(o) => <Text fontSize={"10px"}>{o.id}</Text>}
                  items={organisations}
                  value={""}
                  onSearch={(o, query) => {
                    const rgx = new RegExp(query, "ig");

                    return rgx.test(o.name);
                  }}
                  label={"Parent Organisation"}
                  onChange={(o) => formik.setFieldValue("parentId", o.id)}
                  placeholder={"Search Organisations"}
                />
                <BaseFormControl
                  label={"Start Checkin Tool Trail"}
                  name={"active"}
                  inputComponent={
                    <Checkbox
                      isChecked={formik.values.startPollTrial}
                      onChange={() => {
                        formik.setFieldValue(
                          "startPollTrial",
                          !formik.values.startPollTrial
                        );
                      }}
                    />
                  }
                />
              </VStack>
              <Flex
                position={"relative"}
                zIndex={1}
                justifyContent={"flex-end"}
              >
                <Button
                  {...buttonStyles}
                  isDisabled={!formik.values.name}
                  isLoading={loading}
                  type={"submit"}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

NewOrgModal.propTypes = {};

export default NewOrgModal;
