import React, { useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import Checkbox from "./Checkbox";
import Modal from "./Modal";

export default({user, onHide}) => {

    const [loading, setLoading] = useState(true);
    const [flags, setFlags] = useState([]);

    const localStyles = {
        content: {
            padding: 30
        },
        flag: {
            marginBottom: 5,
            display: "flex",
            flexDirection: "row"
        }
    };

    useEffect(() => {
        if (user) {
            reloadFlags();
        }
    }, [user]);

    const reloadFlags = async() => {
        setFlags([]);
        setLoading(true);
        const loadedFlags = await HttpServices.get(`/feature/user/${user.id}`);
        setFlags(loadedFlags);
        setLoading(false);
    };

    const toggleFlag = async (flag) => {
        // Send the actual update request
        if (flag.on) {
            // We are turning off
            HttpServices.delete(`/feature/remove`, {
                user_id: user.id,
                feature_id: flag.id
            });
        } else {
            HttpServices.post(`/feature/apply`, {
                user_id: user.id,
                feature_id: flag.id
            });
        }

        var updatedFlags = [].concat(flags);
        
        for (var i = 0; i < updatedFlags.length; i++) {
            if (updatedFlags[i].id === flag.id) {
                updatedFlags[i].on = !updatedFlags[i].on;
                break;
            }
        }

        setFlags(updatedFlags);

        

    };

    const renderContent = () => {
        if (loading) {
            return null;
        }

        return (
            <div style={localStyles.content}>
                {flags.map((flag) => {
                    return (
                        <div style={localStyles.flag} key={flag.id}>
                            <Checkbox
                                on={flag.on}
                                onChange={() => toggleFlag(flag)}
                            />
                            {flag.id}
                        </div>
                    )
                })}
            </div>
        );

    };

    return (
        <Modal
            title={user ? user.first_name : "Feature Flags"}
            visible={user !== null}
            height="60%"
            buttons={{
                right: [
                    {
                        id: "done", 
                        label: "Done", 
                        onClick: () => onHide(), 
                        width: 60
                    }
                ]
            }}
            onHide={() => onHide()}
            renderContent={() => renderContent()}
        />
    );
};