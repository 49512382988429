import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes, { convertComponentStyles } from "../Themes";
import { Box, Input, InputGroup } from "@chakra-ui/react";

const ENTER = 13;

export default ({
  label,
  labelProps = {},
  type,
  width,
  value,
  placeholder,
  onChange,
  onEnter,
  alwaysBlack,
  leftAddon = () => {},
  inputProps = {},
}) => {
  const theme = Themes[useColorScheme()];

  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <Box
        className={alwaysBlack ? "alwaysBlack" : null}
        fontSize={"12px"}
        fontWeight={700}
        mb={"5px"}
        {...labelProps}
      >
        {label}
      </Box>
    );
  };

  const onKeyDown = (evt) => {
    if (!onEnter || value === "") {
      return;
    }

    if (evt.keyCode === ENTER) {
      onEnter();
    }
  };

  let textBoxStyles = theme.styles.textBox;

  if (width) {
    textBoxStyles = Object.assign({ width: width }, textBoxStyles);
  }

  textBoxStyles = convertComponentStyles(textBoxStyles);

  return (
    <div>
      {renderLabel()}
      <InputGroup spacing={0}>
        {leftAddon && leftAddon()}
        <Input
          as={"input"}
          type={type || "text"}
          placeholder={placeholder}
          value={value}
          onKeyDown={(evt) => onKeyDown(evt)}
          onChange={(evt) => onChange(evt.target.value)}
          {...textBoxStyles}
          {...inputProps}
        />
      </InputGroup>
    </div>
  );
};
