import React, { useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import Checkbox from "./Checkbox";

import Modal from "./Modal";
import TextBox from "./TextBox";

const TYPES = {
    link: {id: "link", title: "Link", additionalField: {id: "url", title: "URL"}},
    email: {id: "email", title: "Email", additionalField: {id: "email", title: "Email Address"}},
    video: {id: "video", title: "Video", additionalField: {id: "url", title: "URL"}},
    reachout: {id: "reachout", title: "Reach Out", additionalField: {id: "email", title: "Email of person who will receive notification"}}
}
    

export default({visible, editing, onHide, organisationId}) => {
    
    const [saving, setSaving] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("link");
    const [config, setConfig] = useState({});

    const clear = () => {
        setTitle(null);
        setDescription(null);
        setType("link");
        setConfig({});
    };

    useEffect(() => {

        if (editing) {
            setTitle(editing.title);
            setDescription(editing.description);
            setType(editing.type);
            setConfig(editing.config);
        } else {
            clear();
        }

    }, [editing]);


    const localStyles = {
        content: {
            padding: 30
        },
        fieldHolder: {
            marginBottom: 20
        },
        checkboxes: {
            marginTop: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },  
        checkBoxHolder: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 20,
            marginBottom: 20
        },
        checkBoxTitle: {
            fontWeight: "700",
            marginLeft: 2
        }
    }

    const setConfigField = (id, val) => {
        var updatedConfig = Object.assign({}, config);
        updatedConfig[id] = val;
        setConfig(updatedConfig);
    }

    const renderAdditionalField = () => {
        if (!TYPES[type].additionalField) {
            return null;
        }

        const additionalField = TYPES[type].additionalField;

        return (
            <div style={localStyles.fieldHolder}>
                <TextBox
                    label={additionalField.title}
                    value={config[additionalField.id] || ""}
                    width={400}
                    onChange={(val) => setConfigField(additionalField.id, val)}
                />
            </div>
        )
    };

    const renderContent = () => {
        return (
            <div style={localStyles.content}>
                <div style={localStyles.fieldHolder}>
                    <TextBox
                        label="Title"
                        value={title}
                        width={400}
                        onChange={(val) => setTitle(val)}
                    />
                </div>
                <div style={localStyles.fieldHolder}>
                    <TextBox
                        label="Description"
                        value={description}
                        width={400}
                        onChange={(val) => setDescription(val)}
                    />
                </div>
                <div style={localStyles.fieldHolder}>
                    <div style={localStyles.checkboxes}>
                        {Object.keys(TYPES).map((key) => {
                            const availableType = TYPES[key];
                            return (
                                <div style={localStyles.checkBoxHolder}>
                                    <Checkbox
                                        on={availableType.id === type}
                                        onChange={() => setType(availableType.id)}
                                    />
                                    <div style={localStyles.checkBoxTitle}>
                                        {availableType.title}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {renderAdditionalField()}
                
            </div>
        );
    };

    const hide = () => {
        clear();
        onHide();
    };

    const canSave = () => {
        if (!title || title.length === 0 || !description || description.length === 0) {
            return false;
        }

        if (TYPES[type].additionalField) {
            const additionalField = TYPES[type].additionalField;
            // Check if additional field value set
            if (!config[additionalField.id] || config[additionalField.id].length === 0) {
                return false;
            }
        }
        
        return true;
    };
    
    const save = async () => {
        var body = {
            title: title,
            description: description,
            config: config,
            type: type,
            organisation_id: organisationId
        };
        
        if (editing) {
            // We are patching
            setSaving(true);
            await HttpServices.patch(`/score/low/resources/${editing.id}`, body);
            hide();
            setSaving(false);
        } else {
            // We are creating afresh
            setSaving(true);
            await HttpServices.post(`/score/low/resources`, body);
            hide();
            setSaving(false);
        }


    };
    
    return (
        <Modal
            title={`${editing ? "Edit" : "Add"} Low Score Resource`}
            visible={visible}
            height="80%"
            buttons={{
                left: [
                    {id: "cancel", label: "Cancel", onClick: () => hide(), width: 60}
                ],
                right: [
                    {
                        id: "save", 
                        label: "Save", 
                        onClick: () => save(), 
                        width: 60, 
                        loading: saving,
                        disabled: !canSave()
                    }
                ]
            }}
            onHide={() => hide()}
            renderContent={() => renderContent()}
        />
    )

};