import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import ProfilePic from "./ProfilePic";
import Button from "./Button";
import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import AddUsersViaEmailModal from "./AddUsersViaEmailModal";
import EditUserName from "./EditUserName";
import AddUserEmailsViaCsv from "./AddUserEmailsViaCsv";

function OrganisationUsers({ organisation }) {
  const theme = Themes[useColorScheme()];
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [makingAdmin, setMakingAdmin] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [showAddBulkEmailModal, setShowAddBulkEmailModal] = useState(false);

  const localStyles = {
    container: {
      marginTop: 30,
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    clickable: {
      marginLeft: 5,
      fontWeight: "500",
      color: theme.colors.primary,
    },
    userDetails: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    userName: {
      marginLeft: 10,
    },
  };

  useEffect(() => {
    reloadUsers();
  }, [organisation]);

  const reloadUsers = async () => {
    const loadedUsers = await HttpServices.get(
      `/organisation/${organisation.id}/user`
    );
    setUsers(loadedUsers);
    setLoading(false);
  };

  const onDeleteUser = async (user) => {
    setDeletingUser(user);

    await HttpServices.delete(
      `/organisation/${organisation.id}/user/${user.id}`,
      {}
    );

    await reloadUsers();
    setDeletingUser(false);
  };

  const onToggleAdmin = async (user) => {
    setMakingAdmin(user);

    if (user.admin) {
      await HttpServices.delete(`/organisation/${organisation.id}/user/admin`, {
        user_id: user.id,
      });
    } else {
      await HttpServices.post(`/organisation/${organisation.id}/user/admin`, {
        user_id: user.id,
      });
    }

    await reloadUsers();
    setMakingAdmin(false);
  };

  const onUpdateUser = async (userId, updates) => {
    await HttpServices.post(`/users/${userId}`, {
      user_to_update: updates,
    });
  };

  const onUpdateUsers = async (users) => {
    setLoading(true);
    try {
      const result = await HttpServices.post(
        `/organisation/${organisation.id}/members/addviajson`,
        {
          users,
        }
      );
      alert("Upload succeeded. Results: " + JSON.stringify(result, null, 4));
      await reloadUsers();
    } catch (e) {
      alert("Error uploading users: ", JSON.stringify(e, null, 4));
    }
    setLoading(false);
  };

  const renderEmails = (emails) => {
    if (!emails || emails.length === 0) {
      return null;
    }

    return (
      <div>
        {emails.split(",").map((email) => {
          return <div key={email}>{email}</div>;
        })}
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile</th>
            <th>Emails</th>
            <th>Admin</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  <div style={localStyles.userDetails}>
                    <ProfilePic user={user} />
                    <EditUserName
                      user={user}
                      ml={2}
                      onSubmit={(newUser) => onUpdateUser(user.id, newUser)}
                    />
                  </div>
                </td>
                <td>{user.phone_number}</td>
                <td>{renderEmails(user.emails)}</td>
                <td>
                  <div style={localStyles.row}>{user.admin ? "Yes" : "No"}</div>
                </td>
                <td>
                  <Flex
                    flexDirection="row"
                    width={300}
                    justifyContent="space-between"
                  >
                    <Button
                      label={user.admin ? "Remove Admin" : "Make Admin"}
                      loading={makingAdmin && makingAdmin.id === user.id}
                      dangerous={user.admin}
                      width={160}
                      onClick={() => onToggleAdmin(user)}
                    />
                    <Button
                      label="Delete"
                      marginRight={20}
                      dangerous={true}
                      confirm={true}
                      confirmText="Remove user"
                      width={120}
                      loading={deletingUser && deletingUser.id === user.id}
                      onClick={() => onDeleteUser(user)}
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Box mt={6}>
      <Heading variant="h2" mb={2}>
        Users {users.length > 0 ? ` (${users.length})` : ""}
      </Heading>

      <HStack marginTop="30px" marginBottom="30px">
        <Button
          label="Add users"
          width={160}
          onClick={() => setShowAddBulkEmailModal(true)}
        />
        <AddUserEmailsViaCsv
          onSubmit={(users) => {
            onUpdateUsers(users);
          }}
        />
      </HStack>

      {renderContent()}
      <p>
        Note: this will only show users who have signed up through the app. Use
        the emails page to find email only users.
      </p>
      <AddUsersViaEmailModal
        organisation={organisation}
        visible={showAddBulkEmailModal}
        onRefresh={() => reloadUsers()}
        onHide={() => setShowAddBulkEmailModal(false)}
      />
    </Box>
  );
}

export default OrganisationUsers;
