import React, { Fragment, useContext } from "react";
import { useWindowSize } from "react-use";
import { animated, useSpring } from "@react-spring/web";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import { PollScoresContext } from "../contexts/PollScoresContext";
import { chain } from "lodash";
import { Center } from "@chakra-ui/react";
import AggregatedPollScores from "./AggregatedPollScores";

export const PollScoreVariant = {
  AllScores: "all-scores",
  ScoreAmounts: "score-amounts",
};

function PollScores({ variant = PollScoreVariant.AllScores }) {
  const theme = Themes[useColorScheme()];
  const scores = useContext(PollScoresContext);
  const { width, height } = useWindowSize();
  // subtract numbers are to do with other elements on the page, header, footer, etc
  const widthToSubtract = 150 - 180 - 120;
  const heightToSubtract = 194 + 224;

  

  const textAnimation = useSpring({
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  });

  if (!Array.isArray(scores)) {
    return (
      <AggregatedPollScores
        variant={variant}
        scores={scores}
        width={width}
        height={height}
        widthToSubtract={widthToSubtract}
        heightToSubtract={heightToSubtract}
      />
    );
  }

  const formatters = {
    [PollScoreVariant.AllScores]: () =>
      scores.map((s) => ({
        id: s.score + "-" + s.id,
        label: s.score,
        color: theme.colors.form[s.score],
        value: 1,
      })),
    [PollScoreVariant.ScoreAmounts]: () =>
      chain(scores)
        .reduce((acc, s) => {
          if (!acc[s.score]) {
            acc[s.score] = {
              id: s.score,
              label: s.score,
              color: theme.colors.form[s.score],
              value: 0,
            };
          }

          acc[s.score].value += 1;

          return acc;
        }, {})
        .values()
        .value(),
  };

  const chartData = {
    id: "poll-scores",
    value: 0,
    children: formatters[variant](),
  };

  if (!scores || !scores.length) {
    return null;
  }

  return (
    <Center
      className={"bs"}
      width={width - widthToSubtract}
      height={height - heightToSubtract}
      mx={"auto"}
      position={"fixed"}
      left={"50%"}
      top={"50%"}
      transform={"translate(-50%, -50%)"}
    >
      <ResponsiveCirclePacking
        data={chartData}
        id={"id"}
        value={"value"}
        colorBy={"id"}
        colors={({ data }) => {
          return data.color;
        }}
        isInteractive={false}
        leavesOnly
        padding={15}
        enableLabels
        label={(props) => props.data.label}
        labelComponent={({ node, style, label }) => {
          return (
            <Fragment>
              <animated.text
                textAnchor={"middle"}
                dominantBaseline={"central"}
                fill={theme.colors.alwaysWhite}
                style={{
                  fontSize: Math.max(14, node.radius / 2),
                  fontFamily: "Fugue Regular",
                  ...style,
                  ...textAnimation,
                }}
              >
                {(variant === PollScoreVariant.ScoreAmounts &&
                  `#${node.data.value}`) ||
                  label}
              </animated.text>
            </Fragment>
          );
        }}
      />
    </Center>
  );
}

export default PollScores;
