import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import CreateGeneratedReport from "./CreateGeneratedReport";
import GeneratedReportsTable from "./GeneratedReportsTable";

const GeneratedReports = (props) => {
  return (
    <Box {...props}>
      <Heading mb={3}>Generated Reports</Heading>
      <CreateGeneratedReport />
      <GeneratedReportsTable />
    </Box>
  );
};

GeneratedReports.propTypes = {
  ...Box.propTypes,
};

export default GeneratedReports;
