import React, {useState} from "react";
import Modal from "./Modal";
import TextBox from "./TextBox";

export default({visible, onHide, onAdd}) => {
    const [domain, setDomain] = useState("");

    const localStyles = {
        content: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            width: "100%"
        }
    }

    const renderContent = () => {
        return (
            <div style={localStyles.content}>
                <TextBox
                    label="Domain"
                    placeholder="Add the part after @"
                    value={domain}
                    width={300}
                    onChange={(val) => setDomain(val.toLowerCase())}
                />
            </div>
        );
    }

    const hide = () => {
        setDomain("");
        onHide();
    }

    const addDomain = async() => {
        onAdd(domain);
        hide();
    };

    return (
        <Modal
            title="Add a domain"
            visible={visible}
            height="40%"
            buttons={{
                left: [
                    {id: "cancel", label: "Cancel", onClick: () => hide(), width: 60}
                ],
                right: [
                    {
                        id: "save", 
                        label: "Save", 
                        onClick: () => addDomain(), 
                        width: 60, 
                        disabled: domain.length < 3 || domain.indexOf("@") > 0
                    }
                ]
            }}
            onHide={() => hide()}
            renderContent={() => renderContent()}
        />
    );


};