import { createContext } from "react";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";
import { getId } from "../helpers/helpers";

export const DriversEditContext = createContext({
  drivers: [],
  driverTypes: [],
  categories: [],
  add: () => {},
  update: () => {},
  remove: () => {},
});

export const DriversEditProvider = ({ children }) => {
  const orgId = getId();
  const driversUrl = `/drivers${orgId ? `?organisation_id=${orgId}` : ""}`;
  const { data: drivers } = useApi(driversUrl, {
    defaultValue: [],
  });
  const driversToUse = orgId
    ? drivers.filter((d) => d.organisation_id)
    : drivers;
  const { data: driverTypes } = useApi("/drivers/types", {
    defaultValue: [],
  });
  const { data: categories } = useApi("/form-categories", {
    defaultValue: [],
  });

  const handleIconUpload = async (driverId, iconFile) => {
    const form = new FormData();
    form.append("file", iconFile);

    await HttpServices.upload(`/drivers/${driverId}/icon`, form);
  };

  const handleAddDriver = async (newDriver) => {
    const { emojiFile, ...driver } = newDriver;

    const result = await HttpServices.post("/drivers", {
      driver: {
        ...driver,
        organisation_id: orgId,
        is_default: !orgId,
      },
    });

    if (emojiFile) {
      await handleIconUpload(result.id, emojiFile);
    }

    await mutate(driversUrl);
  };

  const handleUpdateDriver = async (newDriver) => {
    const { emojiFile, ...driver } = newDriver;

    await HttpServices.patch(`/drivers/${newDriver.id}`, {
      driver,
    });

    if (emojiFile) {
      await handleIconUpload(newDriver.id, emojiFile);
    }

    await mutate(driversUrl);
  };

  const handleRemoveDriver = async (driverId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this driver and all associated data?"
    );

    if (confirmed) {
      await HttpServices.delete(`/drivers/${driverId}`);

      await mutate(driversUrl);
    }
  };

  return (
    <DriversEditContext.Provider
      value={{
        drivers: driversToUse,
        driverTypes,
        categories,
        add: handleAddDriver,
        update: handleUpdateDriver,
        remove: handleRemoveDriver,
      }}
    >
      {children}
    </DriversEditContext.Provider>
  );
};
