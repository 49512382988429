import React, { useEffect, useState } from "react";
import TotalUserCount from "../components/TotalUserCount";
import WeeklyActiveUsers from "../components/WeeklyActiveUsers";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";

export default({companies}) => {
    const theme = Themes[useColorScheme()];
    
    const [loading, setLoading] = useState(true);
    const [analytics, setAnalytics] = useState(null);

    useEffect(() => {
        reloadAnalytics();
    }, [companies]);

    const localStyles = {
        headlineStats: {
            display: "flex",
            flexDirection: "row",
            width: 320,
            justifyContent: "space-between"
        }
    }

    const reloadAnalytics = async() => {
        if (companies.selected) {
            const reloadedAnalytics = await HttpServices.get(`/organisation/${companies.selected.id}/analytics`);
            setAnalytics(reloadedAnalytics);
            setLoading(false);
        }
    };

    
    const renderContent = () => {
        if (loading) {
            return null;
        }

        return (
            <div>
                <div style={localStyles.headlineStats}>
                    <TotalUserCount
                        label="Total"
                        total={analytics.total_users}
                    />
                    <TotalUserCount
                        label="Active"
                        total={analytics.total_users_who_have_scored}
                    />
                </div>
                
                <h2>Weekly Active Users</h2>
                <p>Number of weekly active users shown by week commencing dates</p>
                <WeeklyActiveUsers
                    weeklyActiveUsers={analytics.weekly_active_users}
                />
            </div>
            
        );
    };
    
    return (
        <div>
            <h1 style={theme.styles.pageHeading}>Analytics</h1>
            {renderContent()}
        </div>
    );


};