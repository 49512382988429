import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import ProfilePic from "./ProfilePic";

export default({results, onToggleActive, onGoToUser}) => {
    const theme = Themes[useColorScheme()];
    
    const localStyles = {
        container: {
            marginTop: 30
        },
        nameAndPic: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        activeToggle: {
            color: theme.colors.primary,
            fontWeight: "500",
            cursor: "pointer"
        },
        activeToggleRed: {
            color: theme.colors.red,
            fontWeight: "500",
            cursor: "pointer"
        },
        name: {
            marginLeft: 10,
            color: theme.colors.primary,
            fontWeight: "500",
            cursor: "pointer"
        }
    }

    if (!results) {
        return null;
    }

    if (results.length === 0) {
        return (
            <div style={localStyles.container}>
                <p>No matches found</p>
            </div>
        );
    }

    return (
        <div style={localStyles.container}>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Active</th>
                        <th>Verified</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result) => {
                        return (
                            <tr key={result.id}>
                                <td>
                                    <div style={localStyles.nameAndPic} onClick={() => onGoToUser(result)}>
                                        <ProfilePic
                                            user={result}
                                            size={20}
                                        />
                                        <div style={localStyles.name}>{result.first_name} {result.last_name}</div>
                                    </div>
                                    
                                </td>
                                <td>{result.phone_number}</td>
                                <td className="centered">{result.active ? "Yes" : "No"}</td>
                                <td className="centered">{result.verified ? "Yes" : "No"}</td>
                                <td className="centered">
                                    <div className="opacityHover" style={result.active ? localStyles.activeToggleRed : localStyles.activeToggle} onClick={() => onToggleActive(result)}>
                                        {result.active ? "Disable" : "Enable"}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};