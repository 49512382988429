import React from "react";
import { range } from "d3-array";
import { axisBottom } from "d3-axis";
import { scaleBand, scaleLinear } from "d3-scale";

import { colorScale, clearSvg, setupSvg } from "./helpers";

export default class extends React.Component {

    componentDidUpdate() {
        this.renderScores(this.props.data);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => this.renderScores(this.props?.data || []);

    renderScores(data) {
        const setup = {
            id: "chart_scores",
            height: 320,
            margin: { top: 30, right: 0, bottom: 20, left: 0 }
        }
        clearSvg(setup);
        const { g, width, height } = setupSvg(setup);
        const scales = {
            x: scaleBand().domain(range(10).map(d => d + 1)).range([0, width]).paddingInner(0.1),
            y: scaleLinear().domain([0, Math.max(...data)]).range([0, height])
        }
        g.selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d,i) => scales.x(i + 1))
            .attr("y", d => height - scales.y(d))
            .attr("height", d => scales.y(d))
            .attr("width", d => scales.x.bandwidth())
            .style("fill", (d, i) => colorScale(i + 1));
        g.selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("x", (d,i) => scales.x(i + 1) + scales.x.bandwidth() / 2)
            .attr("y", d => height - scales.y(d) - 10)
            .text(d => d)
            .style("text-anchor", "middle");
        const axis = axisBottom(scales.x);
        g.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(axis);
    }

    render() {
        return (
            <div className="score tile">
                <div className="row">
                    <h2>Scores</h2>
                </div>
                <div className="row">
                    <svg id="chart_scores"></svg>
                </div>
            </div>
        )
    }
}