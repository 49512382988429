import React from "react";
import PropTypes from "prop-types";
import { CardFeature } from "../../services/DiscoverCardServices";
import { Box } from "@chakra-ui/react";
import BaseFormControl from "../BaseFormControl";
import ImageFormControl from "./ImageFormControl";
import ContentFormControl from "./ContentFormControl";
import FormWebsiteLinkFormControl from "./FormWebsiteLinkFormControl";
import ExternalArticleLinkFormControl from "./ExternalArticleLinkFormControl";

const CardFormControl = ({ feature, values, onChange, ...props }) => {
  switch (feature) {
    case CardFeature.Title:
      return (
        <BaseFormControl
          name={"title"}
          label={"Title"}
          value={values.title}
          onChange={(e) => onChange("title", e.target.value)}
          {...props}
        />
      );
    case CardFeature.Description:
      return (
        <BaseFormControl
          name={"description"}
          label={"Description"}
          value={values.description}
          onChange={(e) => onChange("description", e.target.value)}
          {...props}
        />
      );
    case CardFeature.Image:
      return (
        <ImageFormControl
          name={"image_url"}
          value={values.image_url}
          onChange={(val) => onChange("image_url", val)}
          {...props}
        />
      );
    case CardFeature.Content:
      return (
        <ContentFormControl
          name={"body"}
          label={"Card Body Content"}
          value={values.body}
          onChange={(val) => onChange("body", val)}
          {...props}
        />
      );
    case CardFeature.FormWebsiteLink:
      return (
        <FormWebsiteLinkFormControl
          value={values.config.url}
          onChange={(articleCard) => {
            onChange("all", {
              ...articleCard,
              reasons: values.reasons,
            });
          }}
        />
      );
    case CardFeature.Link:
      return (
        <BaseFormControl
          name={"link"}
          label={"External Link"}
          value={values.config.url}
          onChange={(e) => onChange("config.url", e.target.value)}
          helperText={"Paste the full external url below"}
          {...props}
        />
      );
    case CardFeature.Price:
      return (
        <BaseFormControl
          name={"price"}
          label={"Price"}
          value={values.config.price}
          type={"number"}
          onChange={(e) => onChange("config.price", Number(e.target.value))}
          inputProps={{
            step: "0.01",
          }}
          {...props}
        />
      );
    case CardFeature.Cta:
      return (
        <ContentFormControl
          name={"cta"}
          label={"Call to action"}
          value={values.config.cta}
          onChange={(body) => onChange("config.cta", body)}
          {...props}
        />
      );
    case CardFeature.Spotify:
      return (
        <BaseFormControl
          name={"spotify"}
          label={"Spotify Link"}
          value={values.config.spotify}
          onChange={(e) => onChange("config.spotify", e.target.value)}
          {...props}
        />
      );
    case CardFeature.AppleMusic:
      return (
        <BaseFormControl
          name={"applemusic"}
          label={"Apple Music Link"}
          value={values.config.apple}
          onChange={(e) => onChange("config.apple", e.target.value)}
          {...props}
        />
      );
    case CardFeature.MixCloud:
      return (
        <BaseFormControl
          name={"mixcloud"}
          label={"Mixcloud Link"}
          value={values.config.mixcloud}
          onChange={(e) => onChange("config.mixcloud", e.target.value)}
          {...props}
        />
      );
    case CardFeature.ExternalArticleLink:
      return (
        <ExternalArticleLinkFormControl
          value={values.config.url}
          onChange={(newValues) =>
            onChange("all", {
              ...values,
              ...newValues,
            })
          }
        />
      );
    default:
      return null;
  }
};

CardFormControl.propTypes = {
  values: PropTypes.any,
  feature: PropTypes.oneOf(Object.values(CardFeature)),
  onChange: PropTypes.func,
  ...Box.propTypes,
};

export default CardFormControl;
