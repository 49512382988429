import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HttpServices from "../services/HttpServices";

export const PollContext = createContext(null);

export const PollProvider = ({ children }) => {
  const { name, number } = useParams();
  const [poll, setPoll] = useState({});

  useEffect(() => {
    const getPoll = () => {
      let url = `/polls/${name}/instances/latest`;

      if (number) {
        url += `/${Number(number)}`;
      }

      HttpServices.get(url).then((result) => {
        setPoll(result);
      });
    };

    if (name || number) {
      getPoll();
    }
  }, [name, number]);

  return <PollContext.Provider value={poll}>{children}</PollContext.Provider>;
};
