import React, { Fragment, useEffect, useState } from "react";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import Button, { buttonStyles } from "../components/Button";
import HttpServices from "../services/HttpServices";
import PollItem from "../components/PollItem";
import NewPollModal from "../components/NewPollModal";
import UserCompanyServices from "../services/UserCompanyServices";
import { get } from "lodash";
import PollOrderServices, {
  OrderOption,
  SortOption,
} from "../services/PollOrderServices";

export default () => {
  const theme = Themes[useColorScheme()];
  const [polls, setPolls] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortBy, setSortBy] = useState(SortOption.Created);
  const [order, setOrder] = useState(OrderOption.Descending);
  const [query, setQuery] = useState("");
  const org = UserCompanyServices.selectedCompany || {};

  const sortedPolls = PollOrderServices.sort(polls, sortBy, order);
  const searchedPolls = PollOrderServices.search(sortedPolls, query);

  useEffect(() => {
    const pollsOrgId = get(polls, "[0].organisation_id");
    if (org && org.id && org.id !== pollsOrgId) {
      HttpServices.get(`/polls?orgId=${org.id}`).then((result) => {
        setPolls(result);
      });
    }
  }, [org, org.id]);

  const handleNewPoll = async ({ name, captureDrivers }) => {
    const result = await HttpServices.post("/polls", {
      poll: {
        name,
        capture_drivers: captureDrivers,
        organisation_id: org.id,
      },
    }).catch((e) => {
      console.error(e);
      alert(
        "There was an error creating your poll. Please double check the poll details and try again"
      );
    });

    if (result && result.id) {
      window.location.reload();
    }
  };

  const handleDownload = (e) => {
    e.preventDefault();
    HttpServices.downloadFile(
      `/polls/organisations/${org.id}/download`,
      `${org.name} - Organisation Poll Report.csv`
    );
  };

  const styles = {
    pageButtons: {
      display: "flex",
    },
    actions: {
      alignItems: "center",
      display: "flex",
      marginTop: 20,
    },
    action: {
      alignItems: "center",
      display: "flex",
      marginRight: 20,
    },
    actionSelect: {
      padding: "5px 10px",
      borderRadius: 8,
      border: `1px solid ${theme.colors.borderContrast}`,
    },
    label: {
      marginRight: 5,
    },
  };

  return (
    <div className={"bs"}>
      <h1 style={theme.styles.pageHeading}>Polls</h1>
      {!org.id && (
        <p>You must have a company selected to view and create polls</p>
      )}
      {org.id && (
        <Fragment>
          <div style={styles.pageButtons}>
            <div
              onClick={handleDownload}
              style={{
                ...buttonStyles(theme, {
                  width: "170px",
                }),
                textDecoration: "none",
                marginRight: 20,
              }}
              className={"link--white opacityHover"}
            >
              Download all
            </div>
            <Button
              label={"Create New Poll"}
              width={"170px"}
              onClick={() => setShowModal(true)}
            />
          </div>
          <NewPollModal
            visible={showModal}
            onHide={() => setShowModal(false)}
            onSubmit={handleNewPoll}
          />
          <div style={styles.actions}>
            <div style={styles.action}>
              <p style={styles.label}>Sort by:</p>
              <select
                style={styles.actionSelect}
                name="sort_polls"
                onChange={(e) => setSortBy(e.target.value)}
                value={sortBy}
              >
                {Object.entries(SortOption).map(([label, value]) => {
                  return (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div style={styles.action}>
              <p style={styles.label}>Order: </p>
              <select
                style={styles.actionSelect}
                name="order_polls"
                onChange={(e) => setOrder(e.target.value)}
                value={order}
              >
                {Object.entries(OrderOption).map(([label, value]) => {
                  return (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div style={styles.action}>
              <p style={styles.label}>Search: </p>
              <input
                type="text"
                defaultValue={query}
                name={"search_polls"}
                style={styles.actionSelect}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          {searchedPolls.map((p) => (
            <PollItem key={p.id} {...p} />
          ))}
        </Fragment>
      )}
    </div>
  );
};
