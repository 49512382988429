import React from "react";
import Emojis from "../Emojis";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";


const OFF_OPACITY = 0.6;

export default({reason, on, onToggle, activeColor, iconWidth, iconMargin}) => {
    const theme = Themes[useColorScheme()];

    const localStyles = {
        reason: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            opacity: on ? 1 : OFF_OPACITY,
            filter: on ? null : "grayscale(95%)",
            margin: iconMargin,
            transition: "all 0.3s ease-in-out",
            //transform: on ? "scale(1)" : "scale(0.8)",
            width: iconWidth,
            height: iconWidth,
            borderWidth: 2,
            borderColor: on ? activeColor : "transparent",
            borderRadius: 4,
            borderStyle: "solid"
        },
        iconHolder: {
            display: "flex",
            alignItems: "center",
            height: 30,
            
        },
        reasonLabel: {
            marginTop: 10,
            fontSize: 10,
            textAlign: "center",
            fontWeight: "700",
            maxWidth: iconWidth - 2,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap", 
            overflow: "hidden",
            opacity: on ? 1 : OFF_OPACITY
        }
    };

    var emoji = Emojis[reason.emoji];

    if (!emoji && theme.images[reason.emoji]) {
        emoji = {
            width: 48,
            height: 48,
            image: theme.images[reason.emoji]
        }
    }

    if (!emoji) {
        console.log("No emoji for", reason);
        return null;
    }

    return (
        <div style={localStyles.reason} onClick={onToggle}>
            <div style={localStyles.iconHolder}>
                <img 
                    src={emoji.image}
                    alt={reason.title}
                    style={{width: 30, height: 30, objectFit: "contain"}}
                />
            </div>
            
            <div style={localStyles.reasonLabel}>{reason.title}</div>
        </div>
    );

};