import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export const buttonStyles = (
  theme,
  { dangerous, disabled, height, width }
) => ({
  overflow: "hidden",
  userSelect: "none",
  backgroundColor: disabled
    ? theme.colors.border
    : dangerous
    ? theme.colors.red
    : theme.colors.primary,
  color: theme.colors.lightOnPrimary,
  fontSize: "16px",
  fontWeight: 500,
  paddingLeft: "20px",
  paddingRight: "20px",
  height: height || "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "25px",
  cursor: "pointer",
  textAlign: "center",
  transition: "all 0.1s ease-in-out",
  width: width ? width : null,
});

export function useButtonStyles({ dangerous, disabled, height, width } = {}) {
  const theme = Themes[useColorScheme()];
  const [styles, setStyles] = useState({});

  useEffect(() => {
    setStyles(buttonStyles(theme, { dangerous, disabled, height, width }));
  }, [theme, dangerous, disabled, height, width]);

  styles._hover = {
    background: theme.colors.primary,
  };

  return styles;
}

export default ({
  label,
  loading,
  dangerous,
  height,
  width,
  disabled,
  onClick,
  confirm,
  confirmText,
}) => {
  const theme = Themes[useColorScheme()];
  const [confirming, setConfirming] = useState(false);

  const localStyles = {
    button: buttonStyles(theme, {
      dangerous,
      disabled,
      height,
      width,
    }),
    sureText: {
      color: theme.colors.alwaysWhite,
      fontSize: 10
    },
    yesNoHolder: {
      marginTop: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      flex: 1,
    },
    yes: {
      fontSize: 11,
      opacity: 0.7,
      fontWeight: "700",
      color: theme.colors.alwaysWhite,
    },
    no: {
      fontSize: 11,
      opacity: 0.7,
      fontWeight: "700",
      color: theme.colors.alwaysWhite,
    },
  };

  const handleClick = () => {
    if (disabled || confirming) {
      return;
    }

    if (confirm) {
      setConfirming(true);
      return;
    }

    onClick();
  };

  const no = () => {
    setConfirming(false);
  };

  const yes = () => {
    onClick();
  };

  const renderContent = () => {
    if (loading) {
      return <div className="buttonSpinner"></div>;
    }

    if (confirming) {
      return (
        <div>
          <div style={localStyles.sureText}>
            {confirmText ? confirmText : "Delete"}?
          </div>
          <div style={localStyles.yesNoHolder}>
            <div
              className="yesNoHover"
              style={localStyles.yes}
              onClick={() => yes()}
            >
              Yes
            </div>
            <div
              className="yesNoHover"
              style={localStyles.no}
              onClick={() => no()}
            >
              No
            </div>
          </div>
        </div>
      );
    }

    return label;
  };

  return (
    <div
      className="opacityHover"
      style={localStyles.button}
      onClick={() => handleClick()}
    >
      {renderContent()}
    </div>
  );
};
