import { range } from "d3-array";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";

export function formatNumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const colors = [
    "#252729", // 1
    "#b14125", // 2
    "#df603f", // 3
    "#df873c", // 4
    "#e0ad3a", // 5
    "#72b1a2", // 6
    "#7fd0cb", // 7
    "#7dbee8", // 8
    "#7096fe", // 9
    "#517eff", // 10
];

export const colorScale = scaleLinear().range(colors).domain(range(10).map(d => d + 1));

export function clearSvg(setup) {
    const svg = select(`#${setup.id}`);
    svg.select("g").remove();
}

export function setupSvg(setup) {
    // set the dimensions and margins of the graph
    const margin = setup.margin;
    const svgHeight = setup.height;
    const height = svgHeight - margin.top - margin.bottom;

    const svg = select(`#${setup.id}`)
        .attr("height", height + margin.top + margin.bottom);
    const svgWidth = document.getElementById(setup.id).clientWidth,
        width = svgWidth - margin.right - margin.left;

    const g = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    return { g, width, height };
}