import React from "react";

import { Line } from "react-chartjs-2";
import moment from "moment";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

export default ({ weeklyActiveUsers }) => {
  const theme = Themes[useColorScheme()];

  if (!weeklyActiveUsers || weeklyActiveUsers.length === 0) {
    return null;
  }

  var dates = [];
  var userData = [];

  const reversedWeeks = weeklyActiveUsers.concat([]).reverse();

  reversedWeeks.forEach((week) => {
    userData.push(week.unique_users);
    const startOfWeek = moment(week.week_date);
    dates.push(startOfWeek.format("DD MMM"));
  });

  const data = {
    labels: dates,
    datasets: [
      {
        label: "Active Users",
        data: userData,
        fill: false,
        backgroundColor: theme.colors.primary,
        borderColor: theme.colors.primary,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return <Line data={data} options={options} />;
};
