import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import Modal from "./Modal";

export default ({ visible, organisation, onHide, onRefresh }) => {
  const theme = Themes[useColorScheme()];
  const [emails, setEmails] = useState("");
  const [adding, setAdding] = useState(false);

  const localStyles = {
    content: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 20,
      width: "100%",
    },
    textArea: {
      borderColor: theme.colors.border,
      borderRadius: 6,
      outline: theme.colors.primary,
      width: "90%",
      height: "80%",
      padding: 10,
    },
  };

  const getEmailArray = () => {
    if (emails.length === 0) {
      return [];
    }
    return emails
      .split("\n")
      .map((email) => email.replace(/[\n\t]/g, "").trim());
  };

  const renderContent = () => {
    return (
      <div style={localStyles.content}>
        <textarea
          cols={20}
          rows={20}
          style={localStyles.textArea}
          placeholder="Enter one email address per line"
          value={emails}
          onChange={(evt) => setEmails(evt.target.value)}
        ></textarea>
      </div>
    );
  };

  const hide = () => {
    setEmails("");
    onHide();
  };

  const addEmails = async () => {
    const emailArray = getEmailArray();

    if (emailArray.length === 0) {
      alert("No emails found in list");
      return;
    }

    setAdding(true);

    await HttpServices.post(`/organisation/${organisation.id}/emails/batch`, {
      emails: emailArray,
    });

    await onRefresh();

    hide();
    setAdding(false);
  };

  return (
    <Modal
      title="Add multiple emails"
      visible={visible}
      height="80%"
      buttons={{
        left: [
          { id: "cancel", label: "Cancel", onClick: () => hide(), width: 60 },
        ],
        right: [
          {
            id: "save",
            label: "Save",
            onClick: () => addEmails(),
            width: 60,
            loading: adding,
            disabled: false,
          },
        ],
      }}
      onHide={() => hide()}
      renderContent={() => renderContent()}
    />
  );
};
