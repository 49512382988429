import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import AddDomainModal from "./AddDomainModal";
import Checkbox from "./Checkbox";

const LINK = "https://dashboard.formscore.today/email/subscribe";

export default({organisation, config, onChange}) => {
    const theme = Themes[useColorScheme()];
    const [showAddDomainModal, setShowAddDomainModal] = useState(false);

    if (!config) {
        return null;
    }

    const localStyles = {
        row: {
            display: "flex",
            flexDirection: "row"
        },
        addedDomain: {
            display: "flex",
            flexDirection: "row"
        },
        linkHolder: {
            marginLeft: 34,
            marginTop: 5,
            fontSize: 14,
            
        },
        link: {
            color: theme.colors.primary,
            cursor: "pointer",
            marginLeft: 5,
            marginRight: 5
        },
        bold: {
            fontWeight: "700"
        },
        domains: {
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    };

    const changeToggle = () => {
        var updatedConfig = Object.assign({}, config);
        updatedConfig.allow_signup = !updatedConfig.allow_signup;
        onChange(updatedConfig);
    };

    const addDomain = () => {
        setShowAddDomainModal(true);
    };

    const removeDomain = (domain) => {
        var updatedConfig = Object.assign({}, config);
       
        for (var i = 0; i < updatedConfig.domains.length; i++) {
            if (updatedConfig.domains[i] === domain) {
                updatedConfig.domains.splice(i, 1);
                break;
            }
        }

        onChange(updatedConfig);
    };

    const renderDomains = () => {
        if (!config.domains || config.domains.length === 0) {
            return (
                <div style={localStyles.domains}>
                    Any email domain can signup <div style={localStyles.link} onClick={() => addDomain()}>[Add]</div>
                </div>
            );
        }

        return (
            <div>
                <div style={localStyles.domains}>
                    {config.domains.map((domain) => {
                        return (
                            <div key={domain} style={localStyles.addedDomain}>@{domain} <div style={localStyles.link} onClick={() => removeDomain(domain)}>[x]</div></div>
                        )
                    })}
                    <div style={localStyles.link} onClick={() => addDomain()}>[Add]</div>
                </div>
                
            </div>
        );
        
    };

    const onAddDomain = (domain) => {
        var updatedConfig = Object.assign({}, config);
        
        if (!updatedConfig.domains) {
            updatedConfig.domains = [];
        }

        updatedConfig.domains.push(domain);

        onChange(updatedConfig);
    };

    const renderLink = () => {
        if (!config.allow_signup) {
            return null;
        }

        const linkId = organisation.short_id ? `sid=${organisation.short_id}` : `id=${organisation.id}`;

        return (
            <div style={localStyles.linkHolder}>
                <a style={localStyles.bold} href={`${LINK}?${linkId}`}>{LINK}?{linkId}</a>
                {renderDomains()}
            </div>
        );
    };

    return (
        <div>
            <h2>Manual Signup</h2>
            <div style={localStyles.row}>
                <Checkbox
                    on={config.allow_signup}
                    onChange={() => changeToggle()}
                />
                <div>Allow users to signup to email alerts if they have the link</div>
                
            </div>
            {renderLink()}
            <AddDomainModal
                visible={showAddDomainModal}
                onAdd={(domain) => onAddDomain(domain)}
                onHide={() => setShowAddDomainModal(false)}
            />
        </div>
    );

};