const DISCOVER_TAB_FLAG = "discover_tab";
const LOW_FORM_POPUP_FLAG = "low_form_popup";
const LOW_SCORE_RESOURCES_FLAG = "low_score_resources";
const POLLS_FLAG = "polls";
const APPS_ADMIN_FLAG = "apps_admin";

export default {
  DISCOVER_TAB_FLAG,
  LOW_FORM_POPUP_FLAG,
  LOW_SCORE_RESOURCES_FLAG,
  POLLS_FLAG,
  APPS_ADMIN_FLAG,

  isFeatureOn(user, flag) {
    if (!user || !user.feature_flags) {
      return false;
    }

    if (!user.feature_flags[flag]) {
      return false;
    }

    return true;
  },
};
