export const discoverCardTypes = [];

export const CardFeature = {
  Title: "Title",
  Description: "Description",
  Image: "Image",
  Content: "Content",
  FormWebsiteLink: "Form Website Link",
  Price: "Price",
  Link: "Link",
  Cta: "Call to Action",
  Country: "Country",
  Spotify: "Spotify",
  AppleMusic: "Apple Music",
  MixCloud: "Mixcloud",
  Video: "Video",
  Podcast: "Podcast",
  ExternalArticleLink: "External Article Link",
};

const defaultCardFeatures = [
  CardFeature.Title,
  CardFeature.Description,
  CardFeature.Image,
  CardFeature.Content,
];

function addCardType({ id, label, features, helperText, fieldLabels }) {
  discoverCardTypes.push({
    id,
    label,
    features: features || defaultCardFeatures,
    helperText,
    fieldLabels,
  });
}

addCardType({
  id: "article",
  label: "Form Website Article",
  features: [CardFeature.FormWebsiteLink],
});

addCardType({
  id: "tip",
  label: "Form Tip",
  features: [...defaultCardFeatures, CardFeature.Cta],
});

addCardType({
  id: "product",
  label: "Product Link",
  features: [
    ...defaultCardFeatures,
    CardFeature.Link,
    CardFeature.Price,
    CardFeature.Country,
  ],
});

addCardType({
  id: "offer",
  label: "Offers and discounts",
  features: [...defaultCardFeatures, CardFeature.Link],
});

addCardType({
  id: "charity",
  label: "Charity",
  features: [...defaultCardFeatures, CardFeature.Link],
});

addCardType({
  id: "music",
  label: "Music",
  features: [
    ...defaultCardFeatures,
    CardFeature.AppleMusic,
    CardFeature.Spotify,
    CardFeature.MixCloud,
  ],
});

addCardType({
  id: "video",
  label: "Video",
  fieldHelperText: {
    [CardFeature.Link]: "Add full video url here",
  },
  fieldLabels: {
    [CardFeature.Link]: "Video Link",
  },
  features: [...defaultCardFeatures, CardFeature.Link],
});

addCardType({
  id: "podcast",
  label: "Podcast",
  fieldHelperText: {
    [CardFeature.Link]: "Add full podcast url here",
  },
  fieldLabels: {
    [CardFeature.Link]: "Podcast Link",
  },
  features: [...defaultCardFeatures, CardFeature.Link],
});

addCardType({
  id: "external article",
  label: "External Article Link",
  features: [CardFeature.ExternalArticleLink, ...defaultCardFeatures],
});
