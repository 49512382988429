import React from "react";
import Button from "./Button";
import HttpServices from "../services/HttpServices";
import moment from "moment";
import PollDropdownList from "./PollDropdownList";
import { MdDelete, MdDownload } from "react-icons/md";

const styles = {
  container: {
    paddingBottom: 20,
  },
  title: {
    marginBottom: 5,
  },
  latestPoll: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  latestPollLink: {
    marginRight: 5,
  },
  buttons: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "120px 120px",
    gridGap: 20,
  },
};

export default ({ id, short_id, name, instances = [] }) => {
  const handlePollStart = async () => {
    const result = await HttpServices.post(`/polls/${id}/start`);

    if (result.id) {
      window.open(`/p/${name}/${result.number}`);
      window.location.reload();
    }
  };

  const handleInstanceDelete = async (instance) => {
    await HttpServices.delete(`/polls/instances/${instance.id}`);
    window.location.reload();
  };

  const handlePollDelete = async () => {
    await HttpServices.delete(`/polls/${id}`);
    window.location.reload();
  };

  const handleInstanceDownload = (instance) => (e) => {
    e.preventDefault();
    HttpServices.downloadFile(
      `/polls/instances/${instance.id}/download`,
      `${name} - ${instance.number} Report.csv`
    );
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>{name}</h2>
      {instances.length > 0 && (
        <div>
          <div style={styles.latestPoll}>
            <div className={"mr--10"}>
              Latest poll:{" "}
              <a
                href={`/p/${short_id}/${instances[0].number}`}
                target={"_blank"}
                rel={"noreferrer"}
                style={styles.latestPollLink}
              >
                {moment(instances[0].created_at).format(
                  "MMM Do YYYY, h:mm:ssa"
                )}
              </a>
            </div>
            <div
              onClick={handleInstanceDownload(instances[0])}
              className={"display--block link--clean mr--10"}
            >
              <MdDownload className={"icon--md"} />
            </div>
            <MdDelete
              className={"icon--md"}
              onClick={() => handleInstanceDelete(instances[0])}
            />
          </div>
          {instances.length > 1 && (
            <PollDropdownList
              onDelete={handleInstanceDelete}
              onDownload={handleInstanceDownload}
              items={instances.slice(1).map((i) => ({
                ...i,
                name,
              }))}
            />
          )}
        </div>
      )}
      <div style={styles.buttons}>
        <Button label={"Start New"} width={120} onClick={handlePollStart} />
        <Button
          label={"Delete"}
          width={120}
          onClick={handlePollDelete}
          dangerous
        />
      </div>
    </div>
  );
};
