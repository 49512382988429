import React, { useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import {
  uniqueNamesGenerator,
  colors,
  names,
  adjectives,
  animals,
} from "unique-names-generator";
import Checkbox from "./Checkbox";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

const generateName = () =>
  uniqueNamesGenerator({
    dictionaries: [adjectives, colors, names, animals],
    length: 3,
    separator: "-",
    style: "lowerCase",
  });

export default ({ visible, onHide, onSubmit }) => {
  const theme = Themes[useColorScheme()];
  const [name, setName] = useState(generateName());
  const [captureDrivers, setCapturedDrivers] = useState(true);

  const handleSubmit = (e) => {
    if (!name) {
      alert("Please choose a name for your poll");
      return;
    }

    onSubmit({ name: name, captureDrivers });
  };

  const styles = {
    form: {
      padding: 20,
      width: "570px",
    },
    formControl: {
      display: "block",
      marginBottom: 20,
    },
    label: {
      display: "block",
      fontWeight: "bold",
      marginBottom: 10,
    },
    inputContainer: {
      display: "flex",
    },
    input: {
      padding: 10,
      background: "transparent",
      border: `1px solid ${theme.colors.border}`,
      borderRadius: "4px",
      color: theme.colors.black,
      display: "block",
      marginBottom: 10,
      width: "100%",
      marginRight: 10,
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    refreshIcon: {
      display: "block",
      width: 35,
    },
  };

  return (
    <Modal
      title={"Create New Poll"}
      width={"570px"}
      height={"auto"}
      visible={visible}
      onHide={onHide}
      renderContent={() => (
        <div>
          <form className={"bs"} style={styles.form}>
            <label htmlFor="poll_name" style={styles.formControl}>
              <span style={styles.label}>Name</span>
              <div style={styles.inputContainer}>
                <input
                  id={"poll_name"}
                  name={"poll_name"}
                  placeholder={"Poll name"}
                  style={styles.input}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div onClick={() => setName(generateName())}>
                  <img
                    src={theme.images.refreshIcon}
                    alt={"refresh"}
                    style={styles.refreshIcon}
                  />
                </div>
              </div>
            </label>
            <div onClick={() => setCapturedDrivers(!captureDrivers)}>
              <span style={styles.label}>Capture Drivers</span>
              <Checkbox
                on={captureDrivers}
                onChange={() => setCapturedDrivers(!captureDrivers)}
              />
            </div>
            <div style={styles.footer}>
              <Button label={"Save"} width={"100px"} onClick={handleSubmit} />
            </div>
          </form>
        </div>
      )}
    />
  );
};
