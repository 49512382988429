import { useState, useEffect } from 'react';
import UserCompanyServices from '../services/UserCompanyServices';

import { v4 as uuidv4 } from 'uuid';

export default() => {
    const [companies, setCompanies] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        UserCompanyServices.addListener(uuidv4(), (updates) => {
            setCompanies(updates.companies);
            setSelectedCompany(updates.selected);
            setAdmin(updates.admin);
        });
    }, []);

    return {
        companies: companies,
        selected: selectedCompany,
        admin: admin
    };
}