import React, { useState } from "react";
import TextBox from "../components/TextBox";
import HttpServices from "../services/HttpServices";
import moment from "moment";
import { Box, Heading, HStack, VStack } from "@chakra-ui/react";
import GeneratedReports from "../components/GeneratedReports";
import { GeneratedReportsProvider } from "../contexts/GeneratedReportsContext";

const AVAILABLE_REPORTS = [
  {
    url: "/reporting/organisation/scores",
    title: "Organisation Scores",
    filename: "Organisation_Scores",
  },
  {
    url: "/reporting/organisation/reasons",
    title: "Organisation Reasons",
    filename: "Organisation_Reasons",
  },
  {
    url: "/reporting/organisation/parent/scores",
    title: "Parent Organisation Grouped Scores",
    filename: "Parent_Organisation_Scores",
  },
  {
    url: "/reporting/organisation/parent/reasons",
    title: "Parent Organisation Grouped Reasons",
    filename: "Parent_Organisation_Reasons",
  },
  {
    url: "/reporting/organisation/messages",
    title: "Indicative message counts by organisation",
    filename: "Organisation_Messages",
  },
];

export default () => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const downloadReport = async (report) => {
    const filename = report.filename;
    let datePart = "";
    let queryStr = "?download=true";

    if (dateFrom && moment(dateFrom).isValid()) {
      if (datePart.length === 0) {
        datePart += "_";
      }

      datePart += moment(dateFrom).format("YYYY-MM-DD");
      queryStr += `&date_from=${moment(dateFrom).format("YYYY-MM-DD")}`;
    }

    if (dateTo && moment(dateTo).isValid()) {
      datePart += "_to_";
      datePart += moment(dateTo).format("YYYY-MM-DD");
      queryStr += `&date_to=${moment(dateTo).format("YYYY-MM-DD")}`;
    }

    await HttpServices.downloadFile(
      report.url + queryStr,
      `${filename}${datePart}.csv`
    );
  };

  const clearDateRange = () => {
    setDateFrom("");
    setDateTo("");
  };

  return (
    <div>
      <Heading as={"h1"} mb={4}>
        Admin Reports
      </Heading>

      <Heading variant={"h2"} as={"h2"} mb={2}>
        Date Range
      </Heading>

      <HStack spacing={4}>
        <TextBox
          label="From"
          type="date"
          value={dateFrom}
          onChange={(val) => setDateFrom(val)}
        />
        <TextBox
          label="To"
          type="date"
          value={dateTo}
          onChange={(val) => setDateTo(val)}
        />
      </HStack>

      <Box
        color={"black"}
        opacity={0.5}
        cursor={"pointer"}
        fontWeight={500}
        fontSize={12}
        display={"inline-block"}
        mb={1}
        className="opacityHover"
        onClick={() => clearDateRange()}
      >
        Clear Date Range
      </Box>

      <Heading variant={"h2"} as={"h2"} mb={2}>
        Available Reports
      </Heading>

      <VStack spacing={1} alignItems={"flex-start"} mb={4}>
        {AVAILABLE_REPORTS.map((report) => {
          return (
            <Box
              color={"primary"}
              cursor={"pointer"}
              fontWeight={500}
              key={report.url}
              className="opacityHover"
              onClick={() => downloadReport(report)}
            >
              {report.title}
            </Box>
          );
        })}
      </VStack>

      <GeneratedReportsProvider>
        <GeneratedReports />
      </GeneratedReportsProvider>
    </div>
  );
};
