import { createContext } from "react";
import { useParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";

export const CardContext = createContext({
  card: {},
  update: () => {},
  updateOrg: () => {},
});

export const CardProvider = ({ cardId, children }) => {
  const params = useParams();
  const finalCardId = cardId || params.id;
  const { data: card } = useApi(`/discover/cards/${finalCardId}`);

  const handleUpdate = async (newCard) => {
    await HttpServices.post(`/discover/cards/${card.id}`, {
      card: newCard,
    });

    mutate(`/discover/cards/${card.id}`);
    mutate(`/discover/cards`);
  };

  const handleUpdateOrg = async (newCard) => {
    const data = {
      ...newCard,
      organisation_id: JSON.parse(localStorage.getItem("selectedCompany")).id,
    };
    await HttpServices.post(`/discover/cards/${card.id}`, { card: data });

    mutate(
      `/discover/cards?organisation_id=${
        JSON.parse(localStorage.getItem("selectedCompany")).id
      }`
    );
  };

  if (!card || !card.id) {
    return null;
  }

  return (
    <CardContext.Provider
      value={{ card, update: handleUpdate, updateOrg: handleUpdateOrg }}
    >
      {children}
    </CardContext.Provider>
  );
};
