import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import Countries from "../data/CountryCodes";

export default ({value, onChange}) => {
    const theme = Themes[useColorScheme()];

    return (
        <div>
            <div className="alwaysBlack" style={theme.styles.label}>
                Country
            </div>
            <select value={value} onChange={(evt) => onChange(evt.target.value)} style={theme.styles.countryBox}>
                {Object.keys(Countries).map((countryCode) => {
                    const country = Countries[countryCode];

                    return (
                        <option key={countryCode} value={countryCode}>{country.flag} &nbsp;&nbsp; {country.name}</option>
                    )
                })}
                
            </select>
        </div>
        
    )
};