import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Themes from "../Themes";
import HttpServices from "../services/HttpServices";
import { PollContext } from "../contexts/PollContext";
import { Box, Button, Flex, Image } from "@chakra-ui/react";

const scoreDescriptions = {
  1: "Crisis, seek help now",
  2: "I am struggling and need help",
  3: "Extremely low form",
  4: "Very low form",
  5: "Low form",
  6: "Average form",
  7: "Good form",
  8: "Very good form",
  9: "Exceptional form",
  10: "Lifetime peak form",
};

function PollScore() {
  const { number } = useParams();
  const poll = useContext(PollContext);
  const history = useHistory();
  const [score, setScore] = useState(6);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const result = await HttpServices.post(
      `/polls/instances/${poll.id}/scores`,
      {
        score,
      }
    ).catch((e) => {
      console.error(e);
      alert("Error with request");
      setLoading(false);
    });

    if (poll.capture_drivers) {
      let driversPath = `/p/${poll.short_id}/score/${result.id}/drivers`;

      if (number) {
        driversPath = `/p/${poll.short_id}/${number}/score/${result.id}/drivers`;
      }

      history.push(driversPath);
    } else {
      history.push("/p/thank-you");
    }
  };

  return (
    <Flex
      background={`form.${score}`}
      alignItems={"center"}
      flexDirection={"column"}
      justifyContent={"center"}
      fontFamily={"heading"}
      pb={"86px"}
      height={"100vh"}
      userSelect={"none"}
      sx={{
        touchAction: "manipulation",
      }}
    >
      <Image
        src={Themes.dark.images.formLogoWord}
        alt={"form"}
        width={"120px"}
        mb={6}
        display={"block"}
      />
      <Image
        src={Themes.dark.images.leftArrow}
        alt="Up arrow"
        transform={"rotate(90deg)"}
        onClick={() => setScore(Math.min(10, score + 1))}
      />
      <Flex
        as={"p"}
        color={"alwaysWhite"}
        fontSize={"50px"}
        py={"80px"}
        px={0}
        textAlign={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <span>{score}</span>
        <Box as={"span"} fontSize={"14px"}>
          {scoreDescriptions[score]}
        </Box>
      </Flex>
      <Image
        src={Themes.dark.images.leftArrow}
        alt="Down arrow"
        transform={"rotate(-90deg)"}
        onClick={() => setScore(Math.max(1, score - 1))}
      />
      <Flex
        position={"fixed"}
        p={4}
        width={"100%"}
        bottom={0}
        justifyContent={"center"}
      >
        <Button
          variant={"outline"}
          borderColor={"alwaysWhite"}
          color={"alwaysWhite"}
          fontSize={"22px"}
          p={2}
          height={"auto"}
          disabled={loading}
          width={"100%"}
          maxWidth={"440px"}
          opacity={loading ? 0.5 : 1}
          onClick={handleSave}
          _hover={{
            background: "transparent",
          }}
          _focus={{
            background: "transparent",
          }}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
}

export default PollScore;
