import React from "react";
import Checkbox from "./Checkbox";

export default({config, onChange}) => {
    if (!config) {
        return null;
    }

    const localStyles = {
        row: {
            display: "flex",
            flexDirection: "row",
            marginTop: 5
        }
    };

    const changeToggle = () => {
        var updatedConfig = Object.assign({}, config);
        updatedConfig.must_join_sub_team = !updatedConfig.must_join_sub_team;
        onChange(updatedConfig);
    };

    return (
        <div style={localStyles.row}>
            <Checkbox
                on={config.must_join_sub_team}
                onChange={() => changeToggle()}
            />
            <div>Users must join at least one sub team</div>
        </div>
    );

};