import { orderBy } from "lodash";

export const SortOption = {
  Created: "created_at",
  Name: "name",
  "Latest Used": "updated_at",
};
export const OrderOption = {
  Descending: "desc",
  Ascending: "asc",
};

export default {
  sort(polls, sortBy, order) {
    const sortFns = {
      [SortOption.Created]: this.sortByProp,
      [SortOption.Name]: this.sortByProp,
      [SortOption["Latest Used"]]: this.sortByProp,
    };

    const fn = sortFns[sortBy];

    if (!fn) {
      throw new Error(`Sort function ${sortBy} not recognised`);
    }

    return fn({ polls, sortBy, order });
  },

  sortByProp({ polls, sortBy, order }) {
    return orderBy(polls, [sortBy], order);
  },

  search(polls, query) {
    if (!query) {
      return polls;
    }

    return polls.filter((p) => {
      const rgx = new RegExp(query, "gi");

      return rgx.test(p.name);
    });
  },
};
