import React, { useEffect, useState } from "react";
import AddOrEditLowScoreResourceModal from "../components/AddOrEditLowScoreResourceModal";
import Button from "../components/Button";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";

export default({companies}) => {
    const theme = Themes[useColorScheme()];
    
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState(null);
    const [showAddOrEditModal, setShowAddOrEditModal] = useState(false);
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);

    const localStyles = {
        buttons: {
            display: "flex",
            flexDirection: "row",
            width: 200,
            alignItems: "center",
            justifyContent: "space-between"
        }
    };

    useEffect(() => {
        reloadResources();
    }, [companies]);

    const reloadResources = async() => {
        setLoading(true);

        var queryStr = "?";

        if (!companies.selected) {
            queryStr += `top_level=true`;
        } else {
            queryStr += `organisation_id=${companies.selected.id}`;
        }

        const loadedResouces = await HttpServices.get(`/score/low/resources${queryStr}`);
        setResources(loadedResouces);

        setLoading(false);
    };

    const showEditModal = (resource) => {
        setEditing(resource)
        setShowAddOrEditModal(true);
    };

    const showAddModal = (resource) => {
        setEditing(null)
        setShowAddOrEditModal(true);
    };

    const deleteResource = async (resource) => {
        setDeleting(resource);
        await HttpServices.delete(`/score/low/resources/${resource.id}`);
        reloadResources();
    };

    const renderContent = () => {
        if (loading) {
            return null;
        }

        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Clicks</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resources.map((resource) => {
                            return (
                                <tr key={resource.id}>
                                    <td>{resource.title}</td>
                                    <td>{resource.description}</td>
                                    <td>{resource.type}</td>
                                    <td>{resource.total_count || 0}</td>
                                    <td>
                                        <div style={localStyles.buttons}>
                                            <Button
                                                label="Edit"
                                                width={50}
                                                onClick={() => showEditModal(resource)}
                                            />
                                            <Button
                                                label="Delete"
                                                dangerous={true}
                                                confirm={true}
                                                loading={deleting && deleting.id === resource.id}
                                                width={50}
                                                onClick={() => deleteResource(resource)}
                                            />
                                        </div>
                                        
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
            
        );
    };

    const hideModal = () => {
        setShowAddOrEditModal(false);
        reloadResources();
    };
    
    return (
        <div>
            <h1 style={theme.styles.pageHeading}>Low Score Resources</h1>
            <p>When a user records a low score, if they are part of your organisation you can choose to highlight certain resources to them.</p>
            {renderContent()}

            <Button
                label="Add"
                width={50}
                onClick={() => showAddModal()}
            />

            <AddOrEditLowScoreResourceModal
                visible={showAddOrEditModal}
                organisationId={companies.selected ? companies.selected.id : null}
                editing={editing}
                onHide={() => hideModal()}
            />
            
        </div>
    );


};