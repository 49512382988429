import { createContext } from "react";
import HttpServices from "../services/HttpServices";

export const EditOrganisationsContext = createContext({
  createOrganisation: () => {},
  updateOrganisation: () => {},
  startOrganisationPollsTrial: () => {},
});

export const EditOrganisationsProvider = ({ children }) => {
  const handleCreateOrganisation = async (name, parentId) => {
    return HttpServices.post("/organisation", {
      name,
      parent_id: parentId,
    });
  };
  const handleStartOrganisationPollsTrial = async (orgId) => {
    return HttpServices.post("/products/default-trial", {
      orgId,
    });
  };

  return (
    <EditOrganisationsContext.Provider
      value={{
        createOrganisation: handleCreateOrganisation,
        updateOrganisation: () => {},
        startOrganisationPollsTrial: handleStartOrganisationPollsTrial,
      }}
    >
      {children}
    </EditOrganisationsContext.Provider>
  );
};
