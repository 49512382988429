import { createContext } from "react";
import useApi from "../hooks/useApi";

export const DiscoverCategoriesContext = createContext([]);

export const DiscoverCategoriesProvider = ({ children }) => {
  const { data: categories } = useApi("/discover/categories", {
    defaultValue: [],
  });

  return (
    <DiscoverCategoriesContext.Provider value={categories}>
      {children}
    </DiscoverCategoriesContext.Provider>
  );
};
