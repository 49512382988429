import React, { createContext } from "react";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";
import { useInterval } from "react-use";

export const GeneratedReportsContext = createContext({
  reports: [],
  add: () => {},
  remove: () => {},
});

export const GeneratedReportsProvider = ({ children }) => {
  const { data: reports } = useApi("/reports", {
    defaultValue: [],
  });

  const handleAdd = async (newReport) => {
    return new Promise((res) => {
      HttpServices.post("/reports", newReport);
      setTimeout(async () => {
        await mutate("/reports");
        res();
      }, 1000);
    });
  };

  const handleRemove = async (reportId) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this report?"
    );

    if (confirmed) {
      await HttpServices.delete(`/reports/${reportId}`);
      await mutate("/reports");
    }
  };

  useInterval(async () => {
    if (reports.some((r) => r.status === "generating")) {
      await mutate("/reports");
    }
  }, 2000);

  return (
    <GeneratedReportsContext.Provider
      value={{
        reports,
        add: handleAdd,
        remove: handleRemove,
      }}
    >
      {children}
    </GeneratedReportsContext.Provider>
  );
};
