import React from "react";
import EmailScoreReason from "./EmailScoreReason";

export default({availableReasons, reasons, onChangeReasons, activeColor}) => {
    const iconWidth = window.innerWidth < 400 ? 66 : 80;
    const iconMargin = 6;
    
    const localStyles = {
        outerContainer: {
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            paddingBottom: 300
        },
        container: {
            marginTop: 0,
            marginBottom: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            //justifyContent: "center",
            alignItems: "center"
        },
        organisationTitle: {
            marginTop: 5,
            marginBottom: 10,
            fontSize: 15,
            fontWeight: "500"
        },
        centered: {
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        centeredReasons: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }
        
    }

    const onToggleReason = (reason) => {
        var updatedReasons = Object.assign({}, reasons);
        updatedReasons[reason.id] = !updatedReasons[reason.id];
        onChangeReasons(updatedReasons);
    };

    const getGenericAndOrganisationReasons = () => {
        var genericReasons = [];
        var organisationReasons = [];

        availableReasons.forEach((reason) => {
            if (reason.organisation_id) {
                organisationReasons.push(reason);                
            } else {
                genericReasons.push(reason);
            }
        });

        return {
            generic: genericReasons,
            organisation: organisationReasons
        };
    }

    const allReasons = getGenericAndOrganisationReasons();

    

    const renderOrganisationReasons = () => {
        if (allReasons.organisation.length === 0) {
            return null;
        }

        return (
            <>
                <div style={localStyles.centered}>
                    <div style={localStyles.organisationTitle}>Organisation Drivers</div>
                </div>
               
                <div style={localStyles.centeredReasons}>
                    <div style={localStyles.container}>
                        {allReasons.organisation.map((reason) => {
                            return (
                                <EmailScoreReason
                                    key={reason.id}
                                    reason={reason}
                                    activeColor={activeColor}
                                    iconWidth={iconWidth}
                                    iconMargin={iconMargin}
                                    on={reasons[reason.id]}
                                    onToggle={() => onToggleReason(reason)}
                                />
                            );
                        })}
                    </div>
                </div>
                
            </>
        )
    };

    return (
        <div id="holder">
            <div style={localStyles.outerContainer}>
                <div style={localStyles.container}>
                    {allReasons.generic.map((reason) => {
                        return (
                            <EmailScoreReason
                                key={reason.id}
                                activeColor={activeColor}
                                reason={reason}
                                iconWidth={iconWidth}
                                iconMargin={iconMargin}
                                on={reasons[reason.id]}
                                onToggle={() => onToggleReason(reason)}
                            />
                        );
                    })}
                </div>
                {renderOrganisationReasons()}
            </div>
        </div>
        
        
    );



};