import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";

const BaseFormControl = ({
  label,
  name,
  value,
  type,
  onChange,
  helperText,
  inputProps = {},
  inputComponent,
  errorMessage,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!errorMessage} {...props}>
      <FormLabel>{label}</FormLabel>
      {helperText && <FormHelperText mb={1}>{helperText}</FormHelperText>}
      {inputComponent || (
        <Input
          name={name}
          onChange={onChange}
          type={type}
          value={value}
          {...inputProps}
        />
      )}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

BaseFormControl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  inputProps: PropTypes.shape(Input.propTypes),
  inputComponent: PropTypes.any,
  errorMessage: PropTypes.string,
  ...FormControl.propTypes,
};

export default BaseFormControl;
