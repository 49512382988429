import React, {useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import CompanySelectModal from "./CompanySelectModal";
import ProfilePic from "./ProfilePic";

export default({user, companies}) => {

    const theme = Themes[useColorScheme()];
    const [showChooser, setShowChooser] = useState(false);

    const localStyles = {
        container: {
            position: "fixed",
            userSelect: "none",
            top: 20,
            right: 20,
            borderRadius: 8,
            backgroundColor: theme.colors.white,
            color: theme.colors.black,
            fontSize: 14,
            fontWeight: 500,
            zIndex: 100,
            boxShadow: theme.colors.shadow,
            cursor: "pointer"
           
        },
        innerContainer: {
            padding: 15,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pointerEvents: "none"
        },
        name: {
            marginLeft: 10
        }
    };

    const onShowChooser = (evt) => {
        if (evt.target.dataset.picker) {
            setShowChooser(true);
        }
    };

    const getName = () => {
        if (companies && companies.selected) {
            return companies.selected.name;
        } else if (companies.admin) {
            return "Admin Mode"
        } else {
            return null;
        }
    };

    return (
        <div data-picker={true} style={localStyles.container} onClick={(evt) => onShowChooser(evt)}>
            <div data-picker={true} style={localStyles.innerContainer}>
                <ProfilePic
                    data-picker={true}
                    user={companies.selected ? companies.selected : { override: "A" }}
                /> 
                <div data-picker={true} style={localStyles.name}>{getName()}</div>
            </div>
            <CompanySelectModal
                visible={showChooser}
                companies={companies}
                onHide={() => setShowChooser(false)}
            />
        </div>
    );
};