import React, {useState} from "react";
import Button from "../components/Button";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";

const LinkMSTeamsSubscription = (data) => {
  const [selection, setSelection] = useState("");
  const theme = Themes[useColorScheme()];
  const localStyles = {
    dropDown: {
      padding: 10,
      borderRadius: 6,
      borderColor: theme.colors.border,
      outline: theme.colors.primary,
      width: "400px",
      border: `1px solid ${theme.colors.borderContrast}`,
    },
  };

  return (
    <div style={{marginTop: '50px'}}>
      <p>Do you want to link available MS Teams Subscription?</p>
      <FormControl>
        <FormLabel>Subscriptions Available</FormLabel>
        <select style={localStyles.dropDown} defaultValue="" onChange={(e) => setSelection(e.target.value)}>
        <option disabled key="0" value="">-- Select --</option>
          {data.data.map((d) => {
            return <option key={d.id} value={d.id}>{d.purchaser_email}</option>;
          })}
        </select>
      </FormControl>
      <Box marginTop="30px" marginBottom="30px">
        <Button label="Link Subscription" width={160} onClick={() => { if (selection) { data.action(selection) } else alert("Please select a Subscription Available");}} />
      </Box>
    </div>
  );
};

export default LinkMSTeamsSubscription;
