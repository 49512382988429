import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import { Box, Button, Heading, Image } from "@chakra-ui/react";
import { useButtonStyles } from "../components/Button";

export default () => {
  const theme = Themes[useColorScheme()];
  const buttonStyles = useButtonStyles();

  return (
    <Box p={4}>
      <Heading fontSize={"36px"} mt={"200px"} textAlign={"center"}>
        Thank you
      </Heading>
      <Image
        display={"block"}
        width={"153px"}
        mx={"auto"}
        src={theme.images.formLogo150}
        alt="form logo"
      />
      <Button
        {...buttonStyles}
        as={"a"}
        target={"_blank"}
        maxWidth={440}
        fontSize={"22px"}
        mx={"auto"}
        href={"https://www.formscore.today"}
        className={"link--white"}
        color={theme.colors.alwaysWhite}
      >
        Find Out More
      </Button>
    </Box>
  );
};
