import React from "react";
import { Heading } from "@chakra-ui/react";
import CardEditForm from "../components/discover/CardEditForm";

const CardEdit = () => {
  return (
    <div>
      <Heading as={"h1"} mb={6}>
        Edit Card
      </Heading>
      <CardEditForm />
    </div>
  );
};

export default CardEdit;
