import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import DriverForm from "./DriverForm";

const DriverModal = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box {...props}>
      {props.buttonComponent(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"600px"}>
          <ModalHeader>{props.heading}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DriverForm driver={props.driver} onSubmit={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DriverModal.propTypes = {
  heading: PropTypes.string,
  buttonComponent: PropTypes.func,
  driver: PropTypes.object,
  ...Box.propTypes,
};

export default DriverModal;
