import Themes from "./Themes";

export default {
  travel: { width: 37, height: 46, image: Themes.light.images.travel },
  weather: { width: 49, height: 31, image: Themes.light.images.weather },
  stress: { width: 42, height: 41, image: Themes.light.images.stress },
  sleep: { width: 45, height: 47, image: Themes.light.images.sleep },
  nutrition: {
    width: 31,
    height: 42,
    image: Themes.light.images.nutrition,
  },
  health: { width: 37, height: 34, image: Themes.light.images.health },
  finance: { width: 43, height: 41, image: Themes.light.images.finance },
  exercise: { width: 43, height: 35, image: Themes.light.images.exercise },
  energy: { width: 39, height: 43, image: Themes.light.images.energy },
  connections: {
    width: 48,
    height: 39,
    image: Themes.light.images.connections,
  },
  work: { width: 47, height: 40, image: Themes.light.images.work },
  kids: { width: 43.8, height: 40, image: Themes.light.images.kids },
};
