import moment from "moment";
import { mean, min, max } from "d3-array";
import { groupBy, orderBy, keyBy } from "lodash";

export function getAverageScore(scores) {
    if (scores.length < 1) {
        return 0;
    }
    return roundToTwoDecimalPlaces(mean(scores, d => d.score));
}

export function groupByWeek(scores) {
    return groupBy(scores, score => {
        return moment(score['created_at'])
            // create week buckets running from Monday - Sunday
            // Sunday is day 1 in Moment, so subtract one day to make Monday day 1
            .subtract(1, "day")
            .endOf("week")
            // report with the bucket name being the Sunday at the end of the week
            .add(1, "second"); 
    });
}

export function getTrendData(groupedScores) {
    const trendData = [];
    for (const date in groupedScores) {
        if (Object.hasOwnProperty.call(groupedScores, date)) {
            const element = groupedScores[date];
            trendData.push({
                date: new Date(date),
                score: roundToTwoDecimalPlaces(mean(element, d => +d.score)),
                scoreMin: roundToTwoDecimalPlaces(min(element, d => +d.score)),
                scoreMax: roundToTwoDecimalPlaces(max(element, d => +d.score)),
                n: element.length,
                data: element
            });
        }
    }
    return orderBy(trendData, "date");
}

export function getScoresData(scores) {
    const groupedScores = groupBy(scores, score => score['score']);
    const scoresData = [];
    for (let index = 1; index <= 10; index++) {
        const element = groupedScores[index];
        if (element === undefined) {
            scoresData.push(0);
        } else {
            scoresData.push(element.length);
        }   
    }
    return scoresData;
}

export function getLeaderboardData(scores, groupedScores) {
    const organisationData = []
    for (const date in groupedScores) {
        if (Object.hasOwnProperty.call(groupedScores, date)) {
            const organisationsForDate = groupedScores[date];
            const groupedOrganisations = groupBy(organisationsForDate, d => d["name"]);
            let data = {}
            for (const org in groupedOrganisations) {
                if (Object.hasOwnProperty.call(groupedOrganisations, org)) {
                    const organisationData = groupedOrganisations[org];
                    // The leaderboard score (data) is calculated
                    // as the average (mean) of the scores for that week
                    data[org] = mean(organisationData, d => d.score);
                }
            }
            organisationData.push({
                date: new Date(date),
                data
            });
        }
    }
    const orderedByDate = orderBy(organisationData, "date", "desc");
    const latestWeek = orderedByDate[0] || { data: [] };
    const lastWeek = orderedByDate[1] || { data: [] };
    const organisationsById = keyBy(scores, "name");
    const leaderBoardData = []
    for (const organisationId in latestWeek.data) {
        if (Object.hasOwnProperty.call(latestWeek.data, organisationId)) {
            const score = latestWeek.data[organisationId];
            const lastScore = lastWeek.data[organisationId] || score;
            const org = organisationsById[organisationId];
            leaderBoardData.push({
                name: org.name,
                score: roundToTwoDecimalPlaces(score),
                lastScore: roundToTwoDecimalPlaces(lastScore),
                change: roundToTwoDecimalPlaces((score - lastScore))
            })
        }
    }
    return orderBy(leaderBoardData, "score", "desc");
}

function roundToTwoDecimalPlaces(value) {
    return Math.round(value * 10)  / 10;
}