import rightArrow from "./images/right_arrow@2x.png";
import rightArrowDark from "./images/right_arrow_dark@2x.png";
import leftArrow from "./images/left_arrow@2x.png";
import leftArrowDark from "./images/left_arrow_dark@2x.png";
import emailFormLogo from "./images/email_form_logo.svg";
import emailFormLogoDark from "./images/email_form_logo.svg";
import formLogo150 from "./images/150_form_logo@2x.png";
import formLogo150Dark from "./images/150_form_logo_dark@2x.png";
import formSpinner from "./images/form_spinner@2x.png";
import checkboxTick from "./images/checkbox_tick@2x.png";
import checkboxTickDark from "./images/checkbox_tick_dark@2x.png";
import appStoreBadge from "./images/appstore@2x.png";
import playStoreBadge from "./images/playstore@2x.png";
import deleteOrg from "./images/delete_org@2x.png";
import deleteOrgDark from "./images/delete_org_dark@2x.png";
import sortOrder from "./images/sort_order@2x.png";
import sortOrderDark from "./images/sort_order_dark@2x.png";
import formLogoWord from "./form_logo_word_dark.svg";
import formLogoWordDark from "./form_logo_word.svg";
import refreshIcon from "./images/refresh.svg";
import refreshIconDark from "./images/refresh_dark.svg";
import completed from "./images/completed.svg";

import travel from "./images/travel@2x.png";
import weather from "./images/weather@2x.png";
import stress from "./images/stress@2x.png";
import sleep from "./images/sleep@2x.png";
import nutrition from "./images/nutrition@2x.png";
import health from "./images/health@2x.png";
import finance from "./images/finance@2x.png";
import exercise from "./images/exercise@2x.png";
import energy from "./images/energy@2x.png";
import connections from "./images/connections@2x.png";
import work from "./images/work@2x.png";
import kids from "./images/kids.png";

import playBadge from "./images/playbadge@2x.png";
import iosBadge from "./images/iosbadge@2x.png";

import resourcesReachout from "./images/resources_reachout@2x.png";
import resourcesReachoutDark from "./images/resources_reachout_dark@2x.png";
import resourcesEmail from "./images/resources_email@2x.png";
import resourcesEmailDark from "./images/resources_email_dark@2x.png";
import resourcesVideo from "./images/resources_play@2x.png";
import resourcesVideoDark from "./images/resources_play_dark@2x.png";
import resourcesLink from "./images/resources_link@2x.png";
import resourcesLinkDark from "./images/resources_link_dark@2x.png";

import autonomy from "./images/drivers_img/Autonomy.png";
import compensation from "./images/drivers_img/Compensation.png";
import confidence from "./images/drivers_img/Confidence.png";
import culture from "./images/drivers_img/Culture.png";
import feedback from "./images/drivers_img/Feedback.png";
import fertility from "./images/drivers_img/Fertility.png";
import flexibility from "./images/drivers_img/Flexibility.png";
import grief from "./images/drivers_img/Grief.png";
import job_security from "./images/drivers_img/Job_security.png";
import line_manager from "./images/drivers_img/Line_manager.png";
import menopause from "./images/drivers_img/Menopause.png";
import phycological_safety from "./images/drivers_img/Phycological_Safety.png";
import recognition from "./images/drivers_img/Recognition.png";
import transaction from "./images/drivers_img/Transaction.png";
import work_stress from "./images/drivers_img/Work_Stress.png";
import workload from "./images/drivers_img/Workload.png";
import assessment_centers from "./images/drivers_img/Assessment Centres@3x.png";
import interviews from "./images/drivers_img/Interviews@3x.png";
import rejection from "./images/drivers_img/Rejection@3x.png";
import time_management from "./images/drivers_img/Time Management@3x.png";
import uncertainty from "./images/drivers_img/Uncertainty@3x.png";

import { extendTheme } from "@chakra-ui/react";
import useColorScheme from "./hooks/useColorScheme";
import { isNumber } from "lodash";

const LIGHT = "light";
const DARK = "dark";

const FORM_COLORS = {
  10: "#387CFF",
  9: "#6296FC",
  8: "#73BEE8",
  7: "#78D1CC",
  6: "#48A791",
  5: "#E5AC3D",
  4: "#E5833D",
  3: "#E55B3D",
  2: "#B73C21",
  1: "#252729",
};

const VIDEOS = {
  1: "https://s3.eu-west-2.amazonaws.com/assets.formscore.today/video/1_10.mp4",
  2: "https://s3.eu-west-2.amazonaws.com/assets.formscore.today/video/2_10.mp4",
};

const COLORS = {
  light: {
    primary: "#387eff",
    colorBackground: "#13376e",
    black: "#162541",
    transparentBody: "rgba(0, 0, 0, 0.6)",
    alwaysBlack: "#162541",
    actualBlack: "#000000",
    white: "#ffffff",
    alwaysWhite: "#ffffff",
    transparentWhite: "rgba(255,255,255,0.1)",
    border: "#f2f2f2",
    borderContrast: "#a2a2a2",
    red: "#e01f5f",
    lowering: "#E55B3D",
    green: "rgba(46, 182, 125, 1.00)",
    overlay: "rgba(0,0,0,0.75)",
    shadow: "0px 3px 15px rgba(0,0,0,0.2)",
    lightOnPrimary: "#ffffff",
    form: FORM_COLORS,
  },
  dark: {
    primary: "#387eff",
    colorBackground: "#13376e",
    black: "#ffffff",
    transparentBody: "rgba(255, 255, 255, 0.6)",
    alwaysBlack: "#162541",
    actualBlack: "#000000",
    white: "#162541",
    alwaysWhite: "#ffffff",
    transparentWhite: "rgba(255,255,255,0.1)",
    border: "rgba(242, 242, 242, 0.5)",
    borderContrast: "rgba(242, 242, 242, 0.5)",
    red: "#e01f5f",
    lowering: "#E55B3D",
    green: "rgba(46, 182, 125, 1.00)",
    overlay: "rgba(0,0,0,0.5)",
    shadow: "0px 13px 15px rgba(65, 61, 65, 0.6)",
    lightOnPrimary: "#ffffff",
    form: FORM_COLORS,
  },
};

const COMPONENTS = (colors) => {
  return {
    body: {
      backgroundColor: colors.white,
    },
    pageTakeover: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: colors.colorBackground,
    },
    pageTakeoverBox: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors.alwaysWhite,
      padding: 20,
      width: "50%",
      minWidth: 300,
      maxWidth: 500,
      borderRadius: 10,
      boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    },
    padded: {
      padding: 20,
    },
    paddedTop: {
      paddingTop: 20,
    },
    headerNoMargin: {
      marginBottom: 0,
    },
    textBox: {
      borderWidth: 1,
      borderColor: colors.border,
      color: colors.alwaysBlack,
      borderRadius: 2,
      borderStyle: "solid",
      outline: "none",
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: 10,
      height: 40,
      fontWeight: 700,
    },

    countryBox: {
      borderWidth: 1,
      borderColor: colors.border,
      borderRadius: 2,
      borderStyle: "solid",
      outline: "none",
      fontSize: 16,
      width: 40,
      paddingLeft: 10,
      paddingRight: 10,
      height: 44,
      fontWeight: 700,
      marginRight: 20,
    },
    p: {
      //color: colors.black
    },
    error: {
      color: colors.red,
    },
    h1: {
      fontSize: 30,
      marginTop: 0,
      marginBottom: 0,
    },
    pageHeading: {
      fontSize: 30,
      marginTop: 0,
      marginBottom: 30,
    },
    h2: {
      fontSize: 24,
    },
    label: {
      fontSize: 12,
      fontWeight: 700,
      marginBottom: 5,
    },
  };
};

let Themes = {
  light: {
    styles: COMPONENTS(COLORS[LIGHT]),
    colors: COLORS[LIGHT],
    images: {
      appStoreBadge: appStoreBadge,
      playStoreBadge: playStoreBadge,
      checkboxTick: checkboxTick,
      rightArrow: rightArrow,
      leftArrow: leftArrow,
      emailFormLogo: emailFormLogo,
      formLogo150: formLogo150,
      formLogoWord: formLogoWord,
      formSpinner: formSpinner,
      sortOrder: sortOrder,
      deleteOrg: deleteOrg,
      travel: travel,
      weather: weather,
      stress: stress,
      sleep: sleep,
      nutrition: nutrition,
      confidence: confidence,
      culture: culture,
      feedback: feedback,
      fertility: fertility,
      flexibility: flexibility,
      assessment_centers: assessment_centers,
      interviews: interviews,
      rejection: rejection,
      time_management: time_management,
      uncertainty: uncertainty,
      grief: grief,
      line_manager: line_manager,
      menopause: menopause,
      phycological_safety: phycological_safety,
      recognition: recognition,
      transaction: transaction,
      work_stress: work_stress,
      workload: workload,
      job_security: job_security,
      autonomy: autonomy,
      compensation: compensation,
      health: health,
      finance: finance,
      exercise: exercise,
      energy: energy,
      connections: connections,
      work: work,
      kids: kids,
      refreshIcon: refreshIcon,
      completed: completed,
      playBadge: playBadge,
      iosBadge: iosBadge,
      resources: {
        link: resourcesLink,
        video: resourcesVideo,
        email: resourcesEmail,
        reachout: resourcesReachout,
      },
    },
    videos: VIDEOS,
  },
  dark: {
    styles: COMPONENTS(COLORS[DARK]),
    colors: COLORS[DARK],
    images: {
      appStoreBadge: appStoreBadge,
      playStoreBadge: playStoreBadge,
      checkboxTick: checkboxTickDark,
      rightArrow: rightArrowDark,
      leftArrow: leftArrowDark,
      formLogo150: formLogo150Dark,
      formSpinner: formSpinner,
      emailFormLogo: emailFormLogoDark,
      formLogoWord: formLogoWordDark,
      deleteOrg: deleteOrgDark,
      sortOrder: sortOrderDark,
      travel: travel,
      weather: weather,
      stress: stress,
      sleep: sleep,
      nutrition: nutrition,
      health: health,
      finance: finance,
      exercise: exercise,
      energy: energy,
      connections: connections,
      work: work,
      kids: kids,
      refreshIcon: refreshIconDark,
      confidence: confidence,
      culture: culture,
      feedback: feedback,
      fertility: fertility,
      job_security: job_security,
      flexibility: flexibility,
      grief: grief,
      line_manager: line_manager,
      menopause: menopause,
      phycological_safety: phycological_safety,
      recognition: recognition,
      transaction: transaction,
      work_stress: work_stress,
      autonomy: autonomy,
      workload: workload,
      compensation: compensation,
      assessment_centers: assessment_centers,
      interviews: interviews,
      rejection: rejection,
      time_management: time_management,
      uncertainty: uncertainty,
      completed: completed,
      playBadge: playBadge,
      iosBadge: iosBadge,
      resources: {
        link: resourcesLinkDark,
        video: resourcesVideoDark,
        email: resourcesEmailDark,
        reachout: resourcesReachoutDark,
      },
    },
    videos: VIDEOS,
  },
};

const conversionWhitelist = ["fontWeight"];

export const convertComponentStyles = (componentStyles) => {
  return Object.entries(componentStyles).reduce((acc, [style, val]) => {
    if (isNumber(val) && !conversionWhitelist.includes(style)) {
      acc[style] = `${val}px`;
      return acc;
    }

    acc[style] = val;
    return acc;
  }, {});
};

export const useChakraTheme = () => {
  const theme = Themes[useColorScheme()];

  return extendTheme({
    colors: {
      ...theme.colors,
    },

    fonts: {
      heading: "'Fugue Regular', sans-serif",
      body: "'Inter', sans-serif",
    },

    space: {
      1: "5px",
      2: "10px",
      3: "15px",
      4: "20px",
      5: "25px",
      6: "30px",
      7: "35px",
      8: "40px",
      9: "45px",
      10: "50px",
      11: "55px",
      12: "60px",
    },

    components: {
      Heading: {
        baseStyle: {
          fontWeight: "normal",
          fontSize: "30px",
        },
        sizes: null,

        variants: {
          // h1: theme.styles.pageHeading,
          h2: theme.styles.h2,
        },
      },
      Button: {
        baseStyle: {
          fontWeight: "normal",
        },
      },
    },

    config: {
      useSystemColorMode: true,
    },
  });
};

export default Themes;
