import React, { useContext, useState } from "react";
import Themes from "../Themes";
import useColorScheme from "../hooks/useColorScheme";
import DriverModal from "../components/DriverModal";
import {
  ButtonGroup,
  Checkbox,
  Icon,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DriversEditContext } from "../contexts/DriversEditContext";
import { MdDelete, MdEdit } from "react-icons/md";
import Button from "../components/Button";
import config from "../config";

const DriversEdit = () => {
  const theme = Themes[useColorScheme()];
  const { drivers, update, remove } = useContext(DriversEditContext);
  const [selectedDriver, setSelectedDriver] = useState(null);

  return (
    <div>
      <h1 style={theme.styles.pageHeading}>Edit Drivers</h1>
      <DriverModal
        heading={"Add Driver"}
        mb={4}
        buttonComponent={(onClick) => (
          <Button label={"Add New Driver"} width={"250px"} onClick={onClick} />
        )}
      />
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th w={"50px"}>Icon</Th>
            <Th w={"120px"}>Name</Th>
            <Th w={"80px"}>Active</Th>
            <Th w={"120px"}>Type</Th>
            <Th w={"200px"}>FormScore Category</Th>
            <Th>Insights</Th>
            <Th w={"70px"}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {drivers.map((driver) => (
            <Tr key={driver.id}>
              <Td>
                {driver.emoji && (
                  <Image
                    src={`${config.S3_URL}emoji/${driver.emoji}.png`}
                    w={"30px"}
                    h={"auto"}
                  />
                )}
              </Td>
              <Td>{driver.title}</Td>
              <Td>
                <Checkbox
                  isChecked={!!driver.active}
                  onChange={() => {
                    update({
                      ...driver,
                      active: !driver.active,
                    });
                  }}
                ></Checkbox>
              </Td>
              <Td>{driver?.type?.title}</Td>
              <Td>{driver?.category?.title}</Td>
              <Td>
                {driver?.insights?.map?.((i) => (
                  <Text key={i.id}>{i.content}</Text>
                ))}
              </Td>
              <Td>
                <ButtonGroup fontSize={"26px"}>
                  <DriverModal
                    heading={"Edit Driver"}
                    driver={selectedDriver}
                    cursor={"pointer"}
                    buttonComponent={(onClick) => {
                      return (
                        <Icon
                          as={MdEdit}
                          onClick={() => {
                            setSelectedDriver(driver);
                            onClick();
                          }}
                        />
                      );
                    }}
                  />
                  {!driver.is_original && (
                    <Icon
                      cursor={"pointer"}
                      as={MdDelete}
                      onClick={() => remove(driver.id)}
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default DriversEdit;
