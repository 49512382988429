import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { discoverCardTypes } from "../../services/DiscoverCardServices";

const CardTypeFormControl = ({ value, onChange, ...props }) => {
  return (
    <FormControl>
      <FormLabel>Card Type</FormLabel>
      <Select value={value} name={"type"} onChange={onChange}>
        <option value="">Select Card Type</option>
        {discoverCardTypes.map((cardType) => {
          return (
            <option value={cardType.id} key={cardType.id}>
              {cardType.label}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

CardTypeFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  ...FormControl.propTypes,
};

export default CardTypeFormControl;
