import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { DriversEditContext } from "../contexts/DriversEditContext";
import BaseFormControl from "./BaseFormControl";
import { Button, Checkbox, Flex, Select, VStack } from "@chakra-ui/react";
import { useButtonStyles } from "./Button";
import UploadFormControl, { CONTENT_TYPE_PNG } from "./UploadFormControl";
import config from "../config";
import ChipsFormInput, { ChipsVariant } from "./ChipsFormInput";

const DriverForm = ({ driver, onSubmit }) => {
  const { add, update, driverTypes, categories } =
    useContext(DriversEditContext);
  const buttonStyles = useButtonStyles();
  const formik = useFormik({
    initialValues: Object.assign(
      {
        title: "",
        description: "",
        color: "",
        active: false,
        is_default: true,
        type: null,
        category: null,
        emoji: "",
        emojiFile: null,
        insights: [],
      },

      driver || {}
    ),
    onSubmit: (values) => {
      if (driver) {
        update(values);
      } else {
        add(values);
      }

      onSubmit?.(values);
    },
    enableReinitialize: true,
  });

  const iconUrl =
    formik.values?.emoji && `${config.S3_URL}emoji/${formik.values.emoji}.png`;

  return (
    <VStack as={"form"} spacing={5} onSubmit={formik.handleSubmit}>
      <BaseFormControl
        label={"Name"}
        name={"title"}
        onChange={formik.handleChange}
        value={formik.values.title}
      />
      <UploadFormControl
        label={"Icon"}
        value={iconUrl}
        previewWidth={"50px"}
        noImageHeight={"50px"}
        contentType={CONTENT_TYPE_PNG}
        onChange={(file) => {
          formik.setFieldValue("emojiFile", file);

          if (!file) {
            formik.setFieldValue("emoji", "");
          }
        }}
      />
      <BaseFormControl
        label={"Description"}
        name={"description"}
        onChange={formik.handleChange}
        value={formik.values.description}
      />
      <BaseFormControl
        label={"Type"}
        name={"type"}
        inputComponent={
          <Select
            value={formik.values?.type?.id}
            onChange={(e) => {
              const newType = driverTypes.find((t) => t.id === e.target.value);

              formik.setFieldValue("type", newType);
            }}
          >
            <option value={""}>Select type</option>
            {driverTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.title}
              </option>
            ))}
          </Select>
        }
        onChange={formik.values?.type?.title}
      />
      <BaseFormControl
        label={"FormScore Category"}
        name={"category"}
        inputComponent={
          <Select
            value={formik.values?.category?.id}
            onChange={(e) => {
              const newCategory = categories.find(
                (t) => t.id === e.target.value
              );
              formik.setFieldValue("category", newCategory);
            }}
          >
            <option value={""}>Select category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
            ))}
          </Select>
        }
      />
      <ChipsFormInput
        variant={ChipsVariant.FreeText}
        label={"Insights"}
        onAdd={(content) => {
          formik.setFieldValue("insights", [
            ...formik.values.insights,
            {
              content,
            },
          ]);
        }}
        onDelete={(index) => {
          formik.setFieldValue(
            "insights",
            formik.values.insights.filter((item, i) => i !== index)
          );
        }}
        selected={formik.values.insights.map((i) => ({
          ...i,
          id: i.id || i.content,
          title: i.content,
        }))}
      />
      <BaseFormControl
        label={"Active"}
        name={"active"}
        inputComponent={
          <Checkbox
            isChecked={formik.values.active}
            onChange={() => {
              formik.setFieldValue("active", !formik.values.active);
            }}
          />
        }
      />
      <Flex justifyContent={"flex-end"} w={"100%"}>
        <Button type={"submit"} {...buttonStyles}>
          Submit
        </Button>
      </Flex>
    </VStack>
  );
};

DriverForm.propTypes = {
  driver: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default DriverForm;
