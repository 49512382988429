import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";
import { Box } from "@chakra-ui/react";

export default ({ text, width }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      QRCode.toCanvas(
        ref.current,
        text,
        {
          margin: 1,
          width,
          errorCorrectionLevel: "H",
        },
        (error) => {
          if (error) {
            console.error(error);
            alert("Error with qr code");
          }
        }
      );
    }
  }, [ref.current]);

  return (
    <Box width={"100%"} height={"100%"}>
      <Box as={"canvas"} ref={ref} width={"100%"} height={"100%"} />
    </Box>
  );
};
