import React, { useContext, useState } from "react";
import {
  Box,
  HStack,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { MdDelete, MdLink } from "react-icons/md";
import { GeneratedReportsContext } from "../contexts/GeneratedReportsContext";
import HttpServices from "../services/HttpServices";

const statusLabels = {
  success: "Created",
  generating: "Generating",
  error: "Error Creating Report",
};

const GeneratedReportsTable = () => {
  const { reports, remove } = useContext(GeneratedReportsContext);
  const [query, setQuery] = useState("");
  const filteredReports = reports.filter((r) => {
    if (query === "") {
      return true;
    }

    const rgx = new RegExp(query, "ig");

    return rgx.test(r.name) || rgx.test(r.org_name) || rgx.test(r.id);
  });

  const handleDownload = async (report) => {
    const url = await HttpServices.get(`/reports/${report.id}/download`);
    window.open(url);
  };

  return (
    <>
      <Input
        mt={4}
        mb={2}
        name={"search-reports"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={"Search Reports"}
        width={"30%"}
      />
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Organisation</Th>
            <Th>Created</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Status</Th>
            <Th textAlign={"center"}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredReports.map((r) => (
            <Tr
              key={r.id}
              spacing={2}
              alignItems={"center"}
              data-org_id={r.organisation_id}
            >
              <Td>{r.name}</Td>
              <Td>{r.org_name}</Td>
              <Td>{moment(r.created_at).format("DD/MM/YYYY")}</Td>
              <Td>{moment(r.from).format("DD/MM/YYYY")}</Td>
              <Td>{moment(r.to).format("DD/MM/YYYY")}</Td>
              <Td>
                <HStack>
                  <span>{statusLabels[r.status]}</span>
                  {r.status === "generating" && <Spinner size={"sm"} />}
                </HStack>
              </Td>
              <Td>
                <HStack justifyContent={"center"} fontSize={"22px"}>
                  {r.file && (
                    <Box
                      onClick={() => handleDownload(r)}
                      cursor={"pointer"}
                      title={"View"}
                    >
                      <MdLink />
                    </Box>
                  )}
                  <Box onClick={() => remove(r.id)} cursor={"pointer"}>
                    <MdDelete />
                  </Box>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

GeneratedReportsTable.propTypes = {};

export default GeneratedReportsTable;
