import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";

export default({day, statId, label}) => {
    if (!day || !day.score) {
        return null;
    }


    const theme = Themes[useColorScheme()];

    var score = "" + day.score[statId].toFixed(0);

    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        label: {
            marginTop: 2,
            fontSize: 8,
            opacity: 0.5,
            fontWeight: "500"
        },
        circle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: score ? theme.colors.form[score] : theme.colors.border,
            width: 30,
            height: 30,
            borderRadius: 15,
            fontSize: 10,
            fontWeight: "700",
            color: theme.colors.alwaysWhite
        }
    };

    return (
        <div style={localStyles.container}>
            <div style={localStyles.circle}>
                {day.score[statId].toFixed(1)}
            </div>
            <div style={localStyles.label}>{label}</div>
        </div>
    );


};