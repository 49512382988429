import React from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { BsCheckCircle } from "react-icons/all";

const SuccessToastDrivers = ({ justifyContent = "flex-end", children }) => {
  return (
    <Flex justifyContent={justifyContent}>
      <HStack
        backgroundColor={"green"}
        color={"white"}
        borderRadius={4}
        p={8}
        spacing={2}
        justifyContent={"flex-start"}
        alignItems={"center"}
        display={"inline-flex"}
      >
        <div>
          <BsCheckCircle width={"auto"} />
        </div>
        <Text paddingBottom={4}>{children}</Text>
      </HStack>
    </Flex>
  );
};

export default SuccessToastDrivers;
