import React from "react";
import Papa from "papaparse";
import {
  Button as ChakraButton,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Button, { useButtonStyles } from "./Button";
import { useFormik } from "formik";

function AddUserEmailsViaCsv({ onSubmit }) {
  const buttonStyles = useButtonStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: (values) => {
      Papa.parse(values.file, {
        header: true,
        skipEmptyLines: true,
        complete: async function ({ data, errors }) {
          console.log({ data, errors });
          if (errors.length > 0) {
            alert("Error parsing csv: " + JSON.stringify(errors, null, 4));
            return;
          }

          await onSubmit(data);
          onClose();
        },
      });
    },
  });

  return (
    <div>
      <Button
        label={"Upload CSV"}
        onClick={() => {
          onOpen();
        }}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload CSV</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={1}>Upload user CSV below</Text>
            <form onSubmit={formik.handleSubmit}>
              <Input
                border={"none"}
                type={"file"}
                name={"file"}
                onChange={(e) => {
                  formik.setFieldValue("file", e.currentTarget.files[0]);
                }}
              />
              <ButtonGroup mt={2}>
                <ChakraButton
                  as={"a"}
                  href={"/assets/example-users.csv"}
                  download
                  className={"link--white"}
                  {...buttonStyles}
                >
                  Download example CSV
                </ChakraButton>
                <ChakraButton type={"submit"} {...buttonStyles}>
                  Upload
                </ChakraButton>
              </ButtonGroup>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AddUserEmailsViaCsv;
