import React, {useEffect, useState} from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import {
    useLocation
} from "react-router-dom";

import Button from "../components/Button";
import EmailScoreReasons from "../components/EmailScoreReasons";
import HttpServices from "../services/HttpServices";
import moment from "moment";
import FormSpinner from "../components/FormSpinner";
import EmailScoreSelectTeams from "../components/EmailScoreSelectTeams";
import EmailScoreLowScoreResources from "../components/EmailScoreLowScoreResources";
import BoostingLoweringPills from "../components/BoostingLoweringPills";

const LOW_SCORE_THRESHOLD = 2;

const BOOSTING = "boosting";

export default() => {
    const theme = Themes[useColorScheme()];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    
    const [loading, setLoading] = useState(true);
    const [score] = useState(query.get("s"));
    const [token] = useState(query.get("t"));
    const [savedScore, setSavedScore] = useState(null);
    const [positiveReasons, setPositiveReasons] = useState({});
    const [negativeReasons, setNegativeReasons] = useState({});
    const [saving, setSaving] = useState(false);
    const [showSelectTeams, setShowSelectTeams] = useState(false);
    const [savedReasons, setSavedReasons] = useState(false);
    const [mustSelectTeam, setMustSelectTeam] = useState(false);
    const [currentPill, setCurrentPill] = useState(BOOSTING);

    useEffect(() => {
        if (score && token) {
            // We have score and token, send the request
            saveScore();
        }
    }, [score, token]);

    const saveScore = async () => {
        const result = await HttpServices.post(`/score/email`, {
            token: token,
            score: parseInt(score),
            date: moment().format("YYYY-MM-DD")
        });

        checkIfShouldShowSubOrgSelector();

        if (result.trend_direction && result.trend_direction < 0) {
            setCurrentPill("lowering");
        }

        setSavedScore(result);
    };

    const checkIfShouldShowSubOrgSelector = async() => {
        try {
            const configuredStatus = await HttpServices.get(`/score/email/team/configured?token=${token}`);

            if (configuredStatus.configurable && !configuredStatus.configured) {
                setShowSelectTeams(true);
                setMustSelectTeam(configuredStatus.required);
                setLoading(false);
            } else {
                setLoading(false);
            }

        } catch (e) {
            // Do nothing except stop loading, this won't be fatal to other functionality
            setLoading(false);
        }
        
    }

    const onChangeReasons = (updatedReasons) => {
        if (currentPill === BOOSTING) {
            setPositiveReasons(updatedReasons);
        } else {
            setNegativeReasons(updatedReasons);
        }
    };

    const localStyles = {
        container: {
            padding: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        badges: {
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        iosBadge: {
            width: 108,
            height: 36,
            cursor: "pointer",
            userSelect: "none"
        },
        playBadge: {
            width: 119,
            height: 36,
            marginRight: 10,
            cursor: "pointer",
            userSelect: "none"
        },
        buttonInner: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: 500,
            padding: 30,
            paddingLeft: 50,
            paddingRight: 50
        },
        linkAppHolder: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 500,
            marginTop: 30,
            textAlign: "center"
        },
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        buttonsArea: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.colors.white
        },
        logo: {
            width: 100,
            height: 100,
            marginBottom: 10
        },
        title: {
            fontSize: 22,
            fontWeight: "500",
            color: theme.colors.black,
            margin: 0
        },
        description: {
            fontSize: 16,
            color: theme.colors.black,
            marginBottom: 20,
            marginTop: 10
        }
    };

    const getCount = (reasonsToUse) => {
        var count = 0;

        Object.values(reasonsToUse).forEach((reason) => {
            if (reason) {
                count++;
            }
        });

        return count;
    }

    const goToAppStore = () => {
        window.location.href = "https://apps.apple.com/app/id1549717355";
    };

    const goToPlayStore = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=today.formscore.app";
    };

    const onSaveReasons = async () => {
        var positiveReasonIds = [];
        var negativeReasonIds = [];

        Object.keys(positiveReasons).forEach((reasonId) => {
            if (positiveReasons[reasonId]) {
                positiveReasonIds.push(reasonId);
            }
        });

        Object.keys(negativeReasons).forEach((reasonId) => {
            if (negativeReasons[reasonId]) {
                negativeReasonIds.push(reasonId);
            }
        });

        setSaving(true);

        await HttpServices.patch(`/score/email/reasons`, {
            score_id: savedScore.id,
            token: token,
            positive_reason_ids: positiveReasonIds,
            negative_reason_ids: negativeReasonIds
        });

        setSavedReasons(true);

    };

    const onCloseSelectTeams = () => {
        setShowSelectTeams(false);
    };

    const renderContent = () => {
        if (showSelectTeams) {
            return (
                <EmailScoreSelectTeams
                    token={token}
                    mustSelectTeam={mustSelectTeam}
                    onClose={() => onCloseSelectTeams()}
                />
            );
        } else if (savedReasons) {
            if (score <= LOW_SCORE_THRESHOLD) {
                // Score is low, show the low score resources
                return (
                    <EmailScoreLowScoreResources
                        token={token}
                        score={score}
                    />
                );
            } else {
                // Show thank you message
                return (
                    <div style={localStyles.content}>
                        <h1 style={localStyles.title}>Thank you</h1>
                        <p style={localStyles.description}>Thank you for recording your score</p>
                        <div style={localStyles.linkAppHolder}>
                            <h3>Get our App</h3>
                            <p style={localStyles.description}>
                                Why not download the Form mobile app to track your Form and get additional insights? Click here to download the app or link your email if you already have it.
                            </p>
    
                            <div style={localStyles.badges}>
                                <img 
                                    alt="Android Play Store"
                                    src={theme.images.playBadge}
                                    style={localStyles.playBadge}
                                    onClick={() => goToPlayStore()}/>
                                <img 
                                    src={theme.images.iosBadge}
                                    alt="iOS App Store"
                                    style={localStyles.iosBadge}
                                    onClick={() => goToAppStore()}/>
                            </div>
                            
                        </div>
                    </div>
                )
            }
            
        } else {
            return (
                <div style={localStyles.content}>
                    <h1 style={localStyles.title}>You are {score} today</h1>
                    <p style={localStyles.description}>Why is that?</p>

                    <BoostingLoweringPills
                        currentPill={currentPill}
                        onChangePill={(pill) => setCurrentPill(pill)}
                        loweringCount={getCount(negativeReasons)}
                        boostingCount={getCount(positiveReasons)}
                    />

                    <EmailScoreReasons
                        key={`${currentPill}_reasons`}
                        activeColor={currentPill === BOOSTING ? theme.colors.primary : theme.colors.lowering}
                        availableReasons={savedScore.available_reasons}
                        reasons={currentPill === BOOSTING ? positiveReasons : negativeReasons}
                        onChangeReasons={(updatedReasons) => onChangeReasons(updatedReasons)}
                    />

                    <div style={localStyles.buttonsArea}>
                        <div style={localStyles.buttonInner}>
                            <Button
                                label="Save"
                                loading={saving}
                                width="100%"
                                onClick={() => onSaveReasons()}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    if (loading) {
        return (
            <FormSpinner/>
        );
    }

    return (
        <div style={localStyles.container}>
            <img 
                alt="Logo"
                style={localStyles.logo}
                src={theme.images.formLogo150}
            />
            {renderContent()}
        </div>
    );
};