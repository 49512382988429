import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Heading, Stack, Text } from "@chakra-ui/react";
import useApi from "../hooks/useApi";
import HttpServices from "../services/HttpServices";
import { mutate } from "swr";
import { useButtonStyles } from "./Button";
import { EditOrganisationsContext } from "../contexts/EditOrganisationsContext";

const ManageOrganisationProductSubscriptions = ({ organisation, ...props }) => {
  const { startOrganisationPollsTrial } = useContext(EditOrganisationsContext);
  const buttonStyles = useButtonStyles({ width: 200 });
  const user = HttpServices.getUser();
  const { data: products } = useApi(user.admin && "/products", {
    defaultValue: [],
  });
  const orgProductsUrl = `/products/organisations/${organisation.id}`;
  const { data: orgProducts } = useApi(orgProductsUrl, {
    defaultValue: [],
  });
  const [loading, setLoading] = useState(false);

  const handleOnProductChange = async (product) => {
    setLoading(true);
    const orgProduct =
      orgProducts.find((op) => op.product_id === product.id) || {};
    const otherActiveProducts = orgProducts.filter(
      (op) => op.product_id !== product.id && op.active
    );
    const newProduct = {
      ...orgProduct,
      organisation_id: organisation.id,
      product_id: product.id,
      product,
      active: !orgProduct.active,
      trial: false,
      trial_expires_at: null,
    };

    await HttpServices.post(orgProductsUrl, {
      organisationProduct: newProduct,
    });

    if (otherActiveProducts.length) {
      await Promise.all(
        otherActiveProducts.map((op) =>
          HttpServices.post(orgProductsUrl, {
            organisationProduct: {
              ...op,
              active: false,
              trial: false,
              trial_expires_at: null,
            },
          })
        )
      );
    }

    let cioUpdates = {};

    if (!newProduct.active) {
      cioUpdates = {
        subscribed: "unsubscribed",
        subscription_type: null,
      };
    } else {
      cioUpdates = {
        subscribed: "dashboard",
        subscription_type: newProduct.product.name.toLowerCase(),
      };
    }

    await HttpServices.post(`/cio/organisations/${organisation.id}`, {
      data: cioUpdates,
    });

    await mutate(orgProductsUrl);
    setLoading(false);
  };

  const handleOnTrialClick = async () => {
    setLoading(true);
    await startOrganisationPollsTrial(organisation.id);
    await mutate(orgProductsUrl);
    setLoading(false);
  };

  return (
    <Box {...props}>
      <Heading variant={"h2"}>Poll Subscription Settings</Heading>
      <Text mb={3}>
        Note: if you uncheck an option it will not give a refund to the client
        if needed. This must be done in Stripe Dashboard.
      </Text>
      <Stack maxW={"150px"} mb={5}>
        {products.map((product) => {
          return (
            <Checkbox
              key={product.id}
              onChange={() => {
                handleOnProductChange(product);
              }}
              isDisabled={loading}
              isChecked={orgProducts.some(
                (op) => op.product_id === product.id && op.active
              )}
            >
              {product.name}
            </Checkbox>
          );
        })}
      </Stack>
      {orgProducts.every((p) => !p.active) && (
        <Button {...buttonStyles} onClick={handleOnTrialClick}>
          Start Trial
        </Button>
      )}
    </Box>
  );
};

ManageOrganisationProductSubscriptions.propTypes = {
  organisation: PropTypes.object,
  ...Box.propTypes,
};

export default ManageOrganisationProductSubscriptions;
