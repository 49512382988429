import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import Button from "./Button";
import Checkbox from "./Checkbox";
import FormSpinner from "./FormSpinner";

export default({token, onClose, mustSelectTeam}) => {
    const theme = Themes[useColorScheme()];
    const [availableOrgs, setAvailableOrgs] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedTeams, setSelectedTeams] = useState({});

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        selector: {
            marginTop: 10,
            width: 280,
            maxHeight: 400,
            borderRadius: 4,
            overflow: "auto",
            borderColor: theme.colors.border,
            borderWidth: 1,
            borderStyle: "solid",
            padding: 10
        },
        orgName: {
            flex: 1
        },
        org: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            marginBottom: 10,
            borderColor: theme.colors.border,
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: theme.colors.white
        },
        buttonsArea: {
            marginTop: 30
        },
    };

    useEffect(() => {
        reloadAvailableOrgs();
    }, []);

    const reloadAvailableOrgs = async() => {
        try {
            const orgs = await HttpServices.get(`/score/email/team?token=${token}`);
    
            if (orgs.length > 0) {
                setAvailableOrgs(orgs);

                // We also need to update the selectedTeams map
                var updatedSelections = {};

                orgs.forEach((org) => {
                    if (org.joined) {
                        updatedSelections[org.id] = true;
                    }
                })

                setSelectedTeams(updatedSelections);
                setLoading(false);


            } else {
                onClose();
            }
        } catch (e) {
            onClose();
        }
    };

    const saveTeams = async() => {
        setSaving(true);

        var orgJoinList = [];

        availableOrgs.forEach((availableOrg) => {
            orgJoinList.push({
                id: availableOrg.id,
                join: selectedTeams[availableOrg.id] || false
            });
        });

        await HttpServices.post(`/score/email/team`, {
            token: token,
            orgs: orgJoinList
        });

        onClose();
    };

    const onSelectOrUnselectTeam = (teamId) => {
        var updatedSelected = Object.assign({}, selectedTeams);
        if (updatedSelected[teamId]) {
            updatedSelected[teamId] = false;
        } else {
            updatedSelected[teamId] = true;
        }

        setSelectedTeams(updatedSelected);
    };

    if (loading) {
        return (
            <FormSpinner/>
        );
    }

    const countSelectedTeams = () => {
        var count = 0;

        Object.keys(selectedTeams).forEach((key) => {
            if (selectedTeams[key]) {
                count ++;
            }
        });

        return count;
    };

    const canContinue = () => {
        if (mustSelectTeam) {
            if (countSelectedTeams() > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    return (
        <div style={localStyles.container}>
            <h1 style={theme.styles.headerNoMargin}>Welcome to Form</h1>
            <p>Which teams are you a part of?</p>
            <div style={localStyles.selector}>
                {availableOrgs.map((availableOrg) => {
                    return (
                        <div key={availableOrg.id} style={localStyles.org}>
                            <Checkbox
                                on={selectedTeams[availableOrg.id]}
                                onChange={() => onSelectOrUnselectTeam(availableOrg.id)}
                            />
                            <div style={localStyles.orgName}>{availableOrg.name}</div>
                        </div>
                    );
                })}
            </div>
            <div style={localStyles.buttonsArea}>
                <Button
                    label="Save"
                    loading={saving}
                    disabled={!canContinue()}
                    onClick={() => saveTeams()}
                />
            </div>
        </div>
    )
};