import React, { useEffect, useState } from "react";
import HttpServices from "../services/HttpServices";
import AddSingleEmailModal from "./AddSingleEmailModal";
import AddBulkEmailModal from "./AddBulkEmailModal";
import Button from "./Button";
import moment from "moment";
import TotalUserCount from "./TotalUserCount";

export default({organisation, showLogging, analytics}) => {
    const [emails, setEmails] = useState([]);
    const [deletingId, setDeletingId] = useState(null);
    const [forceSendingEmailId, setForceSendingEmailId] = useState(null);
    const [showAddSingleEmailModal, setShowAddSingleEmailModal] = useState(false);
    const [showAddBulkEmailModal, setShowAddBulkEmailModal] = useState(false);

    const localStyles = {
        container: {
            marginTop: 30
        },
        addRow: {
            display: "flex",
            flexDirection: "row",
            marginBottom: 30,
            width: 300,
            justifyContent: "space-between"
        },
        highLevelStats: {
            width: 680,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 30
        }
    }

    useEffect(() => {
        if (organisation) {
            reloadEmails();
        }
    }, [organisation]);

    const reloadEmails = async () => {
        const allEmails = await HttpServices.get(`/organisation/${organisation.id}/emails`);
        setEmails(allEmails);
    };
    
    const onDeleteUserEmail = async (userEmail) => {
        setDeletingId(userEmail.id);
        await HttpServices.delete(`/organisation/${organisation.id}/emails`, {
            email: userEmail.email
        });

        reloadEmails();
    };

    const onForceSendSingleEmail = async(email) => {
        setForceSendingEmailId(email.id);
        await HttpServices.post(`/organisation/${organisation.id}/emails/send`, {
            email_address: email.email
        });

        await reloadEmails();
        setForceSendingEmailId(null);
    };

    return (
        <div style={localStyles.container}>
            <h2>Email Addresses</h2>

            <div style={localStyles.highLevelStats}>
                <TotalUserCount
                    label="Total"
                    total={emails.length}
                />
                <TotalUserCount
                    labelOverride="Have scored at least once"
                    total={analytics.at_least_one_score}
                />
                <TotalUserCount
                    labelOverride="Have set sub teams"
                    total={analytics.configured_sub_teams}
                />
                <TotalUserCount
                    label="Unsubscribed"
                    total={analytics.unsubscribed}
                />
            </div>
            

            <div style={localStyles.addRow}>
                <Button
                    label="Add"
                    width={100}
                    onClick={() => setShowAddSingleEmailModal(true)}
                />
                <Button
                    label="Bulk Add"
                    width={100}
                    onClick={() => setShowAddBulkEmailModal(true)}
                />

            </div>
            <table style={localStyles.table}>
                <thead>
                    <tr>
                        <th>Email Address {showLogging ? "" : `(${emails.length})`}</th>
                        {showLogging ? <th>Last status</th> : null}
                        {showLogging ? <th>Last status date</th> : null}
                        {showLogging ? <th>Last sent date</th> : null}
                        {showLogging ? <th></th> : null}
                        <th width={50}></th>
                        
                    </tr>
                </thead>
                <tbody>
                    {emails.map((email) => {
                        return (
                            <tr key={email.id}>
                                <td>{email.email}{email.unsubscribed ? ` (Unsubscribed)` : ``}</td>
                                {showLogging ? <td>{email.last_status}</td> : null}
                                {showLogging ? <td>{email.last_status_date ? moment(email.last_status_date).format("YYYY-MM-DD HH:mm:ss") : ""}</td> : null}
                                {showLogging ? <td>{email.last_send_date ? moment(email.last_send_date).format("YYYY-MM-DD HH:mm:ss") : ""}</td> : null}
                                {showLogging ? <td>
                                    <Button 
                                        dangerous={true} 
                                        width={90}
                                        label="Force Send"
                                        loading={forceSendingEmailId && forceSendingEmailId === email.id}
                                        onClick={() => onForceSendSingleEmail(email)}
                                        />
                                    </td> : null}
                                <td>
                                    <Button 
                                        dangerous={true} 
                                        label="Delete"
                                        loading={deletingId && deletingId === email.id}
                                        onClick={() => onDeleteUserEmail(email)}
                                        />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <AddSingleEmailModal
                organisation={organisation}
                visible={showAddSingleEmailModal}
                onRefresh={async () => await reloadEmails()}
                onHide={() => setShowAddSingleEmailModal(false)}
            />
            <AddBulkEmailModal
                organisation={organisation}
                visible={showAddBulkEmailModal}
                onRefresh={async () => await reloadEmails()}
                onHide={() => setShowAddBulkEmailModal(false)}
            />
        </div>
    )

};