import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { CardProvider } from "../../contexts/CardContext";
import CardEditForm from "./CardEditForm";
import { CardsContext } from "../../contexts/CardsContext";
import { ReasonsProvider } from "../../contexts/ReasonsContext";
import { DiscoverCategoriesProvider } from "../../contexts/DiscoverCategoriesContext";

const CardEditModal = () => {
  const {
    chosenCardId,
    cardEditActive,
    setCardEditActive,
    getUrl,
    fetchCards,
  } = useContext(CardsContext);
  return (
    <Modal
      isOpen={cardEditActive}
      onClose={() => setCardEditActive(false)}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Card Edit</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReasonsProvider>
            <DiscoverCategoriesProvider>
              {cardEditActive && (
                <CardProvider cardId={chosenCardId}>
                  <CardEditForm
                    fetchCards={fetchCards}
                    getUrl={getUrl}
                    setCardEditActive={setCardEditActive}
                  />
                </CardProvider>
              )}
            </DiscoverCategoriesProvider>
          </ReasonsProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

CardEditModal.propTypes = {
  cardId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CardEditModal;
