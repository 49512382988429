import React, { useContext } from "react";
import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Icon,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { CardsContext } from "../../contexts/CardsContext";
import { IoTriangleSharp } from "react-icons/all";
import CardsPagination from "./CardsPagination";

const CardsTable = () => {
  const {
    cards,
    setChosenCardId,
    setCardEditActive,
    remove,
    update,
    reOrder,
    order,
    direction,
  } = useContext(CardsContext);

  const headers = [
    {
      label: "Image",
    },
    {
      value: "title",
      label: "Name",
    },
    {
      value: "type",
      label: "Type",
      textAlign: "center",
    },
    {
      value: "reasons[0].title",
      label: "Drivers",
    },
    {
      value: "category.title",
      label: "Category",
      textAlign: "center",
    },
    {
      value: "order",
      label: "Order",
      textAlign: "center",
    },
    {
      value: "created_at",
      label: "Created",
    },
    {
      value: "updated_at",
      label: "Updated",
    },
    {
      label: "Actions",
      textAlign: "center",
    },
  ];

  const handleCardEdit = (card, index) => {
    setChosenCardId(card.id);
    setCardEditActive(true);
  };

  return (
    <>
      <Table size={"sm"}>
        <Thead>
          <Tr cursor={"pointer"}>
            {headers.map(({ value, label, ...props }) => {
              return (
                <Th
                  key={label}
                  whiteSpace={"nowrap"}
                  {...props}
                  onClick={value && (() => reOrder(value))}
                  userSelect={"none"}
                >
                  <HStack spacing={1}>
                    <Box mr={1} as={"span"}>
                      {label}
                    </Box>
                    {order.includes(value) && (
                      <Icon
                        as={IoTriangleSharp}
                        transform={[
                          "translateY(1px)",
                          (direction === "desc" && "rotate(180deg)") || "",
                        ].join(" ")}
                      />
                    )}
                  </HStack>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {cards?.map((card, i) => {
            return (
              <Tr
                key={card.id}
                sx={{
                  ":hover": {
                    background: "transparentWhite",
                  },
                  ":hover .card__actions": {
                    display: "block",
                  },
                }}
              >
                <Td>
                  <Image
                    cursor={"pointer"}
                    onClick={() => handleCardEdit(card, i)}
                    src={card.image_url}
                    width={"80px"}
                    height={"49px"}
                    objectFit={"cover"}
                  />
                </Td>
                <Td>
                  <Text
                    onClick={() => handleCardEdit(card, i)}
                    cursor={"pointer"}
                  >
                    {card.title || "No Title"}
                  </Text>
                </Td>
                <Td textAlign={"center"}>{card.type}</Td>
                <Td>{card?.reasons?.map((r) => r.title).join(", ")}</Td>
                <Td textAlign={"center"}>{card.category?.title}</Td>
                <Td>
                  <Editable
                    defaultValue={card.order}
                    textAlign={"center"}
                    onSubmit={(val) => {
                      update({
                        id: card.id,
                        order: (val && Number(val)) || null,
                      });
                    }}
                  >
                    <EditablePreview minWidth={"50px"} />
                    <EditableInput type={"number"} maxW={"50px"} />
                  </Editable>
                </Td>
                <Td>
                  {card.created_at &&
                    moment(card.created_at).format("DD/MM/YYYY")}
                </Td>
                <Td>
                  {card.updated_at &&
                    moment(card.updated_at).format("DD/MM/YYYY")}
                </Td>
                <Td>
                  <HStack spacing={2}>
                    <MdDelete
                      fontSize={"20px"}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        remove(card.id);
                      }}
                    />
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <CardsPagination />
      {cards?.length < 1 && (
        <Text p={4} textAlign={"center"}>
          No cards found
        </Text>
      )}
    </>
  );
};

export default CardsTable;
