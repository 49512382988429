import Button from "../components/Button";
import TextBox from "../components/TextBox";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import React, { useEffect, useState } from "react";
import logo from "../form_logo_word.svg";
import HttpServices from "../services/HttpServices";
import CountrySelectBox from "../components/CountrySelectBox";
import { useHistory, useLocation } from "react-router-dom";

export default ({ isMergeLogin }) => {
  const theme = Themes[useColorScheme()];
  const location = useLocation();

  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [page, setPage] = useState("mobile");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [countryCode, setCountryCode] = useState("GB");
  const [verificationId, setVerificationId] = useState(null);

  const query = new URLSearchParams(location.search);
  const [token] = useState(query.get("t"));
  const history = useHistory();

  const PAGE_OTP = {
    title: "We have sent you a code",
    label: "Enter code",
    fieldId: "otp",
    description: "Please enter the code we sent you below",
  };

  const PAGE_MOBILE = {
    title: isMergeLogin ? "Link to Form App" : "Login",
    label: "Mobile Number",
    fieldId: "mobile",
    description: isMergeLogin
      ? "Enter your mobile number to create or login to your Form account"
      : "Please enter your mobile number to continue",
  };

  const PAGE_EMAIL = {
    title: "Login",
    label: "Email address",
    fieldId: "email",
    description: "Enter your email address to login"
  };

  const PAGES = {
    mobile: PAGE_MOBILE,
    email: PAGE_EMAIL,
    otp: PAGE_OTP,
  };

  useEffect(() => {
    if (window.localStorage.mobileNumber) {
      setMobileNumber(window.localStorage.mobileNumber);
    }
  }, []);

  const localStyles = {
    buttons: {
      display: "flex",
      flexDirection: "row",
      padding: 20,
    },
    switch: {
      marginTop: 10,
      height: 20,
      userSelect: "none",
      cursor: "pointer",
      fontSize: 12,
      fontWeight: "500",
      color: theme.colors.black,
      opacity: 0.7
    },
    fields: {
      display: "flex",
      flexDirection: "row",
    },
    logo: {
      marginTop: 40,
      width: 60,
    },
    paddedBack: {
      paddingRight: 20,
    },
    fullScreenError: {},
  };

  if (isMergeLogin && !token) {
    return (
      <div style={theme.styles.pageTakeover}>
        <div style={theme.styles.pageTakeoverBox}>
          <center>
            <h1>Sorry</h1>
            <p>You've clicked an invalid link</p>
          </center>
        </div>
        <div style={localStyles.logoHolder}>
          <img alt="Form Logo" src={logo} style={localStyles.logo} />
        </div>
      </div>
    );
  }

  const onChangeField = (fieldId, val) => {
    if (fieldId === "otp") {
      setOtp(val);
    } else if (fieldId === "mobile") {
      setMobileNumber(val);
    } else if (fieldId === "email") {
      setEmail(val);
    }
  };

  const onNext = async () => {
    setError(false);

    if (page === "mobile") {
      setLoading(true);
      setOtp("");

      try {
        const result = await HttpServices.post("/user/register", {
          phone_number: mobileNumber,
          country_code: countryCode,
          push: isMergeLogin ? false : true,
        });

        setMobileNumber(result.phone_number);
        setVerificationId(result.verification_id);
        setLoading(false);
        setPage("otp");
      } catch (e) {
        setError(e.toString());
        setLoading(false);
      }
    } else if (page === "email") {
      setLoading(true);
      setOtp("");

      try {
        const result = await HttpServices.post("/user/register/email", {
          email: email,
          push: isMergeLogin ? false : true,
        });

        setVerificationId(result.verification_id);
        setLoading(false);
        setPage("otp");
      } catch (e) {
        setError(e.toString());
        setLoading(false);
      }
    } else {
      // We are verifying OTP
      setLoading(true);

      try {
        const result = await HttpServices.post("/user/verify", {
          verification_code: otp,
          verification_id: verificationId,
        });

        await HttpServices.setUserAndToken(result.user, result.token);

        if (isMergeLogin) {
          history.push(`/email/merge?t=${token}`);
        } else {
          history.push("/");
        }
      } catch (e) {
        setError(e.toString());
        setLoading(false);
      }
    }
  };

  const onGoBack = () => {
    setPage("mobile");
    setError(null);
  };

  const renderBack = () => {
    if (page === "mobile" || page === "email") {
      return null;
    }

    return (
      <div style={localStyles.paddedBack}>
        <Button label="Back" width={50} onClick={() => onGoBack()} />
      </div>
    );
  };

  const renderDescriptionOrError = () => {
    if (error) {
      return <p style={theme.styles.error}>{error}</p>;
    }

    return (
      <p style={theme.styles.p} className="alwaysBlack">
        {PAGES[page].description}
      </p>
    );
  };

  const renderSwitchLogin = () => {
    if (page === "mobile") {
      return (
        <div style={localStyles.switch} onClick={() => setPage("email")}>Or login with email</div>
      );
    } else if (page === "email") {
      return (
        <div style={localStyles.switch} onClick={() => setPage("mobile")}>Or login with mobile</div>
      );
    } else {
      return (
        <div style={localStyles.switch}></div>
      );
    }
  };

  const renderCountryBox = () => {
    if (page !== "mobile") {
      return null;
    }

    return (
      <CountrySelectBox
        value={countryCode}
        onChange={(val) => setCountryCode(val)}
      />
    );
  };

  const getFieldValue = () => {
    if (PAGES[page].fieldId === "mobile") {
      return mobileNumber;
    } else if (PAGES[page].fieldId === "email") {
      return email;
    } else {
      return otp;
    }
  };

  return (
    <div style={theme.styles.pageTakeover}>
      <div style={theme.styles.pageTakeoverBox}>
        <div style={theme.styles.padded}>
          <h1 className="alwaysBlack" style={theme.styles.h1}>
            {PAGES[page].title}
          </h1>
          {renderDescriptionOrError()}
          <div style={theme.styles.paddedTop}>
            <div style={localStyles.fields}>
              {renderCountryBox()}
              <TextBox
                key={PAGES[page].fieldId}
                alwaysBlack={true}
                label={PAGES[page].label}
                value={getFieldValue()}
                onChange={(val) => onChangeField(PAGES[page].fieldId, val)}
                onEnter={() => onNext()}
              />
            </div>
            {renderSwitchLogin()}
          </div>
        </div>

        <div style={localStyles.buttons}>
          {renderBack()}
          <Button
            label="Next"
            width={50}
            disabled={
              (page === "mobile" && mobileNumber === "") ||
              (page === "otp" && otp === "")
            }
            onClick={() => onNext()}
            loading={loading}
          />
        </div>
      </div>
      <div style={localStyles.logoHolder}>
        <img alt="Form Logo" src={logo} style={localStyles.logo} />
      </div>
    </div>
  );
};
