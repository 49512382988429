import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ReasonsContext } from "../contexts/ReasonsContext";
import Emojis from "../Emojis";
import { xor } from "lodash";
import { Box, Center, Flex, Grid, Image, Text } from "@chakra-ui/react";

function DriverSelect({ onSelect, trend, ...props }) {
  const reasons = useContext(ReasonsContext);
  const [selected, setSelected] = useState([]);

  return (
    <Grid
      alignItems={"center"}
      gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr"}
      gridGap={4}
      p={2}
      {...props}
    >
      {reasons.map((r) => {
        const emoji = Emojis[r.emoji] || {};
        const isSelected = selected.includes(r.id);
        const formColor = trend === "positive" ? "form.10" : "form.4";

        return (
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            key={r.id}
            onClick={() => {
              const newSelected = xor(selected, [r.id]);
              setSelected(newSelected);
              onSelect(newSelected);
            }}
          >
            <Center
              border={isSelected ? "2px solid" : "none"}
              borderColor={formColor}
              borderRadius={"5px"}
              p={1}
              width={"45px"}
              height={"45px"}
            >
              <Image
                src={emoji.image}
                alt={r.title}
                width={"auto"}
                height={"auto"}
                maxWidth={emoji.width * 0.7}
                maxHeight={emoji.height * 0.7}
                display={"block"}
              />
            </Center>
            <Text
              fontSize={"10px"}
              color={isSelected ? formColor : ""}
              mt={1}
              mb={0}
            >
              {r.title}
            </Text>
          </Flex>
        );
      })}
    </Grid>
  );
}

DriverSelect.propTypes = {
  onSelect: PropTypes.func,
  trend: PropTypes.string,
  ...Box.propTypes,
};

export default DriverSelect;
