import React, { useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import UserCompanyServices from "../services/UserCompanyServices";
import Themes from "../Themes";
import Modal from "./Modal";
import ProfilePic from "./ProfilePic";

export default ({
  onHide,
  onSelect,
  visible,
  excludeOrg,
  companies,
  returnSelectionOnly,
}) => {
  const theme = Themes[useColorScheme()];
  const [searchStr, setSearchStr] = useState("");

  const localStyles = {
    companies: {
      width: "100vw",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    company: {
      padding: 20,
      borderBottomColor: theme.colors.border,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      userSelect: "none",
      cursor: "pointer",
    },
    searchBar: {
      boxSizing: "content-box",
      backgroundColor: theme.colors.border,
      height: 30,
      width: "100%",
      outline: "none",
      borderStyle: "none",
      padding: 8,
      paddingLeft: 20,
      paddingRight: 20,
    },
    companyName: {
      marginLeft: 10,
    },
  };

  const setCompany = (company) => {
    if (returnSelectionOnly) {
      onSelect(company);
    } else {
      UserCompanyServices.setSelectedCompany(company);
      onHide();
    }
  };

  const setAdmin = () => {
    UserCompanyServices.setAdminMode();
    onHide();
  };

  const renderAdmin = () => {
    if (
      returnSelectionOnly ||
      !UserCompanyServices.canAdmin() ||
      searchStr.length > 0
    ) {
      return null;
    }

    return (
      <div
        className="opacityHover"
        style={localStyles.company}
        onClick={() => setAdmin()}
      >
        <ProfilePic user={{ override: "A" }} />
        <div style={localStyles.companyName}>Admin Mode</div>
      </div>
    );
  };

  const onChangeSearch = (val) => {
    setSearchStr(val);
  };

  const renderContent = () => {
    if (!companies || !companies.companies) {
      return null;
    }

    return (
      <div style={localStyles.companies}>
        <input
          type="text"
          placeholder="Search"
          value={searchStr}
          onChange={(el) => onChangeSearch(el.target.value)}
          style={localStyles.searchBar}
        />
        {renderAdmin()}
        {companies.companies
          .filter((company) => {
            if (!searchStr && !company.active) {
              return false;
            }

            const regex = new RegExp(searchStr, "ig");

            return (
              (excludeOrg && excludeOrg.id !== company.id) ||
              regex.test((company && company.name) || "")
            );
          })
          .map((company) => {
            return (
              <div
                className="opacityHover"
                key={company.id}
                style={localStyles.company}
                onClick={() => setCompany(company)}
              >
                <ProfilePic user={company} />
                <div style={localStyles.companyName}>{company.name}</div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Modal
      title="Choose Organisation"
      visible={visible}
      height="80%"
      onHide={onHide}
      renderContent={() => renderContent()}
    />
  );
};
