export const getId = (status) => {
  if (typeof window !== undefined) {
    if (status) {
      return JSON.parse(window.sessionStorage.user).id;
    } else {
      const selectedCompanyStr = localStorage.getItem("selectedCompany");

      if (!selectedCompanyStr) {
        return null;
      }
      
      return JSON.parse(localStorage.getItem("selectedCompany")).id;
    }
  }
};
