import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import Themes from "../Themes";
import moment from "moment";
import LastXTooltipStat from "./LastXTooltipStat";

export default({visible, day}) => {
    if (!visible || !day || !day.date) {
        return null;
    }

    const theme = Themes[useColorScheme()];

    const localStyles = {
        container: {
            position: "absolute",
            zIndex: 999,
            padding: 20,
            borderRadius: 6,
            top: 56,
            left: -23,
            width: 150,
            backgroundColor: theme.colors.white,
            boxShadow: theme.colors.shadow
        },
        date: {
            fontWeight: "700",
            fontSize: 12
        },
        scoreRow: {
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
        },
        count: {
            marginTop: 2,
            fontWeight: "500",
            fontSize: 12
        },
        triangle: {
            position: "absolute",
            top: -10,
            left: 30,
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 13px 15px 13px",
            borderColor: `transparent transparent ${theme.colors.white} transparent`
        }
    };

    const renderCount = () => {
        if (day.score && day.score.count) {
            return (
                <div style={localStyles.count}>{day.score.count} score{parseInt(day.score.count) > 1 ? "s" : ""}</div>
            )
        }

        return (
            <div style={localStyles.count}>No scores</div>
        )
    };

    return (
        <div className="tooltip-appear" style={localStyles.container}>
            <div style={localStyles.date}>{day ? moment(day.date).format("DD MMM YYYY") : ""}</div>
            {renderCount()}
            <div style={localStyles.scoreRow}>
                <LastXTooltipStat
                    statId="avg"
                    label="Avg"
                    day={day}
                />
                <LastXTooltipStat
                    statId="max"
                    label="Max"
                    day={day}
                />
                <LastXTooltipStat
                    statId="min"
                    label="Min"
                    day={day}
                />
            </div>
            <div style={localStyles.triangle}></div>
        </div>
    )


};