import React from "react";
import { Grid, Heading, Text, VStack } from "@chakra-ui/react";
import { range } from "lodash";

const labels = [
  "Crisis, seek\nhelp now",
  "Struggling and\nneed help",
  "Extremely low\nform",
  "Very low\nform",
  "Low\nform",
  "Average\nform",
  "Good\nform",
  "Very good\nform",
  "Exceptional\nform",
  "Lifetime\npeak form",
];

const FormScale = () => {
  return (
    <Grid gridTemplateColumns={"80px 80px"} gridGap={"8px"}>
      {range(1, 11)
        .reverse()
        .map((num) => {
          return (
            <VStack
              spacing={"3px"}
              key={num}
              background={`form.${num}`}
              color={"white"}
              width={"80px"}
              height={"80px"}
              borderRadius={"15px"}
              textAlign={"center"}
              justifyContent={"flex-start"}
            >
              <Heading fontSize={"25px"}>{num}</Heading>
              <Text
                fontSize={"10px"}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {labels[num - 1]}
              </Text>
            </VStack>
          );
        })}
    </Grid>
  );
};

export default FormScale;
