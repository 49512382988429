import React from "react";
import { Route, Switch } from "react-router-dom";
import PollScore from "./PollScore";
import { ReasonsProvider } from "../contexts/ReasonsContext";
import PollScoreDrivers from "./PollScoreDrivers";
import PollLanding from "./PollLanding";
import PollThankYou from "./PollThankYou";
import { PollProvider } from "../contexts/PollContext";
import { ChakraProvider } from "@chakra-ui/react";
import { useChakraTheme } from "../Themes";

function Poll() {
  const chakraTheme = useChakraTheme();
  return (
    <ChakraProvider theme={chakraTheme}>
      <Switch>
        <Route exact path={"/p/thank-you"}>
          <PollThankYou />
        </Route>
        <Route exact path={"/p/:name"}>
          <PollProvider>
            <PollLanding />
          </PollProvider>
        </Route>
        <Route exact path={"/p/:name/score"}>
          <PollProvider>
            <PollScore />
          </PollProvider>
        </Route>
        <Route exact path={"/p/:name/score/:scoreId/drivers"}>
          <ReasonsProvider>
            <PollProvider>
              <PollScoreDrivers />
            </PollProvider>
          </ReasonsProvider>
        </Route>
        <Route exact path={"/p/:name/:number"}>
          <PollProvider>
            <PollLanding />
          </PollProvider>
        </Route>
        <Route exact path={"/p/:name/:number/score"}>
          <PollProvider>
            <PollScore />
          </PollProvider>
        </Route>
        <Route exact path={"/p/:name/:number/score/:scoreId/drivers"}>
          <ReasonsProvider>
            <PollProvider>
              <PollScoreDrivers />
            </PollProvider>
          </ReasonsProvider>
        </Route>
      </Switch>
    </ChakraProvider>
  );
}

export default Poll;
