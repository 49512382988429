import React, { useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import HttpServices from "../services/HttpServices";
import Themes from "../Themes";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import StreaksTable from "../components/StreaksTable";

export default({companies}) => {
    const theme = Themes[useColorScheme()];
    
    const [loading, setLoading] = useState(true);
    const [streaks, setStreaks] = useState(null);
    const [dateFrom, setDateFrom] = useState(moment().add(-12, "weeks").startOf("isoWeek"));
    const [dateTo, setDateTo] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        reloadStreaks();
    }, [companies, dateFrom, dateTo]);

    const reloadStreaks = async() => {
        if (companies.selected) {
            const reloadedStreaks = await HttpServices.get(`/reporting/organisation/${companies.selected.id}/streaks?start_date=${dateFrom.format("YYYY-MM-DD")}&end_date=${dateTo.format("YYYY-MM-DD")}`);
            setStreaks(reloadedStreaks);
            setLoading(false);
        }
    };

    
    const renderContent = () => {
        if (loading) {
            return null;
        }

        return (
            <StreaksTable
                streaks={streaks}
            />
            
        );
    };
    
    return (
        <div>
            <h1 style={theme.styles.pageHeading}>Streaks</h1>
            <DateRangePicker
                startDate={dateFrom}
                startDateId="start_date_id"
                endDate={dateTo}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                    setDateFrom(startDate);
                    setDateTo(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(input) => setFocusedInput(input)}
                isOutsideRange={(day) =>
                    day.isAfter(moment().add(1, "day")) ||
                    day.isBefore(moment("2021-01-01"))
                }
                hideKeyboardShortcutsPanel={true}
                displayFormat={"DD/MM/YYYY"}
                />
            {renderContent()}
        </div>
    );


};