import "./App.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Login from "./screens/Login";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Dashboard from "./screens/Dashboard";
import RecordEmailScore from "./screens/RecordEmailScore";
import UnsubscribeEmail from "./screens/UnsubscribeEmail";
import SubscribeToEmail from "./screens/SubscribeToEmail";
import MergeAccount from "./screens/MergeAccount";
import Poll from "./screens/Poll";
import React from "react";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/email/subscribe" exact>
          <SubscribeToEmail />
        </Route>
        <Route path="/email/merge/login" exact>
          <Login isMergeLogin={true} />
        </Route>
        <Route path="/email/merge" exact>
          <MergeAccount isMergeLogin={true} />
        </Route>
        <Route path="/email/score" exact>
          <RecordEmailScore />
        </Route>
        <Route path="/email/unsubscribe" exact>
          <UnsubscribeEmail />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path={"/p/"}>
          <Poll />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
