import React from "react";
import { useButtonStyles } from "./Button";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import GeneratedReportForm from "./GeneratedReportForm";
import { OrganisationsProvider } from "../contexts/OrganisationsContext";

const CreateGeneratedReport = (props) => {
  const buttonStyles = useButtonStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div>
      <Button {...buttonStyles} onClick={onOpen}>
        New Report
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader
            sx={{
              paddingInlineStart: 3,
              paddingInlineEnd: 3,
            }}
            py={3}
          >
            <Heading>New Report</Heading>
          </ModalHeader>
          <ModalCloseButton right={2} />
          <ModalBody
            sx={{
              paddingInlineStart: 3,
              paddingInlineEnd: 3,
            }}
            py={3}
          >
            <OrganisationsProvider>
              <GeneratedReportForm onSubmit={onClose} />
            </OrganisationsProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

CreateGeneratedReport.propTypes = {};

export default CreateGeneratedReport;
