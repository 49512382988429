import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import HttpServices from "../services/HttpServices";
import EditMSTeamsSeatsModal from "./EditMSTeamsSeatsModal";
import Button from "./Button";
import { Box, Flex, Heading } from "@chakra-ui/react";
import SuccessToastDrivers from "./SuccessToastDrivers";

function MsTeamsUsers({ users = [], organisation, reload = null, seats }) {
  const toast = useToast();
  const [showEditSeatsModal, setShowEditSeatsModal] = useState(false);
  const [makingAdmin] = useState(null);

  const onToggle = async (user) => {
    if (user.isActive) {
      await HttpServices.put(`/user/msteams/v1/deactivate-activate-user`, {
        user_id: user.user_id,
        is_active: "no",
      });
      reload();
      return toast({
        duration: 3000,
        render: () => {
          return <SuccessToastDrivers>User disabled</SuccessToastDrivers>;
        },
      });
    } else {
      await HttpServices.put(`/user/msteams/v1/deactivate-activate-user`, {
        user_id: user.user_id,
        is_active: "yes",
      });
      reload();
      return toast({
        duration: 3000,
        render: () => {
          return <SuccessToastDrivers>User enabled</SuccessToastDrivers>;
        },
      });
    }
  };

  const reloadSeats = async () => {
    reload();
  };

  const renderContent = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>User Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.user_id}>
                <td>{user.user_email}</td>
                <td>
                  <Flex
                    flexDirection="row"
                    width={300}
                    justifyContent="space-between"
                  >
                    <Button
                      label={user.isActive ? "Disable" : "Enable"}
                      loading={makingAdmin === user.user_id}
                      dangerous={user.isActive}
                      width={120}
                      onClick={() => onToggle(user)}
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Box mt={6}>
      <Heading variant="h2" mb={2}>
        Users {users?.length > 0 ? ` (${users?.length})` : ""}
      </Heading>
      <Box marginTop="30px" marginBottom="30px">
        <Button
          label="Update Seats"
          width={160}
          onClick={() => setShowEditSeatsModal(true)}
        />
      </Box>
      {renderContent()}
      <EditMSTeamsSeatsModal
        seats={seats}
        organisation={organisation}
        visible={showEditSeatsModal}
        onRefresh={reloadSeats}
        onHide={() => {
          setShowEditSeatsModal(false);
        }}
      />
    </Box>
  );
}

export default MsTeamsUsers;
